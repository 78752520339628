.c-scroll-container.menunavogazioneinterna {
	background: none;
}
.menunavogazioneinterna .c-scroll-content {
	background: #fcfbfb
}

@media (min-width: 481px) {
    .c-scroll-container.menunavogazioneinterna {
        top: 194px
    }
}

.menunavogazioneinterna ul {
	margin: 0
}
.menunavogazioneinterna li {
	background: #fcfbfb;
    border-bottom: solid 1px #f1f1f1;
    font-size: 1.7rem;
    margin: 0;
    position: relative
}
.menunavogazioneinterna li span {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.menunavogazioneinterna li a {
	padding: 0;
    font-size: 1.7rem;
    font-weight: 300;
    color: #4c4c4c;
    background: none;
    display: block;
    padding: 20px 20px 20px 30px;
    -webkit-transition: left 0.7s ease-out;
    -moz-transition: left 0.7s ease-out;
    -ms-transition: left 0.7s ease-out;
    -o-transition: left 0.7s ease-out;
	transition: left 0.7s ease-out;
}
.menunavogazioneinterna li a:focus { text-decoration:none }
.menunavogazioneinterna li a:hover {
    color: #b2ce27;
	background:none;
	text-decoration: none
}

.menunavogazioneinterna li > div {
	position: relative;
}
.menunavogazioneinterna li > ul {
	border-top: solid 1px #f1f1f1;
}
.menunavogazioneinterna li > ul li {
	margin-left: 40px;
}
.menunavogazioneinterna li > ul li:last-child { border: 0 }
// body > .container  {
//   -webkit-transition:  0.7s ease-out;
//     -moz-transition:  0.7s ease-out;
//     -ms-transition:  0.7s ease-out;
//     -o-transition:  0.7s ease-out;
// 	transition:  0.7s ease-out;
// }

.container.c-navigazioneinterna-open {
	-webkit-transform: translate(33%,0);
    -moz-transform: translate(33%,0);
    -o-transform: translate(33%,0);
    -ms-transform: translate(33%,0);
    transform: translate(33%,0);
}