iframe {
	border: 0;
	margin-top:10px;
	max-width: 100%;
}

@media (max-width: 767px) {

	iframe {
		max-height:380px;
	}

}

@media (min-width: 768px) {

	.green-border-dove-siamo {
		border-left: 1px solid #b2ce27;
	}

}

@media (min-width: 1170px) {

	.margin-top {
		margin-top: 10px;
	}

}