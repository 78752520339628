/* Typo
   ---- */

.c-font-info {
	font-size: $m-size !important;
	vertical-align: middle;
	color: $dark-grey; 
	font-weight: 400
}

.c-font-info-small {
	font-size: $xxs-size !important;
}

.c-main-content {
	padding-top: 40px;
	// padding-bottom: 40px;

	a {
		border-bottom: solid 1px #264d7a;
		color: #264d7a;
		outline: 0;
		text-decoration: none;
		-webkit-transition: color .3s ease-out, border .3s ease-out;
    	transition: color .3s ease-out, border .3s ease-out; 

		&:focus,
		&:hover{
			color: $cta-green-digital;
			border-bottom: solid 1px $cta-green-digital;
		}
	}

	h1{
		color: $dark-grey;
		font-size: $xxxl-size;
		margin-top: 20px;
    	margin-bottom: 15px;
		font-weight: 400;
		line-height: 1.2;
	}

	h2{
		@extend %base-text;
		font-size: $xxl-size;
		font-family: $robotoSlab;
		margin-top: 25px; 
		margin-bottom: 12px;
	}

	h3{
		@extend %base-text;
		font-size: $xl-size;
		font-family: $robotoSlab;
		margin-top: 40px;
    	margin-bottom: 12px;

		&.roboto{
			font-family: $roboto;
		}
		& ~ .o-square ~ p a.green-cta {
			transform: none !important;
		}
		&.roboto-condensed{
			font-family: $robotoCondensed;
		}
		&.roboto-slab{
			font-family: $robotoSlab;
		}
		&.arial {
			font-family: $arial;
		}
		&.arial-narrow {
			font-family: $arialNarrow;
		}
		&.c-subtitle {
			margin-top: 0;
			margin-bottom: 25px;
		}
	}

	h4{
		@extend %base-text;
		font-size: $l-size;
		font-weight: 300;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	h5{
		@extend %base-text;
		font-size: $s-size;
		margin-top: 35px;
		margin-bottom: 10px;
	}

	h6{
		@extend %base-text;
		font-size: $xs-size;
		font-weight:700;
		margin-top: 10px;
		margin-bottom: 10px;
		line-height: 1.5;
	}

	p,
	figcaption { 
		font-size: $xs-size; 
		font-weight: 300;
		line-height: 1.5;
		margin-bottom: 20px !important;

		&.o-note {
			border-left: 2px solid $cta-green-digital;
			padding: 25px;
			font-family: $roboto;
			font-size: $xxs-size;
			font-weight: 300;
		}

		&.c-small-paragraph {
			font-size: $xxs-size;
			line-height: 2.1rem;
		}

		&.c-first-letter {
			&::first-letter {
				font-size: 74px;
				line-height: 45px;
				font-weight: 400;
				font-family: $robotoSlab;
				display: block;
				float: left;
				margin-top: 10px;
				padding-right: 5px;
			}
		}

		.c-evidenced {
			background-color: #E8F0BE;
		}

		.c-indented {
			display: block;
			padding-left: 25px;
			border-left: 6px solid $cta-green-digital;
		}
	}

	figcaption.swipebox-title {
		color: #264d7a;
		display: block;
		font-family: Roboto Slab, serif;
		font-size: 1.8rem;
		font-weight: 400;
		margin-bottom: 12px !important;
		text-align: center;
	}

	ul{
		font-size: $xs-size;
		font-weight: 300;
		list-style: none;
		list-style-position: outside;
		margin-bottom: 20px;
		padding-left: 25px;
		position: relative;

		&.roboto{
			font-family: $roboto;
		}
		&.roboto-condesend{
			font-family: $robotoCondensed;
		}
		&.roboto-slab{
			font-family: $robotoSlab;
		}
		&.arial {
			font-family: $arial;
		}
		&.arial-narrow {
			font-family: $arialNarrow;
		}
		&.ul-small {
			font-size: $xxs-size;
			line-height: 2.1rem;
		}
	}

	.o-square{
		li{
			&:before{
				content: "\25A0";
				color: $cta-green-digital;
				display: inline-block;
				width: 0.6rem;
				margin-right: 25px;
				position: absolute;
				left: 0rem;
			}
		}
		
	}

	.o-square.arial li:before,
	.o-square.arial-narrow li:before {
		font-style: normal;
		font-weight: normal;
	}
	
	.o-number { 
		list-style: none; 
		counter-reset: li;
		position: relative;
		padding-left: 28px;
		margin-bottom: 20px;
		li{
			counter-increment: li;
			font-size: $xs-size;
			font-weight: 300;
			&:before{
				color: #222;
				content: counter(li); 
				font-weight: 400;
				display: inline-block; 
				left: 0rem;
				position: absolute;
				width: 0.6rem;
			}
		}
	}

	.o-number-list {
		list-style: none; 
		font-size: $xs-size;
		font-weight: 300;
		padding-left: 0;
		li{
			display: block;
			margin: 10px 0 20px;
			span:not(.number) {
				display: block;
			}
			span.number {
				position: relative;
				font-family: $roboto;
				font-weight: 100;
				display: inline-block;
				font-size: $xxxl-size;
				color: $dark-blue-digital;
				border-bottom: 2px solid $dark-blue-digital;
				padding-bottom: 10px;
				width: auto;
				&::before {
					content: ".";
					position: absolute;
				    right: -12px;
    				bottom: 9px;
					display: block;
				}
			}
		}
	}

	.o-abstract {
		font-family: $robotoSlab;
		font-size: $xs-size;
		border-top: 4px solid #E7EAEC;
		border-bottom: 4px solid #E7EAEC;
		padding: 25px 20px;
		line-height: 2.4rem;
		color: $dark-grey;
		font-weight: 400;
		margin: 0px 0 50px 0;
	}

	blockquote {
		font-family: $roboto;
		font-size: 2rem;
		font-weight: 300;
		line-height: 1.5;
		margin: 32px 0 32px 80px;
		border-left: 3px solid $cta-green-digital;
		padding: 15px 30px;
		background-color:#F5F5F5;
		&.blockquote-b {  
			position: relative;
			text-align: center;
			border: none;	
			margin: 32px auto;
			background-color: #fff;
			padding: 25px 30px;
			max-width: 940px;
			&:before{
				content: "";
				width: 360px;
				left: 50%;
				height: 2px;
				background-color: #222;
				display: block;
				position: absolute;
				margin-left: -180px;
				z-index: 1;
				top: 0;
			}
			&:after{
				content: "";
				width: 120px;
				left: 50%;
				height: 2px;
				background-color: #222;
				display: block;
				position: absolute;
				margin-left: -60px;
				z-index: 1;
				bottom: 0;
			}
			.blockquote-b-content {
				display: block;
				position: relative;
				&:before{
					content: "";
					width: 15px;
					left: 50%;
					height: 15px;
					display: block;
					background: white url('../../assets/images/apici.svg') top/contain no-repeat;
					padding-left: 20px;
					padding-right: 20px;
					position: absolute;
					margin-left: -20px;
					top: -32px;
					z-index: 2;
				}
				.blockquote-b-sign {
					font-style: italic;
					display: block;
					margin-top: 25px;
					font-size: 1.8rem;
				}  
			}  
		}
	}

	.c-responsive-image {
		display: block;
		max-width: 100%;
		height: auto;
		margin: auto;
	}

	.c-main-title {
		margin-bottom: 50px;
		margin-top: 10px;
		position: relative;

		&:before{
			content: '';
			width: 83px;
			height: 72px;
			display: block;
			background: url('../../assets/images/double-quote-light.svg') top center/contain no-repeat;
			right: 100%;
			top: 5px;
			margin-right: 10px;
			left: 0;
			right: auto;
			float: left;
		}

		&.no-quote {
			&:before{ 
				display: none;
			}
		}

		h1 {
			color:$dark-grey;
			font-family: $roboto;
			font-size: $xxxl-size;
			font-weight: 300;
			margin: -5px 0 0 0;
			line-height: 1.2;
			position: relative;
			top: -8px;
		}

		h2,
		p {
			font-size: $m-size;
			font-weight: 300;
			font-family: $robotoSlab;
			color: $dark-grey;
			margin: -3px 0 0 0;
			line-height: 1.2;
		}
	}

	.o-button {
		
		background: $green-digital;
		color: #fff !important;
		float: left; 
		font-size: $xs-size;
		font-weight:600;
		letter-spacing:0.8px;
		margin: 10px 0 20px 0;
		padding: 10px 20px;
		text-decoration: none;
		text-transform: uppercase;
		@include transition( all 0.3s ease-out );

		&:hover{
			background: $dark-blue-digital;
			color: #fff !important
		}
	}

}

.c-table {
	font-size: $m-size;
    font-weight: 300;
	margin-bottom: 50px;
	
	h3 {
		font-family: $roboto;
		font-weight: 400;
		font-size: $xxs-size;
		color:$dark-blue-digital;
	}

	.row {
		border-bottom: 1px solid #949494;
		padding: 10px 0;

		p {
			margin: 0;
			font-family: $roboto;
			font-weight:300;
			font-size: $xxs-size;
			color:$dark-grey;
		}
	}

}

.c-table-header {
	color: $green-digital;
	text-transform: uppercase;

	h6 {
		font-size: $xxs-size;
	}
}


.link-border-bottom {
	border-bottom: solid 1px $dark-grey;

	&:hover{
		border-bottom: solid 1px $green-digital;
	}
}

.c-table-dati {

	thead{
		td {
			font-family: $roboto;
			font-weight:700;
			font-size: $xxs-size;
			color:$dark-blue-digital;
			line-height: 1.2;
		}
	} 

	tbody{
		td {
			font-family: $roboto;
			font-weight:300;
			font-size: $xxs-size;
			color:$dark-grey;
		}
	}
}

.c-layouts-navigation.c-navigation>ul>li>a.o-area-riservata {
    font-size: $xxs-size;
	font-weight:600;
	letter-spacing:0.8px
}

.c-fascia-tab {
	margin-bottom: 50px;

	a{
		border:0;
		&:hover{
			border:0;

			img {
				display: none;
				&.o-hover {
					display: inline-block
			   }
			}
		}
	}

	.col-sm-2 > a {
		margin: 0 15px;
	}

	img {
		width: 22px;
		
		&.o-hover {
			display: none;
		}
	}

	.container {
		padding: 0;
        max-width: 100%;
    }

    .row {
		border-bottom: 3px solid #F5F5F5;
		padding: 10px;
		font-size: $xs-size;
        font-weight: 300;
		line-height: 4rem;

		&:last-child{
			border-bottom: 0px
		}

        &.o-title {
            color: $dark-blue-digital;
            text-transform: uppercase; 
            font-size: $xs-size;
            font-weight: 700;
        } 
	}
    
} 

/* Media
   ---- */

@media (min-width: 1301px) {
	.c-main-title {
		margin-left: -93px;
		&.no-quote {
			margin: 10px 0 0;
		}
	}
}

@media (max-width: $media-xs) { 

	.c-main-content{

		h1 {
			font-size: 3.6rem;
			line-height: 4.8rem;
		}

		h2 {
			font-size: 2.8rem;
			line-height: 3.6rem;
		}

		.color-palette {
			p {
				line-height: 1.3;
			}
		}

		.o-abstract {
			margin: 0px 0 30px 0;
		}

		blockquote {
			font-size: 1.8rem;
			line-height: 2.8rem;
			margin-left: 40px;
		}

		p,
		figcaption { 
			&.c-first-letter {
				&::first-letter {
					font-size: 54px;
					line-height: 38px;
					margin-top: 4px;
				}
			}
		}
		
		.c-main-title {
			padding-left: 0px;
			margin-bottom: 30px;
			&:before{
				display: none;
			}

			h1{
				font-size: 3.6rem;
				line-height: 4.8rem;
			}
		}

		.o-number-list {
			li{
				span.number {
					font-size: 3rem;
				}
			}
		}

	}
	
}







 