// @font-face {
//     font-family: 'digitalux-icon-fontregular';
//     src:url('../fonts/digitalUx-icon-font.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

html {
    min-height: 100%;
}

html,
body {
    color: $dark-grey;
    font-family: $roboto;
    margin: 0;
    padding: 0;
    // font-size: 62.5% !important;
}

body.no-scrolling {
    overflow: hidden;
}

.l-container-fluid {
    width: 100%;
    padding: 0 20px;
    zoom: 1;
}

.float-right {
    float: right
}

.demohome a {
    display: block;
    width: 100%;
    margin: 20px 0
}

// a:focus {
//     outline: 0px auto -webkit-focus-ring-color;
//     outline-offset: 0px;
// }

a.o-img-hover img {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

a.o-img-hover:hover img {
    opacity: .7;
}

.c-main-content {

    line-height: 2.5rem;

    a.green-cta {
        background: #b2ce27;
        border: none !important;
        border-radius: 0;
        bottom: 18px;
        color: #264d7a !important;
        display: inline-block;
        font-family: Roboto, sans-serif;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.5rem;
        margin: 0 25px 0 0;
        min-height: 50px;
        outline: 0;
        padding: 12px 25px;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        -webkit-transition: background-color .3s;
        transition: background-color .3s;

        &:hover {
            background: #264d7a;
            color: #fff !important;
        }
    }

    .margin-bottom {
        margin-bottom: 20px !important;
    }

    .margin-top {
        margin-top: 20px !important;
    }

    .mb-2 {
        margin-bottom: 40px !important;
    }

    .c-how-to {
        background: url("../images/light-bulb.svg") no-repeat left 26px;
        background-size: 60px 130px;
        padding: 0 0 23px 70px;
        min-height: 160px;
        margin: 0;

        .o-title {
            font-size: 18px;
            font-family: $robotoSlab;
            margin-bottom: 15px;
        }

        p {
            font-size: 16px;
        }

        p.o-contatto {
            padding-left: 15px
        }

        a {
            word-break: break-all;
        }
    }

}


pre {
    color: $dark-grey;
    margin-bottom: 20px;
}

.align-center {
    margin-left: auto;
    margin-right: auto;
}

.word-break-all {
    word-break: break-all;
}

.fright {
    float: right;
}

.menu-index {

    .c-scroll-nav {
        list-style: none;
        padding-left: 0;
        margin-bottom: 50px;
        font-size: 1.5rem;

        a {
            color: $dark-blue-digital;
            position: relative;
            display: inline-block;
            text-decoration: none;
            @include transition(all 0.3s ease-out);

            &:hover {
                color: $green-digital;
            }
        }
    }
}


@media (max-width: $media-xs) {

    .c-main-content {
        line-height: 2.4rem
    }

}

.c-main-content {

    .c-per-info {
        background: #fff;
        padding: 30px 10px 10px 0;
        border-bottom: 3px solid #b2ce27;
        border-radius: 10px;
        z-index: 850;
        box-shadow: 0 3px 5px #a8a8a8;
        position: relative;
        margin: 20px 0;
        position: relative;

        &:before {
            background: #b2ce27 url("../images/icon/icona-info.svg") no-repeat center center;
            top: 0;
            transform: translateY(-50%);
            content: "";
            position: absolute;
            left: 40px;
            width: 40px;
            height: 40px;
            border-radius: 40px
        }

        p {
            margin-top: 5px;
            margin-bottom: 5px !important;
            padding-left: 40px;
            font-size: 1.6rem
        }
    }
}

@media (max-width: $media-xs) {
    .c-main-content {

        .c-per-info {

            &:before {
                left: 20px;
            }

            p {
                padding-left: 20px;
            }
        }
    }
}

/** archivio loghi **/

.c-box-grigio div,
.c-box-sfondogrigio,
.c-box-sfondogrigio div {
    text-align: center;
    padding: 20px;
    position: relative;
    min-height: 273px;
}

.c-box-grigio a,
.c-box-sfondogrigio a {
    position: absolute !important;
    bottom: 20px;
    left: 50%;
    display: inline-block;
    -webkit-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
}

.c-main-content .c-box-grigio p,
.c-main-content .c-box-sfondogrigio p {
    margin-bottom: 40px !important;
}

.c-box-grigio .o-titoletto,
.c-box-sfondogrigio .o-titoletto {
    font-size: 2.4rem;
    color: #264d7a;
    font-weight: 400;
    margin-bottom: 40px !important;
}

.c-box-grigio h2,
.c-box-sfondogrigio h2 {
    margin-top: 0px !important;
}

.c-box-grigio div,
.c-box-sfondogrigio {
    background: #f5f5f5;
    margin-bottom: 10px;
}

.c-box-grigio {
    margin: 20px 0;
}

.c-box-grigio div,
.c-box-sfondogrigio div {
    box-shadow: 0 2px 5px #a8a8a8;
}

.c-box-sfondogrigio div {
    background: #fff;
}

@media (max-width: $media-xs) {

    .c-box-grigio div,
    .c-box-sfondogrigio {
        margin-bottom: 0px;
    }
}

@media (min-width: $media-sm) and (max-width: $media-md) {

    .c-box-grigio div,
    .c-box-sfondogrigio,
    .c-box-sfondogrigio div {
        min-height: 320px;
    }
}


.right-alignement {
    text-align: right;
}

.left-alignement {
    text-align: left;
}

.center-alignement {
    text-align: center;
}

.c-main-content .roboto-slab {
    font-family: Roboto Slab !important;
}

.c-main-content .regular {
    font-weight: 400 !important;
}

.c-main-content .size-20 {
    font-size: 2rem !important;
}

.f5f5f5-bg {
    background-color: #F5F5F5;
}

.vertical-alignement-bottom {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    width: calc(50% - 2px);
}

@media (max-width:767px) {
    .vertical-alignement-bottom {
        display: block;
        width: 100%;
    }
}

@media (min-width:768px) {
    .desktop-visible {
        display: inline-block;
    }

    .mobile-visible {
        display: none;
    }
}

@media (max-width:767px) {
    .desktop-visible {
        display: none;
    }

    .mobile-visible {
        display: inline-block;
    }
}

.no-padding {
    padding: 0;
}

/* Formattazione box come in /metodologia-standard.php */
.big-number-wrapper {
    display: table;
    width: 100%;
}

.big-number-wrapper div {
    display: table-cell;
    vertical-align: bottom;
}

.big-number-wrapper div.big-number {
    color: #92A6BC;
    font-family: Roboto Slab, serif;
    font-size: 16rem;
    font-weight: 400;
    line-height: 14rem;
}

.big-number-wrapper h4 {
    color: #222;
    text-transform: uppercase;
    font-weight: bold;
}

.big-number-wrapper div:nth-child(1) {
    min-width: 130px;
}

.big-number-wrapper div:nth-child(2) {
    width: 100%;
}

.big-number-wrapper div p {
    margin-bottom: 0 !important;
}

/* Fine */

.cosafareper-spacer {
    height: 40px;
}

.c-main-content .no-margin {
    margin: 0 !important;
}

.bcc-publisher-ready {
    background: url(../images/bcc-publisher-ready-bg.jpg) no-repeat 3px 4px;
    border: 1px solid #A3BF19;
    border-radius: 6px;
    bottom: 15px;
    color: #222;
    display: inline-block;
    font: 1.1rem Roboto Slab, serif;
    font-weight: 700;
    padding: 8px 5px 5px 25px;
    position: absolute;
    right: 0;
}

@media (max-width: 767px) {
    .bcc-publisher-ready {
        bottom: auto;
        margin-bottom: 20px;
        position: relative;
    }
}

.bcc-publisher-ready.margin-right {
    margin-right: 15px;
}

.bcc-publisher-ready.tab-banner-slideshow {
    right: 15px;
}

@media (max-width: 767px) {
    .bcc-publisher-ready.tab-banner-slideshow {
        right: 0;
    }
}

#overlay .bcc-publisher-ready,
#sede-contatti .bcc-publisher-ready,
#contattaci .bcc-publisher-ready,
#store-locator .bcc-publisher-ready,
#tooltip .bcc-publisher-ready,
#feedback-alert .bcc-publisher-ready {
    bottom: 0;
    float: right;
    margin: 0 0 20px;
    position: relative;
}

@media (max-width: 767px) {

    #overlay .bcc-publisher-ready,
    #sede-contatti .bcc-publisher-ready,
    #contattaci .bcc-publisher-ready,
    #store-locator .bcc-publisher-ready,
    #tooltip .bcc-publisher-ready,
    #feedback-alert .bcc-publisher-ready {
        float: none;
    }
}

.cancbrandizzata-page figure img {
    margin: auto;
}

/* Nuova pag accesso al Manuale */
html,
body.is-login {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    height: 100% !important;
}

#scegli-manuale {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.text-center {
    text-align: center;
}

.header-scegli-manuale {
    background-color: #00843D;
    padding: 15px 0;

    @media (max-width: 767px) {
        img {
            max-width: 100%;
        }
    }
}

.contenuti-scegli-manuale {
    flex-grow: 1;
    padding: 30px 0;

    h1 {
        color: #fff;
    }

    h2 {
        color: #003594;
        font-size: 3rem;
        font-weight: 500;
        padding: 35px 0;
        
        img {
            max-width: 100%;
            width: 350px;
        }
    }

    #logo-bianco-bcc-nuovaschermata {
        height: auto;
        padding: 0;
        width: 240px;
    }

    h2~p {
        font-size: 2rem;
        font-weight: 500;
    }

    h2~p:last-child {
        font-size: 1.6rem;
        font-weight: 400;
        margin-bottom: 0;
        padding-bottom: 20px;
    }

    #linee-guida-daisy {
        padding-top: 7px;
    }

    @media (max-width: 1199px) {
        #linee-guida-daisy {
            padding-top: 78px;
        }
    }
    
    @media (max-width: 991px) {
        #linee-guida-daisy {
            padding-top: 0;
        }
    }

    .cta-primaria {
        background-color: #006f34;
        background-image: linear-gradient(270deg, #008766 0%, #006f34 100%);
        border: 0;
        border-radius: 26px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .1);
        color: #fff;
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 700;
        margin: 30px 0 60px;
        padding: 11px 20px;
        position: relative;
        text-decoration: none;
        z-index: 0;
    }

    .cta-primaria:focus,
    .cta-primaria:hover {
        color: #fff;
        outline: 0;
        text-decoration: none;
    }

    .cta-primaria:before {
        background-color: #00491f;
        background-image: linear-gradient(270deg, #006D52 0%, #00491f 100%);
        border-radius: 26px;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .3s;
        z-index: -1;
    }

    .cta-primaria:focus:before,
    .cta-primaria:hover:before {
        opacity: 1;
    }

    .row:nth-child(2) {
        padding: 60px 0;
    }

    .row:nth-child(2) .text-center>div {
        background-color: #fff;
        box-shadow: 0 3px 6px #d6d6d6 inset;
        padding: 0 25px;
    }

    .row:nth-child(2) .text-center:first-child img {
        box-sizing: content-box;
        height: 96px;
    }

    a:not(.cta-primaria) {
        text-decoration: underline;
        color: #006F34;
        display: inline-block;
        word-break: break-all;
        word-break: break-word;
    }

    a:not(.cta-primaria):focus,
    a:not(.cta-primaria):hover {
        color: #00491f;
        outline: 0;
    }

    a:not(.cta-primaria):focus-visible,
    a:not(.cta-primaria):focus-visible {
        border-radius: 6px;
        box-shadow: 0 0 0 3px #006F34, 0 0 0 5px #fff;
    }

    @media (max-width: 991px) {
        .row:nth-child(2) .text-center:nth-of-type(2) {
            margin-top: 100px;
        }
    }
    
    @media (max-width: 767px) {
        #logo-bianco-bcc-nuovaschermata {
            width: 200px;
        }

        h1 {
            font-size: 3.2rem;
        }

        h2 {
            font-size: 2.4rem;
        }
        
        h2 ~ p {
            font-size: 1.8rem;
            margin: 0;
        }
        
        .cta-primaria {
            margin: 20px 0;
        }
        
        h2~p:last-child {
            font-size: 1.4rem;
            
        }
    }
}

@media (max-width: 991px) {
    #scegli-manuale {
        display: block;
    }
}

.footer-scegli-manuale {
    .l-footer {
        background-color: #006D52;
    }

    .row {
        position: relative;
    }

    .col-md-2.text-right {
        bottom: 0;
        position: absolute;
        right: 0;
    }

    @media (max-width: 991px) {
        .col-md-2.text-right {
            position: relative;
        }
    }

    p,
    a {
        color: #fff;
        font-size: 1.4rem;
        margin-bottom: 0 !important;
    }

    a {
        text-decoration: underline;
        display: inline-block;
    }

    a:focus,
    a:hover {
        outline: 0;
    }

    a:focus-visible,
    a:focus-visible {
        border-radius: 6px;
        box-shadow: 0 0 0 3px #006F34, 0 0 0 5px #fff;
    }
}

/* Fine nuova pag accesso al Manuale */

/* Accessibilità: Skip navigation */
#skiptocontent {
    background-color: rgba(0, 0, 0, .3);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all .3s ease;
    visibility: hidden;
    z-index: 987656789;
}

#skiptocontent.is-visible {
    opacity: 1;
    visibility: visible;
}

#skiptocontent a {
    background-color: #fff;
    border: 2px solid #264d7a;
    color: #264d7a;
    font-size: 1.6rem;
    font-weight: 300;
    left: -10000px;
    line-height: 1.5;
    opacity: 1;
    padding: 15px;
    position: relative;
    text-decoration: none;
    visibility: visible;
    top: -10000px;
}

#skiptocontent a:focus {
    left: 10px;
    outline: none;
    top: 28px;
}

#skiptocontent span {
    border-bottom: 1px solid #264d7a;
}

#maincontent {
    outline: none;
}

/* Fine skip navigation */