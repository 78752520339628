/* Roboto 100
   ---- */

   @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/roboto/roboto-v16-latin-100.eot'); /* IE9 Compat Modes */
    src: local('Roboto Thin'), local('Roboto-Thin'),
         url('../fonts/roboto/roboto-v16-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto/roboto-v16-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-100.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto/roboto-v16-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto 300
     ---- */
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto/roboto-v16-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'),
         url('../fonts/roboto/roboto-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto/roboto-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto/roboto-v16-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto 400
     ---- */
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto/roboto-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url('../fonts/roboto/roboto-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto/roboto-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto/roboto-v16-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto 500
     ---- */
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto/roboto-v16-latin-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'),
         url('../fonts/roboto/roboto-v16-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto/roboto-v16-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto/roboto-v16-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto 700
     ---- */
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto/roboto-v16-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
         url('../fonts/roboto/roboto-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto/roboto-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto/roboto-v16-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto 900
     ---- */
  
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/roboto/roboto-v16-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Roboto Black'), local('Roboto-Black'),
         url('../fonts/roboto/roboto-v16-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto/roboto-v16-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto/roboto-v16-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto/roboto-v16-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto-Slab 300
     ---- */
  
  @font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-slab/roboto-slab-v6-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
         url('../fonts/roboto-slab/roboto-slab-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-300.svg#RobotoSlab') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto-Slab 400
     ---- */
  
  @font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-slab/roboto-slab-v6-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
         url('../fonts/roboto-slab/roboto-slab-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto-Slab 700
     ---- */
  
  @font-face {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-slab/roboto-slab-v6-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
         url('../fonts/roboto-slab/roboto-slab-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-slab/roboto-slab-v6-latin-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto-Condensed 300
     ---- */
  
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-condensed/roboto-condensed-v14-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto-Condensed 400
     ---- */
  
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-condensed/roboto-condensed-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  
  /* Roboto-Condensed 700
     ---- */
  
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-condensed/roboto-condensed-v14-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-condensed/roboto-condensed-v14-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }