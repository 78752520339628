.c-archivio {
	font-size: 1.7rem;
	padding-top: 50px;
	padding-bottom: 50px;
	font-weight: 300
}


.c-percorso-cartella {
	font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 20px;
}

.c-percorso-cartella, .c-percorso-livello {
    padding-left: 28px;
}
.c-percorso-cartella a {
	color: #284a3d;
    text-decoration: underline;
    -webkit-transition: background-color .5s, color .5s;
    transition: background-color .5s, color .5s;
    border: 0;
}
.c-percorso-cartella a:hover {
	color: #b2ce27;
	border: 0;
}


.c-percorso-livello a {
	display: inline-block;
	font-size: 1.4rem;
    color: #fff;
    text-decoration: none;
    background-color: #B2CE27;
    padding: 10px 15px;
    transition: opacity .3s;
    border: 0;
}
.c-percorso-livello a:hover {
	opacity: .6;
	border: 0;
	color: #fff
}

.c-cartella:before {
    background-image: url("../images/cartella_cerchio-ombra-blu.svg");
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: center left;
    content: 'cartelle';
    display: block;
    text-transform: uppercase;
    color: #264d7a;
    position: absolute;
    background-color: #fff;
    padding: 20px 10px 20px 70px;
    z-index: 10;
    top: -34px;
    left: -2px;
    font-size: 2.5rem;
    font-weight: 400;
}


.c-cartella {
	border: 1px solid #264d7a;
    padding: 30px;
    position: relative;
    margin-bottom: 15px
}

.c-cartella {
	border: 2px solid #264d7a;
}

.c-cartella.c-risultati {
	border: 1px solid #B2CE27;
	padding:50px;
}

.c-cartella.c-risultati:before {
    content: attr(box-title);
    color: #B2CE27;
}

.c-cartella.c-risultati thead td {
    color: #B2CE27;
}

.c-cartella.c-risultati td{
	font-size:1.7rem;
	padding:0;
}

.c-cartella.c-risultati tr {
    border-bottom: 3px solid #F5F5F5;
}
.c-cartella.c-risultati span {
	display:block;
	font-weight:700
}

.c-risultati-layout-a {
	margin:50px 0;
}

// .c-risultati-layout-a:before {
//     background-image: url(../images/contact-form.svg);
// }

.c-cartella.c-risultati.c-risultati-layout-b {
	border: none;
	padding: 25px 0 0 0;
	margin-top: 78px;
}

.c-cartella.c-risultati.c-risultati-layout-b p {
	margin-left: 74px;
}

.c-cartella.c-risultati.c-risultati-layout-b .c-doc {
	border: 2px solid #264d7a;
	padding: 50px;
}

.c-cartella.c-risultati.c-risultati-layout-b:before {
	color: #264d7a;
	left:0;
	top:0;
    padding: 20px 10px 20px 75px;
    font-weight: 400;
	text-transform:none;
	// background-size: initial;
	background-size: contain;
    -ms-transform: translate(-2px, -36px);
    -webkit-transform: translate(-2px, -36px);
    transform: translate(-2px, -36px);
}

.c-cartella.c-risultati.c-risultati-layout-c {
	margin-top: 78px;
	border: 2px solid #264d7a;
}

.c-cartella.c-risultati.c-risultati-layout-c:before {
	color: #264d7a;
	left:0;
	top:0;
    padding: 20px 10px 20px 75px;
    font-weight: 400;
	text-transform:none;
	// background-size: initial;
	background-size: contain;
    -ms-transform: translate(-2px, -36px);
    -webkit-transform: translate(-2px, -36px);
	transform: translate(-2px, -36px);
}

.c-cartella.c-risultati.c-risultati-layout-b tr ,
.c-cartella.c-risultati.c-risultati-layout-c tr {
    font-size: 1.7rem;
    height: 6.3rem;	
	border-bottom: 3px solid #F5F5F5;
}

.c-cartella.c-risultati.c-risultati-layout-b thead td ,
.c-cartella.c-risultati.c-risultati-layout-c thead td {
    font-size: 1.7rem;
    color: #264D7A;
    text-transform: uppercase;
	font-weight: 400;
	vertical-align:middle
}

.c-cartella.c-risultati.c-risultati-layout-b tbody td ,
.c-cartella.c-risultati.c-risultati-layout-c tbody td {
    font-size: 1.7rem;
    color: #222;
	vertical-align:middle
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td a ,
.c-cartella.c-risultati.c-risultati-layout-c tbody tr td a {
	border:none;
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td img ,
.c-cartella.c-risultati.c-risultati-layout-c tbody tr td img {
	width:30px;
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td a:hover img ,
.c-cartella.c-risultati.c-risultati-layout-c tbody tr td a:hover img {
    // display: none;
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td a img.o-hover ,
.c-cartella.c-risultati.c-risultati-layout-c tbody tr td a img.o-hover {
    display: none;
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td a:hover img.o-hover ,
.c-cartella.c-risultati.c-risultati-layout-c tbody tr td a:hover img.o-hover {
    display: inline-block;
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td:nth-child(1) {
    width:46%;
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td:nth-child(2) {
    width:24%;
}

.c-cartella.c-risultati.c-risultati-layout-b tbody tr td:nth-child(3) {
    width:30%;
}

.c-cartelle {
	list-style-type: none;
	padding-left: 0
}
.c-cartelle a {
	color: #264d7a;
	text-decoration: none;	
    border: 0;
}
.c-cartelle a:hover {
    border: 0;
}
.c-cartelle li {
	padding: 10px 0
}
.c-cartelle li img { margin-top: -2px; margin-right: 10px }


.c-doc {	
	color: #000;
}
.c-doc thead td, .c-doc thead td a {
	text-transform: uppercase;
	font-weight: 500
}
.c-doc a {
	color: #000;
	-webkit-transition: color .5s;
    transition: color .5s;
    border: 0
}

.c-doc a:not(.page-link):hover {
	color: #B2CE27;
	text-decoration: none;
    border: 0
}

.c-doc thead {
	color: #264d7a;
}

.c-doc tr {
	border-bottom: 1px solid #000;
}
.c-doc .table>tbody>tr>td { border: 0px; padding: 15px 0 }
.c-doc .table>thead>tr>td { padding: 0px; padding-bottom: 15px } 
.c-doc img {  width: 20px; height: 20px; }


/* assets/images/cartellaPiu.gif */

.c-cartelle li {
	background: url("../images/cartella-nera.svg") no-repeat left 10px;
	background-size: 54px 20px;
	padding-left: 65px;
}
.c-cartelle .o-folder-children {
	background-image: url("../images/cartellaPiu-nera.svg");
	background-size: 54px 20px;
}

.c-doc .table td:first-child { width: 12% }
.c-doc .table td:last-child { width: 15% }

@media (max-width: 660px) {
	
	.table {
		display: block;
		overflow: auto;
	}

	.c-cartella.c-risultati.c-risultati-layout-b td ,
	.c-cartella.c-risultati.c-risultati-layout-c td {
		padding-left:10px;
		padding-right:10px
	}

	.c-cartella.c-risultati.c-risultati-layout-b thead td ,
	.c-cartella.c-risultati.c-risultati-layout-c thead td {
		vertical-align:top
	}

}


@media (max-width: 768px) {
	.c-cartella:before {
		background-size: 40px;
		padding: 8px 10px 8px 45px;
		top: -20px;
		font-size: 2.2rem;
	}
	.c-cartella {
    	padding: 30px 10px;
	}
	.c-percorso-cartella, .c-cartelle {
	    font-size: 1.2rem;
	}
	.c-cartelle { 
		
	}
	.c-doc .table>thead>tr>td { padding-bottom: 10px } 
	
	.c-archivio .c-pagination .pagination>li>a {
    	margin: 0 7px 7px 7px;
	}
}


@media (max-width: 480px) {
	.c-archivio .col-xs-4, .c-archivio .col-xs-8 { width: 100%; float: none }
	.c-archivio .c-pagination .pagination>li>a {
    	margin: 0 2.5px 5px 2.5px;
	}
}



@media (max-width: 992px) {

	.c-risultati-layout-a {
		margin-top: 100px;
	}	

	.c-risultati-layout-a:before  {
		left:0;
		-ms-transform: translate(0, -60px);
		-webkit-transform: translate(0, -60px);
		transform: translate(0, -60px);
	}	

}