.c-scroll-control {
    position: fixed;
    display: none;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    z-index: 20;
}

.c-scroll-control li {
    margin: 10px 0;
    float: right;
    clear: right;
    list-style: none;
}

.c-scroll-control a:link,
.c-scroll-control a:visited {
    display: block;
    width: 15px;
    height: 15px;
    position: relative;
    font-size: 14px;
    text-align: center;
    text-transform: lowercase;
    font-weight: 400;
    text-decoration: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    background-color: #949494;
    overflow: hidden;
    opacity: 1;
    color: rgba(255,255,255,0);
}

.c-scroll-control a:hover,
.c-scroll-control a:active,
.c-scroll-control a:focus {
    width: 120px;
    height: 120px;
    color: rgba(255,255,255,1);
    background-color: #264d7a;
    outline: none;
}
.c-scroll-control:focus {
    outline: none;
}
.c-scroll-control span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.c-scroll-link.is-selected {
    background-color: #264d7a !important;
}