.c-main-banner-content {
	padding-top:0;
	padding-bottom:0;
}

h2.c-banner-subtitle {
    margin-top: 40px;
    margin-bottom: 40px;
}

.nopadding { padding: 0 }

.banner { position: relative }

.banner .carousel-caption {
		background: rgba(255,255,255,0.9);
		text-shadow: none;
		text-align: right;
}
@media (min-width:801px) {
	.banner .carousel-caption {
		right: 0;
		left: 0;
		top: 0;
		height: 100%;
	}
}
.banner .carousel-inner { 
		min-height:600px; }
		
.banner .carousel-caption h2 {
	font-weight: 100;
	font-size: 6rem;
	margin-bottom:0px !important;
	margin-top: 50px;
	color: #264d7a;
}

.banner .carousel-caption h3 {
	font-weight: 100;
	font-size: 2.4rem;
	margin-top: 0;
	margin-bottom: 25px;
	font-family: 'Roboto slab', serif;
	color: #264d7a;
}
.banner .carousel-caption p.infotesto {
	color: #222;
	font-size: 1.8rem;
    margin-left: 30%;
    line-height: 2rem;
    border-top: 1px solid #b2ce27;
    margin-top: 15px;
    padding-top: 10px;
    padding-left: 10%;
    font-weight: 100;
    
}
.banner .carousel-caption .o-button {
	background: #b2ce27;
    border: none!important;
    border-radius: 0;
	color: #264d7a!important;
	float: right;
    font-family: Roboto,sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.5rem;
    min-height: 50px;
    outline: 0;
    padding: 12px 25px;
    position: relative;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
}
.banner .carousel-caption .o-button:hover {
	background: #264d7a;
    color: #fff!important;
}

@media (min-width:801px) {
	.banner .carousel-caption .interno {
		padding: 0 40px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%); /* per la centratura in verticale */
		right: 0
	}
}

.banner .carousel-control.left, .carousel-control.right {
    background-image: none;
    background-repeat: repeat-x;
}
.banner .carousel-control .glyphicon-chevron-left, .banner .carousel-control .glyphicon-chevron-right {
    width: auto;
    height: auto;
    padding: 10px;
    margin: 0;
    margin-top: -10px;
    font-size: 1rem;
    background: rgba(38, 77, 122,.6) !important;
        padding: 17px
}
.banner .carousel-control .glyphicon-chevron-left {
	bottom: 13%;
    top: auto;
    left: 0
}
.banner .carousel-control .glyphicon-chevron-right {
	bottom: 13%;
    top: auto;
    right: 0
}


.banner .carousel-control {
    width: 5%;
    opacity: 0;
    -ms-transition: all .3s;
    transition: all .3s;
}


.banner .carousel-control .glyphicon-chevron-left:before {
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg) !important
}
.banner .carousel-control .glyphicon-chevron-right:before {
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    -ms-transform: translate(-50%, -50%) rotate(135deg);
    transform: translate(-50%, -50%) rotate(135deg) !important
}
.banner .carousel-control .glyphicon-chevron-left:before, .banner .carousel-control .glyphicon-chevron-right:before {
    content: '';
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
}

/*.banner .glyphicon-chevron-left {
    -ms-transform: rotate(180deg); 
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.banner .glyphicon-chevron-left:before {
    content: url(../../assets/images/freccia-dx-blu.png);
}

.banner .glyphicon-chevron-right:before {
    content: url(../../assets/images/freccia-dx-blu.png);
}*/

.banner .item  { 
	background-position: left center;
	background-size: cover;
	min-height: 600px;
}

.banner .carousel-inner:hover .carousel-control {
	opacity: 1
}

.banner .carousel-control ,
.banner .carousel-control:hover {
	border:none
}

.banner .row { margin: 0}


.banner .c-indicatori-slide {
    background: rgba(38,77,122,.8) !important;
    position: absolute;
    bottom: 15px;
    z-index: 10;
    width: 100%;
    font-family: 'Roboto Slab', serif;
    
}
.banner .c-indicatori-slide .o-bullet {
	background: #fff;
	color: #264d7a;
	padding: 5px 0;
	text-align: center;
	text-transform: uppercase;
	border: 3px solid #264d7a;
	border-right: 0;
	-ms-transition: transform .3s, background-color .3s;
    transition: transform .3s, background-color .3s, opacity .3s;
    border-radius: 0px;
    
	
    	font-size: 1.8rem;
    display: block;
    width: 25%;
    height: 100%;
    margin: 0;
    text-indent: 0;
    cursor: pointer;
    background-color: #fff;

}
.banner .c-indicatori-slide .o-bullet:nth-last-of-type(1)  {
	border-right: 3px solid #264d7a;
}

.banner .c-indicatori-slide .o-bullet:hover {
	background: rgba(146,166,188,.4) !important;
	opacity:.8;
	color: #fff
}
.banner .carousel-indicators{
	position: relative;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
    text-align: center;
    list-style: none;
}
.banner .carousel-indicators .active {
	width: 25%; height: auto;
	border-top-color: #92a6bc
}


.banner .c-banner-dropup .dropdown-menu {
	position: absolute;
	margin-left: 0;
	border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Roboto', sans-serif;
    width: 100%;
 display: block;
    visibility: hidden;
  	opacity: 0;
  	transition: visibility 0s, opacity 0.5s linear;
    margin-bottom: 0px !important
}
.banner .dropup.open .dropdown-menu {
	opacity: 1;
	visibility: visible;
}
.banner .c-banner-dropup .dropup .o-dropdown {
	font-size: 2.4rem;
    text-align: left;
    padding: 0px 0 0px 10px;
    border: 3px solid #fff;
    display: block;
    border-radius:0
}
.banner .c-banner-dropup .dropup .o-dropdown:hover { color: #fff !important }
.banner .c-banner-dropup .dropup .o-dropdown:after { 
	border-left: 1px solid #fff;
    bottom: 0;
    color: #fff;
	content: '+';
    display: block;
    font-size: 3.6rem;
    font-weight: 100;
    // line-height: 3.5rem;
    padding: 0 11px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: 0;
    transition: background-color .3s;
}
.banner .c-banner-dropup .dropup.open .o-dropdown:after {
    content: '-';
    font-size: 5.1rem;
    // top: -2px;
    top: -13px;
}


.banner .c-banner-dropup .dropdown-menu li {
	border-bottom: 1px solid #264d7a;
}
.banner .c-banner-dropup .dropdown-menu li:last-child {
	border-bottom: 0px solid #264d7a;
}
.banner .c-banner-dropup .dropdown-menu li a {
	text-decoration: none;
	color: #acacac !important;
	padding: 10px;
	font-weight: 300;
}
.banner .c-banner-dropup {
	background: #264d7a;
	color: #fff;
	padding: 0px 0;
	text-align: center;
	text-transform: uppercase;
	border: 0px solid #fff;
	text-transform: initial !important;
	    
}
.banner .dropdown:hover {
}


@media (max-width:800px) {
    .banner .c-indicatori-slide .o-bullet:hover {
        background: #264d7a!important;
        opacity:1;
        color: #fff
    }
	.banner .carousel-control { display: none }
	.banner .carousel-caption  { 
		position: absolute;
		width: calc(100% - 30px); 
		margin: 0;
		right: 15px !important;
     	left: 15px !important;
     	bottom: 15px !important;
     }
	.banner .carousel-caption .interno { 
		position: relative;
		top: 0;
    	transform: inherit;
     }
     .banner .c-indicatori-slide {
     	position: relative;
     	bottom: 0
     }
     .banner .c-indicatori-slide .o-bullet { 
     	width: 15px;
    	height: 15px;
    	border: 0;
    	margin: 5px;
    	background: #acacac ;
     }
     .banner .c-indicatori-slide .o-bullet.active {
    	background: #264d7a 
     }
     .banner .c-indicatori-slide .o-bullet.active {
     	background: #264d7a
     }
     .banner .c-indicatori-slide .o-bullet:nth-last-of-type(1)  {
		border-right: 0px solid #264d7a;
	}
     .banner .c-indicatori-slide .o-bullet span {
     	display: none 
     }
     .banner .carousel-indicators {
		position: absolute;
		bottom: 85px;
		left: 50%;
		width: auto;
		padding-left: 0;
		margin-left: 0;
		text-align: center;
		list-style: none;
		transform: translateX(-50%);
	}
	.c-banner-dropup {
		width: 100%;
	}
	.c-banner-dropup .o-dropdown {
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}
	.c-indicatori-slide .container { width: 100%; margin:0 }
	.banner .carousel-caption p.infotesto {
    	margin-left: 0%
    }
    .banner .c-banner-dropup .dropup .o-dropdown:after { line-height: 5.8rem }
}

@media (max-width:480px) {
	.banner .item {
		min-height: 400px;
	}
	.banner .carousel-caption {
		position: absolute;
		width: 100%;
		margin: 0px auto;
		right: 0px !important;
		left: 0px !important;
		bottom: 15px !important;
		top: 15px;
	}
	.banner .item {
    	padding: 15px 0;
	}
	.banner .carousel-inner {
  	  min-height: 400px;
	}
	.banner .carousel-caption h2 {
		font-size: 4.5rem;
	}
	.banner .carousel-caption h3 {
		font-size: 1.6rem;
	}
}

/* Banner layout 2 */

.c-banner-layout-2 .c-indicatori-slide {
    background: none !important;
}

.c-banner-layout-2 .c-indicatori-slide .o-bullet {
	position:relative;
	height: 20px;
	width: 20px;
	display: inline-block;
	font-size: 0;
	background-color: #e8efec;
	float:none;
	border:none
}

.c-banner-layout-2 .carousel-indicators .active {
	background-color: #21436d;
	color: #21436d;
}
	
.c-banner-layout-2 .c-indicatori-slide .o-bullet:hover {
	opacity:1;
	background-color: #21436d !important;
}
	
.c-banner-layout-2 .carousel-indicators .active:hover {
	color: #b2ce27;
}
	
.c-banner-layout-2 .c-indicatori-slide .o-bullet:nth-last-of-type(1) {
	border-right: none;
}

@media (min-width: 801px) {

	.c-banner-layout-2 .carousel-indicators {
		left:auto !important;
		bottom:auto !important;
		margin:0 !important;
	}
	
}

@media (max-width: 800px) {

	.c-banner-layout-2 .carousel-indicators {
		bottom: 25px;
	}
	
	.c-banner-layout-2 .c-indicatori-slide .o-bullet {
		width:15px;
		height:15px;
		background-color: #acacac;
	}

}
@media (max-width: 1199px) {
	.c-banner-layout-2 .c-indicatori-slide .o-bullet {
		background-color: #acacac;
	}
    .c-banner-layout-2 .c-indicatori-slide .o-bullet.active {
		background-color: #21436d;
	}
}


/* Banner layout 3 */

.c-banner-layout-3 .c-indicatori-slide {
    background: #66696c !important;
}

@media (min-width: 801px) {

	.c-banner-layout-3 .c-indicatori-slide .o-bullet {
		width:33%;
		border:none;
		border-top: solid 6px transparent;
		border-bottom: solid 6px transparent;
		background: none !important;
		color: #fff;
	}
	
	.c-banner-layout-3 .carousel-indicators .active {
		width:33%;
		border-top: solid 6px #b2ce27;
		color: #b2ce27;
	}
	
	.c-banner-layout-3 .c-indicatori-slide .o-bullet:hover {
		opacity:1;
		background: none !important;
	}
	
	.c-banner-layout-3 .carousel-indicators .active:hover {
		color: #b2ce27;
	}
	
	.c-banner-layout-3 .c-indicatori-slide .o-bullet:nth-last-of-type(1) {
		border-right: none;
	}

}

/* Banner layout 3 */

@media (max-width: 800px) {

	.c-banner-layout-3 .carousel-indicators {
		bottom: 25px;
	}

}

/* Banner layout 4 */

.c-banner-layout-4 .carousel-control .glyphicon-chevron-left, .c-banner-layout-4 .carousel-control .glyphicon-chevron-right {
    padding: 23px 17px;
    transition: opacity .3s, transform .3s;
}

.c-banner-layout-4 .carousel-control .glyphicon-chevron-left:hover, .c-banner-layout-4 .carousel-control .glyphicon-chevron-right:hover {
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.c-banner-layout-4 .carousel-control .glyphicon-chevron-left:before, .c-banner-layout-4 .carousel-control .glyphicon-chevron-right:before {
    width: 12px;
    height: 12px;
}
.c-banner-layout-4 .c-indicatori-slide {
    background-color: rgba(255,255,255,.8) !important;
}

@media (min-width: 801px) {
	.c-banner-layout-4 .c-indicatori-slide .o-bullet {
		color: #000;
		padding: 13px 0;
		background: #fff
	}


	.c-banner-layout-4 .c-indicatori-slide .o-bullet {
		border: 0px solid #264d7a;
		font-size: 1.5rem;
		width: calc(25% - 9px);
		margin-right: 3px;
	    margin-left: 3px;
	}


.c-banner-layout-4 .c-banner-dropup {
    width: 21.5%;
    float: left;
}


	.c-banner-layout-4 .carousel-indicators {
	    width: 84.4%;
	    margin-left: 7.30%;
	}
}


.c-banner-layout-4 .c-indicatori-slide .o-bullet:nth-last-of-type(1)  {
	border-right: 0px solid #264d7a;
}
.c-banner-layout-4 .c-indicatori-slide .o-bullet:hover {
	background-color: #264d7a !important;
	color:#222;
}

@media (min-width: 801px) {
    .c-banner-layout-4 .c-indicatori-slide .o-bullet:hover {
        background-color: rgba(255,255,255,.4) !important;
        color:#222;
    }
	.c-banner-layout-4 .c-indicatori-slide .o-bullet.active,
	.c-banner-layout-4 .c-indicatori-slide .o-bullet.active {
		color: #264d7a;
		padding: 8px 0 18px 0;
		box-shadow: 2px 2px 3px rgba(0,0,0,.3);
	}


.c-banner-layout-4 .c-indicatori-slide .o-bullet.active:hover:before,
.c-banner-layout-4 .c-indicatori-slide .o-bullet.active:hover:before{
opacity:0.8;
}

.c-banner-layout-4 .c-indicatori-slide .o-bullet.active:before,
.c-banner-layout-4 .c-indicatori-slide .o-bullet.active:before {
  content: '';
  display: block;
  position: absolute;
  background-color: #fff;
  height: 5px;
  left: 0;
  right: 0;
  top: -5px;
  transition: opacity .3s;
}

.c-banner-layout-4 .c-indicatori-slide .o-bullet.active:after,
.c-banner-layout-4 .c-indicatori-slide .o-bullet.active:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #264d7a;
  height: 8px;
  left: 0;
  right: 0;
  bottom: 0;
}

}


.c-banner-layout-4 .c-banner-dropup .dropup .o-dropdown {
	border: 0px;
    padding: 7px 25px 6px 25px;
    font-weight: 300;
    transition: color .5s;
	-ms-transition: color .5s;
}
.c-banner-layout-4 .c-banner-dropup .dropup .o-dropdown:hover, .c-banner-layout-4 .c-banner-dropup .dropup .o-dropdown:hover:after {
	color: #b2ce27 !important;
}


.c-banner-layout-4 .c-banner-dropup .dropup .o-dropdown:after { 
	border: 0px;
	// line-height: 4.7rem;
	line-height: 1.3;
    transition: color .5s;
	-ms-transition: color .5s;
}

.c-banner-layout-4 .carousel-caption h2 {
    margin-bottom: 15px !important; 
}
.c-banner-layout-4 .carousel-caption h3 {
    margin-bottom: 20px;
}
.c-banner-layout-4 .carousel-caption p.infotesto {
    padding-top: 25px;
    margin-bottom: 50px;
    font-weight: 300;
}

.c-banner-layout-4 .c-banner-dropup {
    background: #264d7a;
}
.c-banner-layout-4 .dropup.open .dropdown-menu {
    background: #264d7a;
    padding-bottom: 0px
}
.c-banner-layout-4 .c-banner-dropup .dropdown-menu li a {
    color: #fff !important;
    transition: color .5s;
	-ms-transition: color .5s;
	padding-left: 20px;
	font-size: 1.6rem;
}
.c-banner-layout-4 .c-banner-dropup .dropdown-menu li a:hover {
	background: #264d7a;
	color: #b2ce27 !important;
}
.c-banner-layout-4 .c-banner-dropup .dropdown-menu li, .c-banner-layout-4 .c-banner-dropup .dropdown-menu li:last-child {
    border-bottom: 1px solid #fff;
}
.carousel.c-banner-layout-4 li {
    margin-right: 0px;
}

@media (max-width: 800px) {
	.c-banner-layout-4 .carousel-caption p.infotesto {
    	padding-top: 18px;
    	margin-bottom: 10px;
	}
	.c-banner-layout-4 .carousel-indicators {
		bottom: 85px;
	}
	.c-banner-layout-4 .carousel-caption h2 {
    	margin-top:30px;
    	margin-bottom: 5px !important;
    }
    .c-banner-layout-4 .carousel-caption .o-button {
    	padding: 8px 15px;
	}

}

