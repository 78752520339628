/* LAYOUT A */

div.c-main-content.home > div.col-sm-5 {
	box-shadow: 10px 10px 50px #e8e8e8;
	margin-bottom: 100px;
	margin-top: 30px;
	/*padding: 60px 60px 90px 60px;*/
	padding: 50px 70px;
}

div.c-main-content.home > div.col-sm-5:nth-child(1) {
	margin-left: 3.3%;
}

div.c-main-content.home > div.col-sm-5:nth-child(2) {
	margin-left: 10%;
}

div.c-main-content.home > div.col-sm-5 img {
	margin-top: 3px;
}

div.c-main-content.home > div.col-sm-5:nth-child(2) img {
	margin-top: 7px;
}

.p-padding-home {
    padding: 30px 0 45px 0;
}

.l-status-ribbon  {background: #e6eaed;font-size: 1.6rem;padding: 0;box-sizing: border-box;height: 80px; /*margin-bottom: 50px;*/ position: relative;z-index: 888;}
.l-status-ribbon .c-status{list-style:none;font-size: 2rem;padding: 18px 0 0 15px;margin: 0;}
/* .l-status-ribbon .c-status li{width:20%; display:inline-block; position:relative; text-align: center; } */
/* .l-status-ribbon .c-status li:first-child {text-align: left; } */

.l-status-ribbon .c-status li {
	display: inline-block;
	position: relative;
	text-align: center;
}
.l-status-ribbon .c-status li:nth-child(1) {
	width: 17.5%;
}
.l-status-ribbon .c-status li:nth-child(2) {
	width: 22%;
}
.l-status-ribbon .c-status li:nth-child(3) {
	width: 22.5%;
}
.l-status-ribbon .c-status li:nth-child(4) {
	width: 18%;
}
.l-status-ribbon .c-status li:nth-child(5) {
	width: 17.5%;
}
.l-status-ribbon .c-status li a,
.c-table-dati table a.o-remove-filters {text-decoration:none; color:#222; -webkit-transition: color .3s ease-in-out; -moz-transition: color .3s ease-in-out; -ms-transition: color .3s ease-in-out; -o-transition: color .3s ease-in-out; transition: color .3s ease-in-out;}

/* filtro cliccato */
.l-status-ribbon .c-status li a.is-clicked {
	color: #264D7A;
}
/* */

.l-status-ribbon .c-status li a.filter-no-hover-effect:hover {
	color: #8da6bc !important;
	cursor: default;
}
.l-status-ribbon .c-status li a.filter-no-hover-effect span.o-esclamation:hover {
	cursor: default;
}

.l-status-ribbon .c-status li span {
	min-width:65px;
	font-size: 2.5rem;
	box-sizing:border-box;
	background:#f5f5f5;
	display:inline-block;
	padding:5px 15px;
	border-radius: 20px;
	text-align:center;
	font-weight: 600;
	margin-left: 5px;
	position: relative;
	color: #264d7a;
    -webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -ms-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
}

.l-status-ribbon .c-status li a:hover ,
.l-status-ribbon .c-status li a:hover span {
    color: #b2ce27;
}

span.o-esclamation-home {
	background: #f5f5f5;
	border-radius: 20px;
	box-sizing:border-box;
	display: initial;
	font-size: 3rem;
	font-weight: 600;
	margin-left: 0;
	padding: 5px 15px;
	text-align: center;
	position: relative;
}

.l-status-ribbon .c-status .o-esclamation:before,
span.o-esclamation-home:before {
	content:'!';
	width: 22px;
	height: 22px;
	background:red;
	display:block;
	position: absolute;
	top: -8px;
	right: -8px;
	border-radius:50%;
	color:#fff;
	text-align:center;
	line-height:1.1;
	font-size: 2rem;
}

ul.ul-box-home {
    font-size: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.ul-box-home li {
	margin-bottom: 70px;
}

ul.ul-box-home li:last-child {
	margin-bottom: 0;
}

ul.ul-box-home li a {
    color: #264d7a !important;
    -moz-transition: color .3s ease-in-out;
    -ms-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
}

ul.ul-box-home li a:hover {
    color: #b2ce27 !important;
    text-decoration: none;
}

ul.ul-box-home li a span.col-sm-7 {
	margin-left: -5px;
    padding-left: 0;
}

.l-status-ribbon .c-icon-star ,
ul.ul-box-home .c-icon-star {
    display: inline-block;
    position: relative;
	width: 20px;
    height: 20px;
    background: url(../images/stella-blu.svg) no-repeat;
    -moz-transition: background-image .3s ease-in-out;
    -ms-transition: background-image .3s ease-in-out;
    -o-transition: background-image .3s ease-in-out;
    -webkit-transition: background-image .3s ease-in-out;
    transition: background-image .3s ease-in-out;
}

.l-status-ribbon li a:hover .c-icon-star ,
ul.ul-box-home li a:hover .c-icon-star {
    background: url(../images/stella-verde.svg) no-repeat;
}

.wrapper-notifiche + #notifiche .c-alert-check-icon {
	position:absolute;
    display: inline-block;
	width: 27px;
    height: 27px;
	left:15px;
    background: url(../images/alert-validati.svg) no-repeat;
}

.wrapper-notifiche + #notifiche .c-alert-close-icon {
	position:absolute;
    display: inline-block;
	width: 27px;
    height: 27px;
	left:15px;
    background: url(../images/alert-restituiti.svg) no-repeat;
    -ms-transform: translate(0, 14px);
    -webkit-transform: translate(0, 14px);
    transform: translate(0, 14px)
}

/* LAYOUT B */

.l-status-ribbon {
    background: #e6eaed;
    font-size: 1.6rem;
    padding: 0;
    box-sizing: border-box;
    height: 80px;
    position: relative;
    z-index: 888;
}

.l-status-ribbon .c-status li {
	font-family: 'Roboto', serif;
	font-weight:400;
	font-size:1.7rem;
	color: #264d7a
	
}

.l-status-ribbon .c-notifiche {
    padding-right: 0;
    height: 100%;
    background: #f5f5f5;
}

.l-status-ribbon .wrapper-notifiche {
    height: 100%;
    width: 100%;
}

.l-status-ribbon .wrapper-notifiche a {
    color: #264D7A;
    vertical-align: middle;
    font-size: 2rem;
    font-weight: 600;
    height: 100%;
    display: inline-block;
    line-height: 4.2;
    text-decoration: none;
    -webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -ms-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
	position:relative;
    padding-left: 15px;
}

.l-status-ribbon .wrapper-notifiche a:before {
	content: '';
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #264D7A;
    border-right: 2px solid #264D7A;
    -webkit-transition: all .3s;
    transition: all .3s;
    -ms-transform: rotate(45deg) translate(-5px, 0);
    -webkit-transform: rotate(45deg) translate(-5px, 0);
    transform: rotate(45deg) translate(-5px, 0)
}

.l-status-ribbon .wrapper-notifiche a:hover {
    color: #264d7a;
}

.l-status-ribbon .wrapper-notifiche a:hover:before {
    border-top: 2px solid #264d7a;
    border-right: 2px solid #264d7a;
}

.l-status-ribbon button {
    border: 0;
    outline: 0;
    height: 100%;
    background: transparent;
    position: absolute;
    right: 15px;
}

.l-status-ribbon a i {
    font-size: 2rem;
    /*vertical-align:-3px*/
    ;
    margin-right: 5px;
    margin-left: 7px;
    font-weight: 700;
}

.l-status-ribbon .o-notifiche {
    position: relative;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 80px
}

.l-status-ribbon .o-notifiche i {
    color: #222;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: -6px;
    right: 0;
    top: -8px;
    text-align: center;
}

.l-status-ribbon .o-notifiche:before {
    content: "";
    height: 6px;
    position: absolute;
    bottom: 1px;
    left: 50%;
    right: 50%;
    top: auto;
    background: #f5f5f5;
    transition: .3s
}

.l-status-ribbon .o-notifiche:hover:before,
.l-status-ribbon button:not(.collapsed) .o-notifiche:before {
    background: #264d7a;
    left: 0;
    right: 0
}

a#i-miei-filter i.icon-stars {
    font-size: 2.6rem;
    margin: -8px 10px 0 7px;
}

#i-miei-filter {
    background: #f5f5f5;
    border-radius: 20px;
    /* color: #264d7a; */
    display: inline-block;
    padding: 5px 15px 5px 6px;
}

#i-miei-filter:hover {
    color: #b2ce27;
}

#i-miei-filter span.o-esclamation {
    min-width: auto;
    padding: 0;
}

#i-miei-filter span.o-esclamation:before {
    right:-23px;
    top:-13px;
}

div.c-main-content.home a {
	border-bottom:none
}

div.c-main-content.home a h2 {
	color: #222;
	display: inline-block;
	font-weight: 500;
	font-family: 'Roboto slab', serif;
	margin-top: 27px;
	-moz-transition: color .3s ease-in-out;
	-ms-transition: color .3s ease-in-out;
	-o-transition: color .3s ease-in-out;
	-webkit-transition: color .3s ease-in-out;
	transition: color .3s ease-in-out;
}

/* specifico per IE 10+ */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .dettaglio-alert {
      overflow-x: hidden;
  }

  .c-navigation > ul > li > a.o-area-riservata {
    /*line-height: 0;*/
  }

  div.c-main-content.home a h2 {
    padding-top: 27px;
  }

}
/* fine IE 10+ */


#notifiche {
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    margin-left: -15px;
}

#notifiche ul {
    padding: 0;
    list-style: none;
    margin: 0
}

#notifiche ul li {
    padding: 15px 0 15px 59px;
    border-bottom: 1px solid #b5b5b5;
    color: #68696b;
    position: relative;
}

#notifiche ul li a {
    text-decoration: none;
    position: absolute;
    right: 20px;
    top: 50%;
    -ms-transform: translateY(-58%);
    transform: translateY(-58%);
    -wekit-transform: translateY(-58%);
}

#notifiche ul li a.c-icon-close-it{
	width: 15px;
    height: 15px;
}

#notifiche ul li a.c-icon-close-it:before {
	position:absolute;
    display: inline-block;
	width: 10px;
    height: 10px;
	left:0;
    content: url(../images/icona-close.svg);
}

#notifiche ul li .icon-close-it:before {
    color: #68696b;
    font-size: 1.5rem
}

.c-navigation>ul>li>a.is-open:after {
    width: 100%
}

#notifiche ul li .o-esclamation:before {
    font-size: 2rem;
    font-weight: 600;
    content: '!';
    width: 26px;
    height: 26px;
    background: red;
    position: absolute;
    top: 50%;
    left: 15px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 1.2;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -wekit-transform: translateY(-50%);
}

#notifiche ul li>i:before {
    font-size: 3.2rem;
    position: absolute;
    top: 50%;
    left: 15px;
    border-radius: 50%;
    color: #68696b;
    text-align: center;
    line-height: 1.2;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -wekit-transform: translateY(-50%);
}

#notifiche ul li:last-child>i:before {
    font-size: 3rem;
    position: absolute;
    top: 55%;
    left: 15px;
    border-radius: 50%;
    color: #68696b;
    text-align: center;
    line-height: 1.2;
    -ms-transform: translateY(-50%) rotate(-180deg);
    transform: translateY(-50%) rotate(-180deg);
    -wekit-transform: translateY(-50%) rotate(-180deg) scale(-100% -100%);
}

#notifiche .o-results {
    display: block;
    font-size: 1.325rem;
    font-weight: 500
}

#notifiche .o-results>span {
    padding: 0 2px;
    font-weight: 700;
    border-left: 1px solid
}

#notifiche .o-results>span:first-of-type {
    border-left: 0
}

#notifiche .o-results a {
    position: static;
    color: #222;
    padding: 0 2px
}

#notifiche .o-results a:hover {
    background: whitesmoke;
    transition: .3s;
}

.o-count {
    background: red;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
    font-weight: 900;
    height: 20px;
    letter-spacing: -.08em;
    line-height: 1.6;
    position: absolute;
    right: 20px;
    text-align: center;
    text-indent: -.09em;
    top: 15px;
    width: 20px;
}

ul.pagination {
    margin: 0;
}

.icon-notifica:before {
    content: url(../images/icon/notifiche_NO-ombra.svg);
    width: 80px;
    height: 80px;
    display: block;
}

.c-output-giornalieri .c-fascia-button {
	background: #E6EAED;
	font-size: 1.7rem;
	margin-bottom: 50px
}
.c-output-giornalieri .c-fascia-button a {
    text-decoration: none;
    color: #264D7A;
}
.c-output-giornalieri .c-fascia-button .o-fascia-button strong {
	font-size: 2.5rem;
	margin-left: 10px;
} 
.c-output-giornalieri .c-fascia-button .o-fascia-button.o-selected {
	background: #264D7A;
	color: #F5F5F5;
	cursor: default
}
.c-output-giornalieri .c-fascia-button .o-fascia-button.o-selected a{
	color: #F5F5F5
}
.c-output-giornalieri .c-fascia-button .o-fascia-button.o-spento {
	opacity: .5;
	cursor: default
} 
.c-output-giornalieri .c-fascia-button .o-fascia-button { 
	background: #F5F5F5;
    padding: 5px 15px;
    border-radius: 25px;
    margin: 15px auto;
    margin-left: 0px;
    color: #264D7A;
    cursor: pointer;
    min-width: 200px;
    text-align: center;
 }
 .c-output-giornalieri .c-fascia-button .container {
    max-width: 100%;
    overflow: hidden;
 }
 /* LAYOUT D */

.c-collateral-utilizzabile {
    margin: 0 0 50px 0;
    background: #E8F0BE;
    padding: 30px 0;
}

h1.o-icon-overview, h1.o-icon-scoperture, 
h1.o-icon-collateral, h1.o-icon-riconsegne {
    font-family: 'Roboto Slab';
    font-size: 3rem;
    margin-bottom: 50px;
}

 h1.o-icon-collateral:before {
	content: "";
	background-image: url("../images/icon-scopertura.svg");
	width: 70px;
    height: 70px;
    float: left;
    margin-right: 20px;
    background-size: contain;
}

.c-collateral-utilizzabile { margin: 0 -15px 0; background: #E8F0BE; padding: 30px 0; }
.c-collateral-utilizzabile div:first-child {     padding-left: 0; }
.c-collateral-utilizzabile p {
	font-size: 1.8rem;
    font-weight: 300;
    text-align: center
}

.c-collateral-utilizzabile span {
	display: inline-block;
    font-size: 3.5rem;
    font-weight: 400;
    margin-right: 16px
}

.c-collateral-utilizzabile .o-button {
    position: relative;
    bottom: 5px;
}