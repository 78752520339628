.c-message-alert {
    margin: 30px 0;
    background-color: #fafafa;
    color: #777777;
    position: relative;
}

.c-message-alert .o-message-alert-ico {
    /* min-height: 60px; */
    position: absolute;
    height: 100%;
}

.c-message-alert .o-message-alert-text {
    padding: 10px 0 10px 95px;
}

.c-message-alert .o-message-alert-text h4 {
    font-weight: 400;
    margin: 0;
    text-align: left;
}
.c-message-alert .o-message-alert-text p {
    margin: 0;
    text-align: left;
    color: #222;
    font-weight: 300;
    line-height: 2rem;
}

.c-message-alert p {
    margin: 10px 0 0 0;
}

.c-message-alert.is-ok h4 {
    color: #c0d751;
    font-weight: 400;
}

.c-message-alert.is-ok .o-message-alert-ico {
    background-color: #c0d751;
    background-image: url("../images/lamp_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.c-message-alert.is-ko {
    padding: 10px 0 10px 95px;
    text-align: left;
}

.c-message-alert.is-ko h4 {
    color: #222;
    font-weight: 400;
    font-family: "Roboto slab", serif;
}
.c-message-alert.is-ko p {
    margin-top: 0;
    margin-bottom: 20px !important;
}
.c-message-alert.is-ko:before {
    content: "";
    background-color: #ff0000;
    background-image: url(../images/attenzione-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
}

.c-message-alert.is-warning h4 {
    color: #e4d33d;
    font-weight: 400;
}

.c-message-alert.is-warning .o-message-alert-ico {
    background-color: #e4d33d;
    background-image: url("../images/attenzione-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.c-message-alert.is-info h4 {
    color: #92a7bc;
    font-weight: 400;
}

.c-message-alert.is-info .o-message-alert-ico {
    background-color: #92a7bc;
    background-image: url("../images/info-icon.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.o-icon-lamp-mini {
    padding: 20px 15px 20px 75px;
    position: relative;
    line-height: 2.8rem;
    color: #c0d751;
    background: #fafafa;
    margin: 20px 0;
}
div.o-icon-lamp-mini h5 {
    margin: 0;
    color: #222;
}
div.o-icon-lamp-mini p {
    color: #222;
}

.o-icon-lamp-mini:before {
    content: "";
    background-color: #b2ce27;
    background-image: url("../images/lamp_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.o-icon-info-mini {
    padding: 20px 15px 20px 75px;
    position: relative;
    line-height: 2.8rem;
    background: #fafafa;
    margin: 20px 0;
}
.o-icon-info-mini h5 {
    margin: 0;
    color: #222;
}
.o-icon-info-mini p {
    color: #222;
}

.o-icon-info-mini:before {
    content: "";
    background-color: #5493e2;
    background-image: url("../images/info_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.o-icon-alert-mini {
    padding: 20px 15px 20px 75px;
    position: relative;
    line-height: 2.8rem;
    background: #fafafa;
    margin: 20px 0;
}
div.o-icon-alert-mini h5 {
    margin: 0;
    text-align: left;
    color: #222;
}
div.o-icon-alert-mini h5 {
    color: #222;
}

div.o-icon-alert-mini.o-icon-alert-layout-2 h5 {
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: #222;
    font-weight: 400;
}

div.o-icon-alert-mini.o-icon-alert-layout-2 p {
    color: #222;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
}

.o-icon-alert-mini a {
    color: #dfc90d;
    font-weight: bold;
    text-decoration: underline;
}
.o-icon-alert-mini:before {
    content: "";
    background-color: #f2a359;
    background-image: url("../images/!_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.o-icon-error-mini {
    padding: 20px 15px 20px 75px;
    position: relative;
    line-height: 2.8rem;
    color: #af5f64;
    background: #fafafa;
    margin: 20px 0;
}
.o-icon-error-mini h5 {
    margin: 0;
    color: #222;
}
.o-icon-error-mini a {
    color: #af5f64;
    font-weight: bold;
    text-decoration: underline;
}
.o-icon-error-mini:before {
    content: "";
    background-color: #cf021b;
    background-image: url("../images/!_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.o-icon-lamp-outline {
    padding: 20px 15px 20px 60px;
    position: relative;
    border: 2px solid #b2ce27;
    line-height: 2.8rem;
    margin: 20px 0;
}

div.o-icon-lamp-outline h5 {
    margin: 0;
    color: #222;
}

div.o-icon-lamp-outline p {
    color: #222;
}

.o-icon-lamp-outline:before {
    content: "";
    background-image: url("../images/lamp_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.o-icon-info-outline {
    padding: 20px 15px 20px 60px;
    position: relative;
    line-height: 2.8rem;
    border: 2px solid #5493e2;
    margin: 20px 0;
}

.o-icon-info-outline h5 {
    margin: 0;
    color: #222;
}

.o-icon-info-outline p {
    color: #222;
}

.o-icon-info-outline:before {
    content: "";
    background-image: url("../images/info_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 60px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.o-icon-lamp-mega {
    padding: 10px 15px 10px 95px;
    position: relative;
    line-height: 2.8rem;
    color: #c0d751;
    background: #fafafa;
    margin: 20px 0;
}
.o-icon-lamp-mega h5 {
    font-size: 1.9rem;
    margin-top: 15px;
    color: #222;
}
.o-icon-lamp-mega p {
    color: #222;
}
.o-icon-lamp-mega:before {
    content: "";
    background-color: #b2ce27;
    background-image: url("../images/lamp_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.o-icon-lamp-mega .o-button {
    text-transform: uppercase;
    background: #b2ce27;
    color: #fff !important;
    padding: 10px 20px;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    width: 100%;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    border: 0px;
}
.o-icon-lamp-mega .o-button:hover {
    background: #204d7c;
    color: #fff !important;
    border: 0px;
}

.o-icon-info-mega {
    padding: 10px 0 10px 95px;
    position: relative;
    line-height: 2.8rem;
    color: #264d7a;
    background: #fafafa;
    margin: 20px 0;
}
.o-icon-info-mega a {
    color: #264d7a;
    font-weight: bold;
    text-decoration: underline;
}
.o-icon-info-mega p {
    color: #222;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
}
.o-icon-info-mega:before {
    content: "";
    background-color: #92a6bc;
    background-image: url("../images/info_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.o-icon-info-mega .o-button {
    text-transform: uppercase;
    background: #92a6bc;
    color: #fff !important;
    padding: 10px 20px;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    width: 100%;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    border: 0px;
}
.o-icon-info-mega .o-button:hover {
    background: #204d7c;
    color: #fff !important;
    border: 0px;
}

.o-icon-alert-mega {
    padding: 10px 0 10px 95px;
    position: relative;
    line-height: 2.8rem;
    color: #e4d33c;
    background: #fafafa;
    margin: 20px 0;
}
.o-icon-alert-mega a {
    color: #e4d33c;
    font-weight: bold;
    text-decoration: underline;
}
  
  div.o-icon-alert-mini.o-icon-alert-layout-2 h5 {
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: #222;
      font-weight: 400;
  }
  
  div.o-icon-alert-mini.o-icon-alert-layout-2 p {
      color: #222;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 300;
  }
  
  .o-icon-alert-mini a {
      color: #dfc90d;
      font-weight: bold;
      text-decoration: underline
  }
  .o-icon-alert-mini:before {
      content: '';
      background-color: #f2a359;
      background-image: url("../images/attenzione-icon.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 60px;
      height: 100%;
      position: absolute;
      left: 0;
      top:0
  }
  
  
  .o-icon-error-mini {
      padding: 20px 15px 20px 75px;
      position: relative;
      line-height: 2.8rem;
      color: #af5f64;
      background: #fafafa;
      margin: 20px 0
  }
  .o-icon-error-mini h5 {
    margin: 0;
      color: #222;
  }
  .o-icon-error-mini a {
      color: #af5f64;
      font-weight: bold;
      text-decoration: underline
  }
  .o-icon-error-mini:before {
      content: '';
      background-color: #cf021b;
      background-image: url("../images/attenzione-icon.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 60px;
      height: 100%;
      position: absolute;
      left: 0;
      top:0
  }
  
  
  .o-icon-lamp-mega {
      padding: 10px 15px 10px 95px;
      position: relative;
      line-height: 2.8rem;
      color: #c0d751;
      background: #fafafa;
      margin: 20px 0
  }
  .o-icon-lamp-mega h5 { 
    font-size: 1.9rem;
    margin-top: 15px;
    color: #222;
   }
  .o-icon-lamp-mega p {
      color: #222;
  }
  .o-icon-lamp-mega:before {
    background-color: #b2ce27;
    background-image: url(../images/lamp_icon_black.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 45px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px;
  }
  .o-icon-lamp-mega .o-button {
      text-transform: uppercase;
      background: #b2ce27;
      color: #fff !important; 
      padding: 10px 20px;
      text-decoration: none;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      display: inline-block;
      width: 100%;
      font-size: 2rem;
      font-weight: 400;
      text-align: center;
      border: 0px
      
  }
  .o-icon-lamp-mega .o-button:hover {
      background: #204d7c;
      color: #fff !important;
      border: 0px
  }
  
  .o-icon-info-mega {
      padding: 10px 0 10px 95px;
      position: relative;
      line-height: 2.8rem;
      color: #264d7a;
      background: #fafafa;
      margin: 20px 0
  }
  .o-icon-info-mega a {
      color: #264d7a;
      font-weight: bold;
      text-decoration: underline
  }
  .o-icon-info-mega p {
      color: #222;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 300;
  }
  .o-icon-info-mega:before {
      content: '';
      background-color: #92a6bc;
      background-image: url("../images/info-icon.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 80px;
      height: 100%;
      position: absolute;
      left: 0;
      top:0
  }
  .o-icon-info-mega .o-button {
      text-transform: uppercase;
      background: #92a6bc;
      color: #fff !important; 
      padding: 10px 20px;
      text-decoration: none;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      display: inline-block;
      width: 100%;
      font-size: 2rem;
      font-weight: 400;
      text-align: center;
      border: 0px
      
  }
  .o-icon-info-mega .o-button:hover {
      background: #204d7c;
      color: #fff !important;
      border: 0px
  }
  
  .o-icon-alert-mega {
      padding: 10px 0 10px 95px;
      position: relative;
      line-height: 2.8rem;
      color: #e4d33c;
      background: #fafafa;
      margin: 20px 0
  }
  .o-icon-alert-mega a {
      color: #e4d33c;
      font-weight: bold;
      text-decoration: underline
  }
  .o-icon-alert-mega p {
      color: #222;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 300;
  }
  .o-icon-alert-mega:before {
      content: '';
      background-color: #e4d33c;
      background-image: url("../images/attenzione-icon.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 80px;
      height: 100%;
      position: absolute;
      left: 0;
      top:0
  }
  .o-icon-alert-mega .o-button {
      text-transform: uppercase;
      background: #e4d33c;
      color: #fff !important; 
      padding: 10px 20px;
      text-decoration: none;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      display: inline-block;
      width: 100%;
      font-size: 2rem;
      font-weight: 400;
      text-align: center;
      border: 0px
      
  }
  .o-icon-alert-mega .o-button:hover {
      background: #204d7c;
      color: #fff !important;
      border: 0px
  }
  
  
  .o-icon-error-mega {
      padding: 10px 0 10px 95px;
      position: relative;
      line-height: 2.8rem;
      color: #af5f64;
      background: #fafafa;
      margin: 20px 0
  }
  .o-icon-error-mega a {
      color: #af5f64;
      font-weight: bold;
      text-decoration: underline
  }
  .o-icon-error-mega p {
      color: #222;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 300;
  }
  .o-icon-error-mega:before {
      content: '';
      background-color: #FF0000;
      background-image: url("../images/attenzione-icon.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 80px;
      height: 100%;
      position: absolute;
      left: 0;
      top:0
  }
  .o-icon-error-mega .o-button {
      text-transform: uppercase;
      background: #FF0000;
      color: #fff !important; 
      padding: 10px 20px;
      text-decoration: none;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -ms-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      display: inline-block;
      width: 100%;
      font-size: 2rem;
      font-weight: 400;
      text-align: center;
      border: 0px
      
  }
  .o-icon-error-mega .o-button:hover {
      background: #204d7c;
      color: #fff !important;
      border: 0px
  }
  
  
  
  @media (min-width: 768px) {  
      .o-icon-lamp-mega .c-primary-button, .o-icon-info-mega .c-primary-button, .o-icon-alert-mega .c-primary-button, .o-icon-error-mega .c-primary-button {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
      }
      .o-icon-lamp-mega .pabsolute, .o-icon-info-mega .pabsolute, .o-icon-alert-mega .pabsolute, .o-icon-error-mega .pabsolute { 
          position: absolute;
          top: 0;
          bottom: 0; 
      }
  
  }
  
  .c-alert {
      background: #cf021b;
      text-align: center;
  }
  .c-alert p {
      background: url("../images/alert.svg") no-repeat left center;
      color: #fff;
      display: inline-block;
      font-size: 1.8rem;
      font-weight: 700;
      margin: 0 !important;
      padding: 12px 0 12px 45px;
  }
  
  
  .o-limitestabilito .o-close, .o-limitemassimo .o-close, div.info-tooltip .o-close {
      opacity: 1;
      top: 10px;
      transform: translateY(0);
      background:  url("../images/icona-close2.svg") no-repeat center center;
      background-size: 300%;
      
      display: block;
      color: #222;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      position: absolute;
      right: 10px;
      bottom: 0;
      z-index: 999;
  }
  
  .o-limitestabilito {
      background: #fff;
      padding: 10px;
      padding-top: 15px;
      border-bottom: 5px solid #f2a359;
      border-radius: 10px;
      z-index: 850;
      box-shadow: 0 2px 3px #949494;
      position: relative;
      margin-bottom: 20px;
  }
  .o-limitestabilito p { 
    color: #222; 
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
}
.o-icon-alert-mega:before {
    content: "";
    background-color: #e4d33c;
    background-image: url("../images/!_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.o-icon-alert-mega .o-button {
    text-transform: uppercase;
    background: #e4d33c;
    color: #fff !important;
    padding: 10px 20px;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    width: 100%;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    border: 0px;
}
.o-icon-alert-mega .o-button:hover {
    background: #204d7c;
    color: #fff !important;
    border: 0px;
}

.o-icon-error-mega {
    padding: 10px 0 10px 95px;
    position: relative;
    line-height: 2.8rem;
    color: #af5f64;
    background: #fafafa;
    margin: 20px 0;
}
.o-icon-error-mega a {
    color: #af5f64;
    font-weight: bold;
    text-decoration: underline;
}
.o-icon-error-mega p {
    color: #222;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
}
.o-icon-error-mega:before {
    content: "";
    background-color: #ff0000;
    background-image: url("../images/!_icon_black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.o-icon-error-mega .o-button {
    text-transform: uppercase;
    background: #ff0000;
    color: #fff !important;
    padding: 10px 20px;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    width: 100%;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    border: 0px;
}
.o-icon-error-mega .o-button:hover {
    background: #204d7c;
    color: #fff !important;
    border: 0px;
}

@media (min-width: 768px) {
    .o-icon-lamp-mega .c-primary-button,
    .o-icon-info-mega .c-primary-button,
    .o-icon-alert-mega .c-primary-button,
    .o-icon-error-mega .c-primary-button {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
    }
    .o-icon-lamp-mega .pabsolute,
    .o-icon-info-mega .pabsolute,
    .o-icon-alert-mega .pabsolute,
    .o-icon-error-mega .pabsolute {
        position: absolute;
        top: 0;
        bottom: 0;
    }
}

.c-alert {
    background: #cf021b;
    text-align: center;
}
.c-alert p {
    background: url("../images/alert.svg") no-repeat left center;
    color: #fff;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 400;
    margin: 0 !important;
    padding: 12px 0 12px 45px;
}

.o-limitestabilito .o-close,
.o-limitemassimo .o-close,
div.info-tooltip .o-close {
    opacity: 1;
    top: 10px;
    transform: translateY(0);
    background: url("../images/icona-close2.svg") no-repeat center center;
    background-size: 300%;

    display: block;
    color: #222;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    right: 10px;
    bottom: 0;
    z-index: 999;
}

.o-limitestabilito {
    background: #fff;
    padding: 10px;
    padding-top: 15px;
    border-bottom: 5px solid #f2a359;
    border-radius: 10px;
    z-index: 850;
    box-shadow: 0 2px 3px #949494;
    position: relative;
    margin-bottom: 20px;
}
.o-limitestabilito p {
    color: #222;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    margin-bottom: 10px !important;
    padding-left: 30px;
}
.o-limitestabilito p:first-child {
    background: url("../images/alert-giallo.svg") no-repeat left center;
    text-transform: uppercase;
    background-size: contain;
    font-weight: 500;
}

.o-limitemassimo {
    background: #fff;
    padding: 10px;
    padding-top: 15px;
    border-bottom: 5px solid #cf021b;
    border-radius: 10px;
    z-index: 850;
    box-shadow: 0 2px 3px #949494;
    position: relative;
}
.o-limitemassimo p {
    color: #222;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    margin-bottom: 10px !important;
    padding-left: 30px;
}
.o-limitemassimo p:first-child {
    background: url("../images/stop-rosso.svg") no-repeat left center;
    text-transform: uppercase;
    background-size: contain;
    font-weight: 500;
}

.o-limitestabilito p:nth-child(2),
.o-limitemassimo p:nth-child(2) {
    color: #222;
}

.c-generic-tooltip {
    display: inline-block;
    position: relative;
    margin-right: 20px;
}

.c-generic-tooltip:nth-child(1) .o-tooltip.o-tooltip-top {
    opacity: 1;
    display: inline-block;
}

.c-generic-tooltip .icon-riconsegne:before {
    content: url("../images/icon-riconsegne2.svg") !important;
}
.c-generic-tooltip .icon-scopertura:before {
    content: url("../images/icon-scopertura2.svg") !important;
}
.c-generic-tooltip .icon-home:before {
    content: url("../images/icon-home.svg");
}
.c-generic-tooltip .icon-search:before {
    content: url("../images/icon-cerca.svg");
}
.c-generic-tooltip .icon-doc:before {
    content: url("../images/icon-doc.svg");
}
.c-generic-tooltip .icon-brand-identity:before {
    content: url("../images/progettare_NO-ombra.svg");
}
.c-generic-tooltip .icon-esci:before {
    content: url("../images/esci_NO-ombra.svg");
}

.c-generic-tooltip a.o-generic i {
    margin: 0;
    padding: 0;
}
.c-generic-tooltip a.o-generic {
    border: 0px;
    display: inline-block;
    height: auto;
    position: relative;
    width: 34px;
}
.c-generic-tooltip .o-tooltip.o-tooltip-top {
    top: -45px;
}
.c-generic-tooltip a.o-generic:hover ~ .o-tooltip.o-tooltip-top, .c-generic-tooltip a.o-generic:focus ~ .o-tooltip.o-tooltip-top {
    opacity: 1!important;
    display: block!important;
  }
.c-info-tooltip {
    position: relative;
}

.icona-info {
    background: no-repeat top left;
    background-image: url("../images/icona-info-blu.svg");
    border: 0px !important;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0;
    padding: 0;
    -webkit-transition: background-image 0.3s ease-in-out !important;
    -o-transition: background-image 0.3s ease-in-out !important;
    transition: background-image 0.3s ease-in-out !important;
    width: 20px;
}
.icona-info:hover,
.icona-info:focus,
.icona-info:active {
    background-image: url("../images/icona-info-verde.svg");
    border: 0px !important;
    outline: none;
}

div.info-tooltip {
    background-color: #fff;
    display: none;
    font-size: 1.3rem;
    left: auto;
    right: 41px;
    padding: 10px 20px;
    position: absolute;
    text-align: left;
    top: calc(50% - 2px);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    width: 500px;
    z-index: 801;
    line-height: 2.5rem;
    -webkit-box-shadow: 0 2px 3px #949494;
    box-shadow: 0 2px 3px #949494;
    border-right: 0px;
    border-radius: 10px;
}
.c-generic-tooltip.c-big-tooltip {
	margin-right: 0;
	padding-left: 0 !important
}
.c-generic-tooltip.c-big-tooltip:first-child .o-tooltip.o-tooltip-top {
    opacity: 0;
    display: none;
	width: 270px;
    height: auto;
    white-space: initial;
    bottom: 35px;
    top: auto;
    transform: translate(-50%,0);
    left: 15px;
}

@media (max-width: 1400px) {
    .c-into-tooltip-w370 div.info-tooltip {
        left: -380px;
        width: 370px;
    }
}

@media (max-width: 769px) {
    .c-info-tooltip-nomobile {
        display: none;
    }
}
div.info-tooltip:before {
    background-color: #b2ce27;
    border: none;
    box-shadow: 0 2px 3px #949494;
    content: "";
    height: 24px;
    position: absolute;
    right: -5px;
    top: calc(50% - 16px);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 24px;
}

div.info-tooltip:after {
    border: none;
    border-radius: 10px;
    border-right: 8px solid #b2ce27;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: none;
    width: 30px;
}

/* IE11 hack per centrare correttamente freccia di div.info-tooltip con l'icona info */
// @media all and (-ms-high-contrast:none) {

//   *::-ms-backdrop,
//   div.info-tooltip:after {
//     top: 49.5%;
//   }

// }
/* fine IE11 hack */

.info-tooltip-open {
    display: block !important;
}
div.info-tooltip table:before {
    background-color: #fff;
    border: none;
    border-radius: 11px;
    box-shadow: none;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: none;
    transform: none;
    width: 25px;
}
div.info-tooltip table td {
    background-color: #fff !important;
    border: none;
    padding: 5px;
    vertical-align: top;
}
div.info-tooltip table td:first-child,
div.info-tooltip table td:nth-child(2n + 3) {
    color: #264d7a;
}
div.info-tooltip table td:first-child {
    width: 20%;
    font-size: 1.4rem;
    font-weight: 400;
}
div.info-tooltip table td:nth-child(2) {
    width: 80%;
    font-size: 1.4rem;
    font-weight: 400;
}
div.info-tooltip table tr:last-child td:nth-child(2),
div.info-tooltip table tr:last-child td:nth-child(5),
div.info-tooltip table tr:last-child td:nth-child(6) {
    width: 16% !important;
}
div.info-tooltip table tr:last-child td:nth-child(3) {
    width: 10% !important;
}
div.info-tooltip table tr:last-child td:nth-child(4) {
    width: 22%;
}
div.info-tooltip .o-close {
    display: none;
}

@media (max-width: 480px) {
    div.info-tooltip {
        left: 0;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    div.info-tooltip:before,
    div.info-tooltip:after {
        content: none;
    }
    .info-tooltip table,
    .info-tooltip tbody,
    .info-tooltip tr,
    .info-tooltip td {
        display: block;
    }
    div.info-tooltip table td:nth-child(2),
    div.info-tooltip table td:first-child,
    div.info-tooltip table tr:last-child td:nth-child(2),
    div.info-tooltip table tr:last-child td:nth-child(3),
    div.info-tooltip table tr:last-child td:nth-child(4),
    div.info-tooltip table tr:last-child td:nth-child(5),
    div.info-tooltip table tr:last-child td:nth-child(6) {
        width: 100% !important;
        padding: 0;
    }
    div.info-tooltip .o-close {
        display: block;
    }
}
    div.info-tooltip:before {
    background-color: #b2ce27;
    border: none;
    box-shadow: 0 2px 3px #949494;
    content: '';
    height: 24px;
    position: absolute;
    right: -5px;
    top: calc(50% - 16px);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 24px;
  }

  div.info-tooltip:after { 
    border: none;
    border-radius: 10px;
    border-right: 8px solid #b2ce27;
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: none;
    width: 30px;
  }
  
  /* IE11 hack per centrare correttamente freccia di div.info-tooltip con l'icona info */
  // @media all and (-ms-high-contrast:none) {
  
  //   *::-ms-backdrop,
  //   div.info-tooltip:after {
  //     top: 49.5%;
  //   }
  
  // }
  /* fine IE11 hack */

  .info-tooltip-open {
    display: block !important;
  }
  div.info-tooltip table:before {
    background-color: #fff;
    border: none;
    border-radius: 11px;
    box-shadow: none;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: none;
    transform: none;
    width: 25px;
  }
  div.info-tooltip table td {
    background-color: #fff !important;
    border: none;
    padding: 5px;
    vertical-align: top;
  }
  div.info-tooltip table td:first-child,
  div.info-tooltip table td:nth-child(2n+3) {
    color: #264d7a;
  }
  div.info-tooltip table td:first-child {
    width: 20%;
    font-size: 1.4rem;
    font-weight: 400;
  }
  div.info-tooltip table td:nth-child(2) {
    width: 80%;
    font-size: 1.4rem;
    font-weight: 400;
  }
  div.info-tooltip table tr:last-child td:nth-child(2),
  div.info-tooltip table tr:last-child td:nth-child(5),
  div.info-tooltip table tr:last-child td:nth-child(6) {
    width: 16% !important;
  }
  div.info-tooltip table tr:last-child td:nth-child(3) {
    width: 10% !important;
  }
  div.info-tooltip table tr:last-child td:nth-child(4) {
    width: 22%;
  }
  div.info-tooltip .o-close { display: none }
  
  @media (max-width: 480px) {
      div.info-tooltip {
          left: 0;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
      }
      div.info-tooltip:before, div.info-tooltip:after { content: none }
      .info-tooltip table, .info-tooltip tbody, .info-tooltip tr, .info-tooltip td { display: block; }
      div.info-tooltip table td:nth-child(2), 
      div.info-tooltip table td:first-child, 
      div.info-tooltip table tr:last-child td:nth-child(2), 
      div.info-tooltip table tr:last-child td:nth-child(3),
      div.info-tooltip table tr:last-child td:nth-child(4),
      div.info-tooltip table tr:last-child td:nth-child(5), 
      div.info-tooltip table tr:last-child td:nth-child(6)
      { width: 100% !important; padding: 0 }
      div.info-tooltip .o-close { display: block }
  }
  