// Arial Regular
@font-face {
   font-family: 'Arial';
   src: url('../fonts/arial/arial.eot'); /* IE9*/
   src: url('../fonts/arial/arial.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
   url('../fonts/arial/arial.woff2') format('woff2'), /* chrome、firefox */
   url('../fonts/arial/arial.woff') format('woff'), /* chrome、firefox */
   url('../fonts/arial/arial.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
   url('../fonts/arial/arial.svg#Arial') format('svg'); /* iOS 4.1- */
}

// Arial Italic
@font-face {
   font-family: 'Arial Italic';
   src: url('../fonts/arial-italic/arial-italic.eot'); /* IE9*/
   src: url('../fonts/arial-italic/arial-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
   url('../fonts/arial-italic/arial-italic.woff2') format('woff2'), /* chrome、firefox */
   url('../fonts/arial-italic/arial-italic.woff') format('woff'), /* chrome、firefox */
   url('../fonts/arial-italic/arial-italic.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
   url('../fonts/arial-italic/arial-italic.svg#Arial Italic') format('svg'); /* iOS 4.1- */
}

// Arial Bold
@font-face {
   font-family: 'Arial Bold';
   src: url('../fonts/arial-bold/arial-bold.eot'); /* IE9*/
   src: url('../fonts/arial-bold/arial-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
   url('../fonts/arial-bold/arial-bold.woff2') format('woff2'), /* chrome、firefox */
   url('../fonts/arial-bold/arial-bold.woff') format('woff'), /* chrome、firefox */
   url('../fonts/arial-bold/arial-bold.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
   url('../fonts/arial-bold/arial-bold.svg#Arial Bold') format('svg'); /* iOS 4.1- */
}

// Arial Bold Italic
@font-face {
   font-family: 'Arial Bold Italic';
   src: url('../fonts/arial-bold-italic/arial-bold-italic.eot'); /* IE9*/
   src: url('../fonts/arial-bold-italic/arial-bold-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
   url('../fonts/arial-bold-italic/arial-bold-italic.woff2') format('woff2'), /* chrome、firefox */
   url('../fonts/arial-bold-italic/arial-bold-italic.woff') format('woff'), /* chrome、firefox */
   url('../fonts/arial-bold-italic/arial-bold-italic.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
   url('../fonts/arial-bold-italic/arial-bold-italic.svg#Arial Bold Italic') format('svg'); /* iOS 4.1- */
}

