body.is-scrolling:not(.no-scroll){
    padding-top: 150px;
}
@media (max-width: 767px) {
	body.is-scrolling:not(.no-scroll) {
		padding-top: 78px;
	}
}
	
body.no-scrolling {
    overflow: hidden;
}
	
.l-container-fluid {
    width: 100%;
    padding: 0 20px;
    zoom: 1;
}

.l-header:after,
.c-note-head:after,
.c-social-icons:after,
.l-container:after,
.c-navigation>ul:after,
.l-container-fluid:after {
    content: '';
    display: block;
    clear: both;
}

/* Top header
   ---- */

.l-top-header {
    position: relative;
	width:100%;
}

.c-note-head {
    background-color: $dark-blue-digital;
    height: 50px;
    padding: 8px 0;
}

body.is-scrolling:not(.no-scroll) .c-note-head {
	display:none;
}

/* Brands logos
   ---- */
   
.c-iccrea-logo {
	float: left;
	height: auto;
	margin: 7px 0 0 -5px;

	a {
		display: inline-block;
		line-height: 1;
		outline: 0;
		padding: 3px 5px;
	}
	a:focus {
		border-radius: 6px;
		box-shadow: 0 0 0 3px #fff;
	}
	a:active {
		box-shadow: none;
	}
	
	// img {
	// 	height: 14px;
	// 	width: 215.19px;
	// }
}

.c-bcc-logo {
	float:right;
	max-width: 98px;
	width: 100%;
	text-align: right;

	img {
		width: 100%;
		max-width: 98px;
		height: auto;
	}
}


/* Header
   ---- */

.l-header {
    position: relative;
    border-bottom: 1px solid $off-white;
	height:100px;
	line-height:100px;
}

/* Fixed Header
   ---- */

.l-header.is-scrolling:not(.no-scroll) {
	background-color: white;
	height: 86px; 
	line-height: 86px;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 899;
}

.l-header.is-scrolling:not(.no-scroll)  #c-src-reveal ,
.l-header.is-scrolling:not(.no-scroll)  .c-menu-button,
.l-header.is-scrolling:not(.no-scroll)  .intranet-menu-button {
	height:86px; 
}

.l-header.is-scrolling:not(.no-scroll) .c-menu-reveal {
	@include transform( translate(0, 0) );
}

.l-header.is-scrolling:not(.no-scroll) .c-menu-button span,
.l-header.is-scrolling:not(.no-scroll) .intranet-menu-button span {
    bottom: 13px;
}

.l-header.is-scrolling:not(.no-scroll) .o-menu-icon {
	@include transform( translate(0, -18px) );
}

.l-header.is-scrolling:not(.no-scroll) .c-navigation>ul>li,
.l-header.is-scrolling:not(.no-scroll) .c-navigation.is-desktop>ul>li {
	height: 86px;
    line-height: 86px;
}

.l-header.is-scrolling:not(.no-scroll) .c-navigation>ul>li>a.o-area-riservata {
	@include transform( translate(0, 23px) );
}

.l-header.is-scrolling:not(.no-scroll) .c-navigation>ul>li>a.o-notice i ,
.l-header.is-scrolling:not(.no-scroll) .c-navigation>ul>li>a.o-email i {
	margin-top:0;
}

.l-header.is-scrolling:not(.no-scroll) #o-src-reveal {
	bottom:27px;
}

/* Main logo
   ---- */

.c-main-logo {
    float: left;
	padding:0;
	
	a {
		display: inline-block;
		height: 61px;
		margin: 0 0 0 -3px;
		outline: 0;
		padding: 3px;
		vertical-align: middle;
		width: 368px;
	}
	a:focus {
		border-radius: 6px;
		box-shadow: 0 0 0 3px #264d7a;
	}
	a:active {
		box-shadow: none;
	}
	img {
		height: 55px;
		vertical-align: top;
		width: auto;
	}
	.c-logo-mobile { 
		display: none;
	}
}

.c-login  .c-logo-mobile { 
	display: none;
}

.l-header.is-scrolling:not(.no-scroll) .c-main-logo img {
	@include transform( translate(0, 5px) );
}

/* Hamburger menu
   ---- */

.c-menu-button,
.intranet-menu-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	float: left;
	height: 100px;
	padding-left: 20px;
	position: relative;
	width: 70px;

	&:before{
		content: '';
		width: 0;
		height: 6px;
		background: $dark-blue-digital;
		position: absolute;
		bottom: 0;
		left: 50%;

		@include transform( translate(-50%, 0) );
		@include transition( width .3s );
	}

	&:active,
	&:focus,
	&:hover {
		outline: none;
		
		&:before{
			width:80%;
		}
	}

	&.is-open{
		&:before{
			display: none;
		}
	}

	span {
		position: absolute;
		bottom: 25px;
		left: 19px;
		font-size: 1.2rem;
		color: $dark-grey;
		height: auto;
		line-height: 12px;
		text-transform:uppercase;
	}
}


.c-menu-reveal {
    position: absolute;
	display:block;
	width: 32px;
	height: 32px;
	text-align: center;
	text-transform: uppercase;
	color: $dark-grey;
	background-color: transparent;
	border:0;
	cursor:pointer;
    top: 50%;
	z-index:999;

	@include transform( translate(0, -12px) );
	@include transition( opacity 0.3s );
}

.o-menu-icon {

	&:before{
		content: '';
		position: absolute;
		width: 32px;
		height: 4px;
		background-color: $dark-grey;
		top: -9px;
		left:0;
		z-index:1000;

		@include transition( all 0.5s );
	}

    position: absolute;
    content: '';
    width: 32px;
    height: 4px;
    top: 50%;
	left:0;
    background-color: $dark-grey;
	z-index:1000;
	@include transform( translate(0, -11px) );

	&:after{
		content: '';
		position: absolute;
		width: 32px;
		height: 4px;
		background-color: $dark-grey;
		top: 9px;
		left:0;
		z-index:1000;

		@include transition( all 0.5s );
	}
}


.c-menu-button.is-open .c-menu-reveal .o-menu-icon:before,
.intranet-menu-button.is-open .c-menu-reveal .o-menu-icon:before {
	@include transform( rotateZ(-45deg) scaleX(0.75) translate(-10.16px, -5px) );
}

.c-menu-button.is-open .c-menu-reveal .o-menu-icon:after,
.intranet-menu-button.is-open .c-menu-reveal .o-menu-icon:after {
	@include transform( rotateZ(45deg) scaleX(0.75) translate(-10.16px, 5px) );
}

/* Hamburger menu > nav
   ---- */

.c-scroll-container {
    position: fixed;
	display:none;
	width:100%;
    top: 191px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    z-index: 899;
	overflow-x: hidden;
	-ms-overflow-x: hidden; 
	overflow-y: auto;
	-ms-overflow-y: auto; 
    background-color: transparent;
	-webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -o-overflow-scrolling: touch;
    overflow-scrolling: touch;
	box-sizing:content-box;
	background-image: url('../images/main-nav-bg.png');
	background-repeat:repeat;
	cursor: pointer;
	
	&.is-open{
		.c-scroll-content {
			display:block;
			left:0;
		}
	}

	@media(min-width: 768px) {
		&:not(.menunavigabile) #hidden-close-menu-bottom {
			display: none;
		}
	}
}

@media (max-width: 767px) {
	.c-scroll-container {
		&.no-breadcrumb {
			top: 78px !important;
		}
	}
}

.c-scroll-content {
	background-color:#fff;
	width:83.33%;
	left:-83.33%;
	padding:50px;
    position: relative;
    z-index: 900;
	cursor: default;

	@include transition( left 0.7s ease-out );

	ul {
		list-style:none;

		&.c-scroll-nav {
			list-style:none;
			padding-left:0;
			margin-bottom:50px;

			> li{
				margin-bottom:5px;

				> a{
					color: $dark-grey;
					display: inline-block;
					font-size:1.8rem;
					font-weight:300;
					outline: 0;
					position: relative;
					text-decoration:none;

					@include transition( transform 0.3s ease-out );

					&:hover{
						color: $green-digital;
						background-color: transparent;

						@include transform( translate(5px) )
					}
				}
			}
		}
	}

	img {
		max-width:100%;
	}

	.o-title {
		font-family: $roboto;
		color: $dark-blue-digital;
		cursor: auto;
		display: block;
		font-size:2.4rem;
		font-weight:400;
		margin-top:0px;
		margin-bottom:20px;
		outline: 0;
		text-decoration: none;
		
			> svg {
				display: none
			}

			&:focus {
				border-radius: 6px;
				box-shadow: 0 0 0 3px #264d7a;
				outline: 0;
			}
	}
}

/* Hamburger menu > close
   ---- */

.o-scroll-close {

	&:before{
		content: '';
		position:absolute;
		width: 4px;
		top: 50%;
		height: 45px;
		left: 50%;
		background-color: #ffffff;
		border: 0;

		@include transform( translate(-50%, -50%) rotate(45deg) );
	}

	background: transparent;
    border: none;
	content: '';
    display: none;
    font-size: 0;
	height: 33px;
    left: 0;
	padding:0;
	position: absolute;
	top: 50px !important;
    width: 33px;
	z-index: 900;
	
	span {
		position: absolute;
		content: '';
		top: 0;
		font-size:0;
		background-color: $dark-grey;

		@include transition( all 0.5s );
	}

	&:after{
		content: '';
		position:absolute;
		width: 4px;
		height: 45px;
		top: 50%;
		left: 50%;
		background-color: #ffffff;

		@include transform( translate(-50%, -50%) rotate(-45deg) );
	}

	&:focus {
		border: 3px solid #264d7a;
		border-radius: 6px;
		box-shadow: 0px 0px 0px 3px #fff;
		outline: 0;
	}
	&:active {
		border: 0;
		box-shadow: none;
		outline: 0;
	}

}

.c-scroll-container.is-open .o-scroll-close {
	left:calc(83.33% + 15px);
}


/* Right menu
   ---- */

.c-navigation {
	float:right;
	padding-right:82px;
}

.c-navigation>ul {
    margin: 0;
    padding: 0;
    color: $dark-grey;
}

.c-navigation>ul>li {
	float:left;
    display: block;
	position:relative;
    margin: 0 10px;
	padding: 0;
    height: 100px;
    line-height: 100px;
}

.c-navigation.is-desktop {
	display:block;
}

.c-navigation.is-desktop > ul > li {
	width:auto;
	height: 100px;
    line-height: 100px;
	background-color:transparent;
}

.c-navigation>ul>li>a {
	color: $dark-grey;
	display:block;
    font-size: 1.5rem;
	outline: 0;
	position:relative;
    text-decoration: none;
    text-transform: uppercase;
}

.c-navigation>ul>li>a:link {
    padding: 0 22px;
}

.c-navigation>ul>li>a:focus,
.c-navigation>ul>li>a:hover, 
.c-navigation>ul>li>a:hover>i:before {
    color: $dark-grey;
    -webkit-transition: border .3s, color .3s;
    -moz-transition: border .3s, color .3s;
    -ms-transition: border .3s, color .3s;
    -o-transition: border .3s, color .3s;
    transition: border .3s, color .3s;
}

.c-navigation>ul>li.o-active>a:hover {
    color: $dark-grey;
}

#c-src-reveal:after,
.c-navigation>ul>li>a:after {
    content: '';
    width: 0;
    height: 6px;
    background: #21416b;
    position: absolute;
	bottom: 0;
    left: 50%;
	-ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	-webkit-transition: width .3s;
    -moz-transition: width .3s;
    -ms-transition: width .3s;
    -o-transition: width .3s;
    transition: width .3s;
}

.c-navigation>ul>li.c-notice>a:after ,
.c-navigation>ul>li.c-email>a:after ,
.l-header.is-scrolling:not(.no-scroll) .c-navigation>ul>li.c-notice>a:after ,
.l-header.is-scrolling:not(.no-scroll) .c-navigation>ul>li.c-email>a:after {
	bottom: 0;
}

#c-src-reveal:focus:after,
#c-src-reveal:hover:after,
.c-navigation>ul>li.o-active>a:after,
.c-navigation>ul>li.c-has-dropdown.is-open>a:after,
.c-navigation>ul>li>a:focus:after,
.c-navigation>ul>li>a:hover:after,
.c-navigation>ul>li>a.is-open:after {
	width: 100%;
}

.c-navigation>ul>li>a.o-area-riservata {
    color: #fff;
    background: $green-digital;
    text-transform: uppercase;
    border-radius: 0;
    outline: 0;
    text-decoration: none;
    height: 40px;
	line-height:40px;
	margin-left:10px;
	padding:0 12px;
    -webkit-transition: background-color 0.3s ;
    -moz-transition: background-color 0.3s ;
    -ms-transition: background-color 0.3s ;
    -o-transition: background-color 0.3s ;
    transition: background-color 0.3s ;
	-webkit-transform: translate(0, 30px);
	-moz-transform: translate(0, 30px);
    -ms-transform: translate(0, 30px);
	-o-transform: translate(0, 30px);
    transform: translate(0, 30px);
	font-weight:900;
    font-size: 1.4rem;
}

.c-navigation>ul>li>a.o-area-riservata:hover, 
.c-navigation>ul>li>a.o-area-riservata:active,  
.c-navigation>ul>li>a.o-area-riservata:focus {
    background: $dark-blue-digital;
}

.c-navigation>ul>li>a.o-area-riservata:hover:after {
	display:none;
}

.c-navigation>ul>li>a.o-email {
	height:100%;
	width: 80px;
}

.c-navigation>ul>li>a.o-notice {
	height:100%;
    margin: 0;
}

.c-navigation>ul>li>a.o-generic {
	height:100%;
	padding: 0;
}

.l-header [class^="o-icon-"], .l-header [class*=" o-icon-"] {
	display: block;
    position: relative;
    top: 50%; 
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}
.l-header [class^="o-icon-"]:before, .l-header [class*=" o-icon-"]:before {	
	width: 28px;
    height: 40px;
    display: block;
    margin: 0 20px;
}
.l-header .o-icon-notice:before {
    content: "";
    background: url("../images/notifiche.svg") no-repeat center center;
    background-size: 100% 100%;
}
.l-header .o-icon-email:before {
    content: "";
    background: url("../images/mail.svg") no-repeat center center;
    background-size: 100% 100%;
    width: 35px;
}
.l-header .o-icon-search:before {
    content: "";
    background: url("../images/icon-search-desktop.svg") no-repeat center center;
    background-size: 100% 100%;
}
.l-header .o-icon-mobile-menu:before {
    content: "";
    background: url("../images/mobile-menu.svg") no-repeat center center;
    background-size: 100% 100%;
	height: 30px;
    margin: 5px 0 0 25px;
}
.l-header .o-icon-area-riservata:before {
    content: "";
    background: url("../images/area-riservata.svg") no-repeat center center;
    background-size: 100% 100%;
    width: 40px;
    height: 40px;
}

.l-header .o-icon-logout:before {
    content: "";
    background: url("../images/esci_373737.svg") no-repeat center center;
    background-size: 100% 100%;
    width: 35px;
    height: 35px;
}

.c-navigation>ul>li>a.o-email i ,
.c-navigation>ul>li>a.o-notice i {
	margin: 0;
    display: block;
    padding: 0;
	position:absolute;
    left: 50%;
    bottom: 50%;
}
.c-navigation>ul>li>a.o-email i {
	font-size: 3.7rem;
    -ms-transform: translate(-50%, 18px);
    -webkit-transform: translate(-50%, 18px);
    transform: translate(-50%, 18px)
}

.c-navigation>ul>li>a.o-notice i {
	font-size: 3.2rem;
    -ms-transform: translate(-50%, 16px);
    -webkit-transform: translate(-50%, 16px);
    transform: translate(-50%, 16px)
}

/* Right menu > tooltip
   ---- */

.o-tooltip {
    position: absolute;
    height: 20px;
    line-height: 20px;
    font-size: 1.2rem;
	font-weight:400;
    padding: 0 10px;
	text-align:center;
	background:#fff;
    color: $dark-grey;
	white-space: nowrap;
	-ms-transform: translate(-50%);
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    -moz-box-shadow: 0 0 3px #949494;
    -webkit-box-shadow: 0 0 3px #949494;
    box-shadow: 0 0 3px #949494;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
	-webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition:opacity 0.3s ease;
	z-index:880
}

.o-tooltip:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    z-index: 901;
}

a:focus ~ .o-tooltip,
a:hover ~ .o-tooltip,
div:hover ~ .o-tooltip {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	-moz-opacity: 1.0;
	-khtml-opacity: 1.0;
	opacity: 1.0;
}

.o-tooltip.o-tooltip-top {
    top: 0;
    border-bottom: solid 2px $green-digital;
    left: 50%;
    -ms-transform: translate(-50%, 15px);
    -webkit-transform: translate(-50%, 15px);
    transform: translate(-50%, 15px)
}

.o-tooltip.o-tooltip-top.o-tooltip-celeste {
    border-color: #92a6bc
}


body.is-scrolling:not(.no-scroll).o-tooltip.o-tooltip-top {
    -ms-transform: translate(-50%, 5px);
    -webkit-transform: translate(-50%, 5px);
    transform: translate(-50%, 5px)
}

.c-email .o-tooltip.o-tooltip-top ,
.c-notice .o-tooltip.o-tooltip-top,
#c-src-reveal .o-tooltip.o-tooltip-top,
.c-icon-area-riservata .o-tooltip.o-tooltip-top, .c-icon-logout .o-tooltip.o-tooltip-top,
body.is-scrolling:not(.no-scroll).c-icon-area-riservata .o-tooltip.o-tooltip-top,
body.is-scrolling:not(.no-scroll).c-icon-logout .o-tooltip.o-tooltip-top,
body.is-scrolling:not(.no-scroll).c-email .o-tooltip.o-tooltip-top,
body.is-scrolling:not(.no-scroll).c-notice .o-tooltip.o-tooltip-top ,
body.is-scrolling:not(.no-scroll)#c-src-reveal .o-tooltip.o-tooltip-top {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.o-tooltip.o-tooltip-top:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $green-digital;
    left: 50%;
    -ms-transform: translate(-50%, 20px);
    -webkit-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px)
}
.o-tooltip.o-tooltip-top.o-tooltip-celeste:after {
    border-top-color: #92a6bc
}

.o-tooltip.o-tooltip-left {
    top: 50%;
    left: 0;
    border-bottom: none;
    border-right: solid 2px $green-digital;
    -ms-transform: translate(-100%, 8px);
    -webkit-transform: translate(-100%, 8px);
    transform: translate(-100%, 8px)
}

.o-tooltip.o-tooltip-left:after {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid $green-digital;
    bottom: -10px;
    left: auto;
    right: 0;
    top: 50%;
    -ms-transform: translate(8px,-6px);
    -webkit-transform: translate(8px,-6px);
    transform: translate(8px,-6px)
}

.o-tooltip.o-tooltip-right {
    top: 50%;
    left: 100%;
    border-bottom: none;
    border-left: solid 2px $green-digital;
    -ms-transform: translate(0, 8px);
    -webkit-transform: translate(0, 8px);
    transform: translate(0, 8px)
}

.o-tooltip.o-tooltip-right:after {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 8px solid $green-digital;
    bottom: -10px;
    left: 0;
    right: auto;
    top: 50%;
    -ms-transform: translate(-8px,-6px);
    -webkit-transform: translate(-8px,-6px);
    transform: translate(-8px,-6px)
}

.o-tooltip.o-tooltip-bottom {
    top: auto;
	left:50%;
	bottom:0;
    border-top: solid 2px $green-digital;
    -ms-transform: translate(-50%, 15px);
    -webkit-transform: translate(-50%, 15px);
    transform: translate(-50%, 15px)
}

.o-tooltip.o-tooltip-bottom:after {
    border-top: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid $green-digital;
    left: 50%;
    -ms-transform: translate(-8px, -16px);
    -webkit-transform: translate(-8px, -16px);
    transform: translate(-8px, -16px)
}

/* Right menu > sub menu
   ---- */

.c-navigation ul.c-submenu {
    display: none;
    position: absolute;
    list-style: none;
	left: 50%;
	z-index: 900;
    top: 0;
	width:450px;
    height: auto;
    background: #fff;
	padding:0;
    -moz-box-shadow: 0 1px 10px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.1);
    box-shadow: 0 1px 10px rgba(0,0,0,.1);
	-webkit-transform: translate(-50%, 100px);
	-moz-transform: translate(-50%, 100px);
    -ms-transform: translate(-50%, 100px);
	-o-transform: translate(-50%, 100px);
    transform: translate(-50%, 100px);
}

.c-navigation.is-mobile ul.c-submenu {
    display: none;
}

body.is-scrolling:not(.no-scroll).c-navigation ul.c-submenu {
	-webkit-transform: translate(-50%, 86px);
	-moz-transform: translate(-50%, 86px);
    -ms-transform: translate(-50%, 86px);
	-o-transform: translate(-50%, 86px);
    transform: translate(-50%, 86px);
}

.c-navigation ul.c-submenu > li {
	float:left;
	position:relative;
	border-bottom:solid 1px #bcbec0;
	font-size:1.4rem;
    text-decoration: none;
	text-align:center;
	padding:0;
	display:block;
}

.c-navigation ul.c-submenu > li:last-of-type {
	border-bottom:none;
}

.c-navigation ul.c-submenu > li:after {
    content: '';
    display: block;
    clear: both;
}

.c-navigation ul.c-submenu > li > div {
    color: $dark-grey;
}

.c-navigation ul.c-submenu > li.is-disabled > div {
    color: #bcbec0;
}

.c-navigation ul.c-submenu > li:first-of-type {
	float:left;
	display:block;
	position:relative;
}

.c-navigation ul.c-submenu > li:first-of-type:before {
	display:none;
}

.c-navigation ul.c-submenu > li .c-status,
.c-navigation ul.c-submenu > li .c-title,
.c-navigation ul.c-submenu > li .c-cta,
.c-navigation ul.c-submenu > li .c-date ,
.c-navigation ul.c-submenu > li .c-details {
	float:left;
	display:block;
	position:relative;
    line-height: 1.4em;
	padding:10px;
	text-align:left;
}

.c-navigation ul.c-submenu > li .c-status,
.c-navigation ul.c-submenu > li .c-title,
.c-navigation ul.c-submenu > li .c-cta {
    line-height: 1.4em;
	padding:10px;
	text-align:left;
}

.c-navigation ul.c-submenu > li .c-status {
	position:absolute;
	width:60px;
	top:50%;
	opacity:0;
	height:20px;
	padding:0 10px;
    -ms-transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
	z-index:900
}

.c-navigation ul.c-submenu > li .c-status .o-tooltip.o-tooltip-bottom {
	top:0;
	-webkit-transform: translate(-50%, 30px);
	-moz-transform: translate(-50%, 30px);
    -ms-transform: translate(-50%, 30px);
	-o-transform: translate(-50%, 30px);
    transform: translate(-50%, 30px);
}

.c-navigation ul.c-submenu > li:hover .c-status ,
.c-navigation ul.c-submenu > li.c-visible-notice  .c-status {
    opacity: 1;
	height:100%;
	-webkit-transition: opacity 3s;
    -moz-transition: opacity 3s;
    -ms-transition: opacity 3s;
    -o-transition: opacity 3s;
    transition:opacity 3s;
}

.c-navigation ul.c-submenu > li .c-details {
	width:450px;
	padding-left:80px;
}

.c-navigation ul.c-submenu > li:before {
    content: '';
	position: absolute;
	display: block;
	top: 10%;
	width:4px;
	height: 80%;
	background: #b33f29;
	-webkit-transform: translate(41px);
	-moz-transform: translate(41px);
    -ms-transform: translate(41px);
	-o-transform: translate(41px);
    transform: translate(41px);
	-webkit-transition: transform .6s;
    -moz-transition: transform .6s;
    -ms-transition: transform .6s;
    -o-transition: transform .6s;
    transition:transform .6s;
}


.c-navigation ul.c-submenu > li:hover:before,
.c-navigation ul.c-submenu > li.c-visible-notice:before {
	-webkit-transform: translate(63px);
	-moz-transform: translate(63px);
    -ms-transform: translate(63px);
	-o-transform: translate(63px);
    transform: translate(63px);
}

.c-navigation ul.c-submenu > li .c-title {
    width: 140px;
	text-align: left;
	padding:0;
	padding-left:10px;
	line-height:47px;
}

.c-navigation ul.c-submenu > li .c-cta {
    float: right;
    width: 310px;
	text-align: right;
	padding-right:10px;
}

.c-navigation ul.c-submenu > li .o-title {
    font-family: 'Roboto Slab', serif;
	font-size:2rem;
	color:$dark-blue-digital;
	float:none;
}

.c-navigation ul.c-submenu > li .o-cta {
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
	font-weight:700;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
	height: 30px;
    line-height: 30px;
	padding:0 15px;
    background: #b3cd2b;
    float: none;
    display: inline-block;
    margin: auto;
	cursor:pointer;
    -webkit-transition: background-color 0.3s ;
    -moz-transition: background-color 0.3s ;
    -ms-transition: background-color 0.3s ;
    -o-transition: background-color 0.3s ;
    transition: background-color 0.3s ;
}

.c-navigation ul.c-submenu > li .o-cta:hover {
	background: $dark-blue-digital;
}

body.no-scrolling .c-navigation ul.c-submenu {
	visibility:hidden;
}

/* On Off 
   ---- */

.c-navigation ul.c-submenu > li .c-switch ,
.c-mobile-navigation ul.c-submenu > li .c-switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 19px;
	margin-top:0;
}

.c-navigation ul.c-submenu > li .c-switch .o-switch ,
.c-mobile-navigation ul.c-submenu > li .c-switch .o-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border:solid 2px #a7a7a7;
	border-radius: 34px;
}

.c-navigation ul.c-submenu > li .c-switch .o-switch:before ,
.c-mobile-navigation ul.c-submenu > li .c-switch .o-switch:before {
    position: absolute;
    content: "";
    width: 19px;
    height: 19px;
    right: -2px;
    bottom: -2px;
	background-color: #b8c95d;
	border-radius: 50%;
    border:solid 2px #b8c95d;
	-webkit-box-shadow: 1px 1px 5px 0px rgba(148,148,148,1);
	-moz-box-shadow: 1px 1px 5px 0px rgba(148,148,148,1);
	box-shadow: 1px 1px 5px 0px rgba(148,148,148,1);
	-webkit-transition: transform .4s;
    -moz-transition: transform .4s;
    -ms-transition: transform .4s;
    -o-transition: transform .4s;
    transition:transform .4s;
}

.c-navigation ul.c-submenu > li .c-switch.is-disabled .o-switch:before ,
.c-mobile-navigation ul.c-submenu > li .c-switch.is-disabled .o-switch:before {
	background-color: #fff;
	-webkit-transform: translate(-19px);
	-ms-transform: translate(-19px);
	transform: translate(-19px);
}

/* Search button
   ---- */

#c-src-reveal{
	float: right;	
	height: 100px;	
	position: relative;	
	width: 70px;
}

#o-src-reveal{
	display: block;
    height:100%;
	cursor:pointer
}

#o-src-reveal i {
	font-size: 3.2rem;
    margin: 0;
    display: block;
    padding: 0;
}

#o-src-reveal:focus{
	outline:0;
}

#o-src-reveal:hover i{
	color:#808285
}

/* Search form
   ---- */

.l-header .c-search-form {
    position: fixed;
    top: 149px;
    width: 100%;
    display: none;
    left: 0;
    right: 0;
    padding: 20px 0 11px 0;
    background-color: $off-white;
    z-index: 899;
	-webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -o-overflow-scrolling: touch;
    overflow-scrolling: touch;
	box-sizing:content-box;
}

body.is-scrolling:not(.no-scroll).l-header .c-search-form {
    top: 86px;
}

.l-header .c-search-form .l-container-fluid {
    max-width: 1170px;
    margin: 0 auto;
    width: auto;
	position: relative;
}

.l-header .c-search-form input[type=text] {
    font-family: 'Roboto', sans-serif;
    background-color: transparent;
    color: $dark-grey;
    font-weight: 300;
    font-size: 3rem;
    border: 0;
    width: 85%;
    text-align: left;
    padding: 10px 0;
    float: left;		
    height: 55px;
    line-height: 55px;
}

.l-header .c-search-form input[type=text]:-moz-placeholder {
    color: $dark-grey;
}

.l-header .c-search-form input[type=text]::-moz-placeholder {
    color: $dark-grey;
}

.l-header .c-search-form input[type=text]::-ms-input-placeholder {
    color: $dark-grey;
}

.l-header .c-search-form input[type=text]::-webkit-input-placeholder {
    color: $dark-grey;
}

.l-header .c-search-form input[type=submit] {
    position: relative;
    top: 10px;
	min-width:100px;
	height:43px;
	line-height:43px;
    border: 1px solid $green-digital;
    color: #fff;
	font-size: 1.4rem;
    background-color: $green-digital;
	font-weight: 900;
    letter-spacing: 1px;
	padding: 0 30px;
    float: right;
	text-transform: uppercase;
    cursor: pointer;
    text-align: center;
	-webkit-transition: color .3s, background-color .3s, border-color .3s;
    -moz-transition: color .3s, background-color .3s, border-color .3s;
    -ms-transition: color .3s, background-color .3s, border-color .3s;
    -o-transition: color .3s, background-color .3s, border-color .3s;
    transition: color .3s, background-color .3s, border-color .3s;
	font-family: 'Roboto', sans-serif;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.l-header .c-search-form input[type=submit]:hover {
    background-color: #204d7c;
    border-color: #204d7c;
}

.l-header .c-search-form form:after {
    content: '';
    display: table;
    clear: both;
}

.l-header .c-search-form form {
    padding-bottom: 10px;
    border-bottom: 2px solid $green-digital;
}

.l-header .c-search-form input[type=text]:focus,
.l-header .c-search-form input[type=submit]:focus{
	outline:0;
}

.l-header #search-results {	
	line-height: normal;	
}	
.l-header #search-results a {	
	margin: 5px;	
}

/* Mobile button
   ---- */

.c-mobile-button {
	display:none;
}

/* Socials
   ---- */
   
.c-social-icons {
	display:block;
	padding:0;
	text-align:left;
	-webkit-transform: translate(0, 50px);
	-moz-transform: translate(0, 50px);
    -ms-transform: translate(0, 50px);
	-o-transform: translate(0, 50px);
    transform: translate(0, 50px);
}

.c-social-icons > ul {
	list-style:none;
}

.c-social-icons > li {
	float:left;
	width:16.66%;
}

.c-social-icons > li > a {
	text-decoration:none;
}

.c-social-icons > li > a > i {
	font-size: 2rem;
}

.c-social-icons > li > a > i:before {
    -webkit-transition: color .3s linear;
    -moz-transition: color .3s linear;
    -ms-transition: color .3s linear;
    -o-transition: color .3s linear;
    transition: color .3s linear;
}

.c-social-icons > li > a:hover > i:before {
    color: $green-digital;
}

.c-social-icons > li:last-of-type {
    margin: 0;
}

/* Breakpoints
   ---- */

@media (max-width: 320px) {

	/* Header
	   ---- */

	.c-main-logo img {
		max-width:80%;
	}

	/* Mobile navigation
	   ---- */
	
	.c-navigation.is-mobile {
		top: 125.55px;
	}

	/* Search
	   ---- */

	.l-header .c-search-form input[type=text] {
		font-size: 1.8rem;
		width: 60%;
	}	
	
	.l-header .c-search-form input[type=submit] {
		font-size: 1.6rem;
		width: 40%;
		min-width:inherit;
	}	

	.l-header .c-search-form form:after {
		position: absolute;
		bottom: 0;
		width:55%;
		height:2px;
		background:$green-digital;
	}

}

@media (max-width: 480px) {

	/* Body
	   ---- */
	
	.l-header.is-scrolling:not(.no-scroll) .o-icon-mobile-menu:before {
		margin: 8px 0 0 25px;
	}

	/* Header
	   ---- */

	.c-bcc-logo img {
		height: 15px;
		width: auto;
	}

	.l-header {
		height:70px;
		line-height:70px;
	}

	/* Burger menu
	   ---- */
	   
	.c-menu-button span,
	.intranet-menu-button span {
		display:none;
	}
		
	/* Hamburger menu > nav
	   ---- */

	.c-scroll-container {
		top:125.5px;
	}
	
	.o-scroll-overlay {
		top:125.5px;
	}

	.c-scroll-content {
		width:80%;
		left:-80%;
		padding:20px 0 50px 0;
		position: relative;
	}

	.c-scroll-content .o-title {
		margin-bottom: 20px;
	}
	
	.o-scroll-close {
		display: none;
	}
	
	/* Hamburger menu > close
	   ---- */
	
	.c-scroll-container.is-open .o-scroll-close {
		top:141px;
		left:83.33%;
	}

	/* Search
	   ---- */
	
	#c-src-reveal {
		height: 70px;
	}

	.l-header .c-search-form {
		top: 125.48px;
	}

	/* Icons
	   ---- */

	.c-navigation.is-mobile .c-icon-area-riservata i:before,
	.c-navigation.is-mobile .c-icon-logout i:before,
	.c-navigation.is-mobile .c-email i:before ,
	.c-navigation.is-mobile .c-notice i:before {
		font-size: 3.2rem;
		-webkit-transform: translate(-50%, -32px);
		-moz-transform: translate(-50%, -32px);
		-ms-transform: translate(-50%, -32px);
		-o-transform: translate(-50%, -32px);
		transform: translate(-50%, -32px);
	}

	.c-navigation.is-mobile .c-icon-area-riservata i:after,
	.c-navigation.is-mobile .c-icon-logout i:after,
	.c-navigation.is-mobile .c-email i:after ,
	.c-navigation.is-mobile .c-notice i:after {
		-webkit-transform: translate(0, 16px);
		-moz-transform: translate(0, 16px);
		-ms-transform: translate(0, 16px);
		-o-transform: translate(0, 16px);
		transform: translate(0, 16px);
	}
	
}

@media (max-width: 991px) {

	.c-main-logo a {
		height: 41px;
		width: 200px;
	}

	.c-main-logo .c-logo-mobile, .c-login  .c-logo-mobile { 
			display: inline-block;
	}
	.c-main-logo .c-logo-desktop, .c-login  .c-logo-desktop { 
		display: none;
	}
	
	.l-header.is-scrolling:not(.no-scroll) .c-mobile-button {
		-ms-transform: translate(0, -8px);
		-webkit-transform: translate(0, -8px);
		transform: translate(0, -8px);
	}
	
	.l-header .c-mobile-button i {
		position: static;
		transform: none;
	}

	.c-navigation:not(.c-navigation2) {
		position: fixed;
		list-style: none;
		top: 150px;
		left:0;
		right:0;
		bottom:0;
		width:100%;
		padding:0;
		z-index: 898;
		background-image: url(../images/main-nav-bg.png);
		background-repeat: repeat;
		display:none;
		cursor: pointer;
		-webkit-overflow-scrolling: touch;
		-moz-overflow-scrolling: touch;
		-o-overflow-scrolling: touch;
		overflow-scrolling: touch;
		box-sizing:content-box;
		overflow-x: hidden;
		-ms-overflow-x: hidden; 
		overflow-y: auto;
		-ms-overflow-y: auto; 
	}

	.l-header.is-scrolling:not(.no-scroll) .c-navigation.is-open {
		top: 86px;
	}
	
	.c-navigation.is-mobile > ul {
		padding: 5px 5px 0;
		z-index:901;
	}

	.c-navigation.is-mobile > ul > li {
		padding:5px;
		margin:0 5px 5px 0;
	}

	.c-navigation.is-mobile > ul > li {
  		display: table;
		position:relative;
		text-align:center;
		padding:0;
		background-color:#f5f5f5;
	}

	.c-navigation.is-mobile > ul > li > a {
		padding:0;
	}

	.l-header .c-navigation.is-mobile>ul>li:hover .o-icon-notice:after,
	.l-header .c-navigation.is-mobile>ul>li:hover .o-icon-email:after {
    color: #fff;	}
	
	.l-header .c-navigation.is-mobile>ul>li:hover .o-icon-notice:before {
    	background-image: url("../images/notifiche-white.svg");
	}
	.l-header .c-navigation.is-mobile>ul>li:hover .o-icon-email:before {
    	background-image: url("../images/mail-white.svg");
	}
	
	.c-navigation.is-mobile>ul>li:hover a,
	.c-navigation.is-mobile>ul>li a:hover,
	.c-navigation.is-mobile>ul>li:hover a.o-notice {
		background-color: transparent;
		color:#fff;
	}

	.l-header.is-scrolling:not(.no-scroll) .c-navigation.is-mobile>ul>li {
		padding:0;
	}
	
	.c-navigation.is-mobile > ul > li:after,
	.c-navigation.is-mobile > ul > li>a:after {
		display: none;
	}

	.c-navigation.is-mobile > ul > li > a {
		color: #fff;
		font-size:1.6rem;
		overflow:hidden;
		text-decoration: none;
		transition: color .3s ease-out, background-color .3s ease-out;
		width:100%;
	}
	
	.c-navigation.is-mobile > ul > li.o-active:before {
		display:none;
	}

	.c-navigation.is-mobile>ul>li>a.o-area-riservata ,
	.l-header.is-scrolling:not(.no-scroll) .c-navigation.is-mobile>ul>li>a.o-area-riservata {
		background:none;
		margin:0;
		height: 100%;
		padding:0;
		-webkit-transform: translate(0);
		-moz-transform: translate(0);
		-ms-transform: translate(0);
		-o-transform: translate(0);
		transform: translate(0);
	}

	.c-navigation.is-mobile>ul>li>a.o-email i, 
	.c-navigation.is-mobile>ul>li>a.o-notice i {
		position: absolute;
		width: 100%;
		max-height: 100%;
		margin: 0;
		display: block;
		padding: 0;
		left: 50%;
		top:0;
		bottom:0;
		-ms-transform: translate(-50%, 0);
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
	
	.c-navigation.is-mobile>ul>li>a>i:before,
	.c-navigation.is-mobile>ul>li>a.o-email i:before, 
	.c-navigation.is-mobile>ul>li>a.o-notice i:before {
		color: #fff;
		position:absolute;
		left: 50%;
		top: 50%;
		margin:0
	}

	#c-src-reveal {
		width: 40px;
		right: 30px;
	}

	body.is-scrolling:not(.no-scroll).l-header .c-search-form {
		top: 125.5px;
	}

	/* Icons
	   ---- */
	
	.c-navigation.is-mobile .c-icon-area-riservata i:after,
	.c-navigation.is-mobile .c-icon-logout i:after,
	.c-navigation.is-mobile .c-email i:after ,
	.c-navigation.is-mobile .c-notice i:after {
		font-family: 'Roboto', sans-serif;
		font-weight:700;
		display:block;
		opacity: 1;
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		padding:0;
		background:none;
		color:$dark-grey;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		border:none;
		text-transform:initial;
		content:attr(icon-title); 
	}

	.c-navigation.is-mobile .c-notice i:after {
		content:attr(icon-title); 
		font-size: 1.2rem;
	}
	
	.c-navigation.is-mobile .o-generic i:after {
	    font-style: normal;
	    font-weight: 400;
	}
	
	.c-navigation.is-mobile .c-icon-logout .o-generic:focus i:after,
	.c-navigation.is-mobile .c-icon-logout .o-generic:hover i:after {
		color: #fff;
	}
	
	.l-header .c-navigation.is-mobile .o-generic i {
		top: 0%;
    	transform: none;
	}
	.c-navigation.is-mobile .o-generic i:before {
		transform: translate(-50%, -30px);
	}
	

	.l-header .o-icon-area-riservata:before {
		background: url("../images/area-riservata.svg") no-repeat center center;
		
		width: 35px;
		height: 40px;
	}

	.l-header .c-icon-logout:hover .o-icon-logout:before {
		background: url("../images/esci_bianco.svg") no-repeat center center;
	}

	.l-header .o-icon-search:before {
		content: "";
		background: url("../images/icon-search-mobile.svg") no-repeat center center;
		width: 40px;
		height:22px;
		margin:0
	}
	
	.l-header .o-icon-mobile-menu:before {
		height:22px;
		margin-top:0;
		margin-left:0 !important
	}

	/* Tooltip
	   ---- */
	
	.o-tooltip {
		display:none;
	}
	
}

@media (max-width: 1200px) {

	/* Socials
	   ---- */
	
	.c-social-icons > li > a > i {
		font-size:1.6rem;
	}
	
}

@media (min-width: 481px) and (max-width: 991px) {

	.l-header .o-icon-mobile-menu:before {
		margin: 0 0 0 25px;
	}

	.l-header.is-scrolling:not(.no-scroll) .o-icon-mobile-menu:before {
		margin: 8px 0 0 25px;
	}

	/* Search
	   ---- */

	#c-src-reveal {
		right: 40px;
	}

	#o-src-reveal{
		bottom:10px;
		-ms-transform: translate(0,0);
		-webkit-transform: translate(0,0);
		transform: translate(0,0);
	}
	
}

@media (max-width: 480px) {
	
	.l-header .c-search-form input[type=text] {
		width: 100%;
	}	
	
	.l-header .c-search-form input[type=submit] {
		width: 30%;
		min-width:inherit;
	}	

}

@media (min-width: 481px) and (max-width: 662px) {

	/* Icons
	   ---- */
	
	.c-navigation.is-mobile .c-icon-area-riservata i:before,
	.c-navigation.is-mobile .c-icon-logout i:before,
	.c-navigation.is-mobile .c-email i:before ,
	.c-navigation.is-mobile .c-notice i:before {
		font-size: 3.6rem;
		-webkit-transform: translate(-50%, -36px);
		-moz-transform: translate(-50%, -36px);
		-ms-transform: translate(-50%, -36px);
		-o-transform: translate(-50%, -36px);
		transform: translate(-50%, -36px);
	}

	.c-navigation.is-mobile .c-icon-area-riservata i:after,
	.c-navigation.is-mobile .c-icon-logout i:after,
	.c-navigation.is-mobile .c-email i:after ,
	.c-navigation.is-mobile .c-notice i:after {
		-webkit-transform: translate(0, 18px);
		-moz-transform: translate(0, 18px);
		-ms-transform: translate(0, 18px);
		-o-transform: translate(0, 18px);
		transform: translate(0, 18px);
	}
	
	.l-header .c-search-form input[type=text] {
		width: 65%;
	}	
	
	.l-header .c-search-form input[type=submit] {
		width: 25%;
		min-width:inherit;
	}	

}

@media (min-width: 663px) and (max-width: 991px) {

	/* Icons
	   ---- */
	
	.c-navigation.is-mobile .c-icon-area-riservata i:after,
	.c-navigation.is-mobile .c-icon-logout i:after,
	.c-navigation.is-mobile .c-email i:after ,
	.c-navigation.is-mobile .c-notice i:after {
		-webkit-transform: translate(0, 16px);
		-moz-transform: translate(0, 16px);
		-ms-transform: translate(0, 16px);
		-o-transform: translate(0, 16px);
		transform: translate(0, 16px);
	}

}

@media (min-width: 992px) and (max-width: 1199px) {

	/* Desktop navigation tablet
	   ---- */
	
	.c-navigation {
		padding-right: 65px;
	}
	
	.c-navigation>ul>li {
		margin: 0;
	}
	
	.c-navigation>ul>li>a:link {
		padding: 0 10px;
	}
	
	#c-src-reveal {
		width: 70px;
		right: 5px;
	}

}

@media (max-width: 991px) {

	/* Mobile navigation
	   ---- */
	
	.c-navigation.is-mobile > ul > li {
		display: block;
		height: 45px !important;
		line-height: 45px !important;
		text-align: center;
		width: 100%;
	}

}

@media (min-width: 0px) and (max-width: 767px) {
	
	.c-note-head {
		height: 28px;
    	padding: 4px 0;
	}

	.l-header,
	.l-header.is-scrolling:not(.no-scroll) {
		height: 50px;
		line-height: 50px;	
	}
	
	.c-scroll-content ul.c-scroll-nav {
    	margin-bottom: 0px;
    	display: none;
    	background: #fff
	}
	.c-scroll-content ul.c-scroll-nav.is-open {
		display: block
	}
	.c-scroll-content ul.c-scroll-nav li {
		margin: 0;
	}
	.c-scroll-content ul.c-scroll-nav li a {
		display: block;
		padding: 10px 0 10px 20px 
	}
	.c-scroll-content ul.c-scroll-nav li:last-child { 
		border-bottom: 1px solid #d8d7d8;
	}
	.c-scroll-content ul.c-scroll-nav li a:focus,
	.c-scroll-content ul.c-scroll-nav li a:hover {
		background-color: rgba(178,206,39,.5) !important;
    	color: #222 !important;
    	transform: none !important;
    	color: #222 !important
    }
	.c-scroll-content {
		width: 100%;
    	height: 100%;
    	padding: 0
    }
	.c-scroll-content .row div { padding: 0 }
	.c-scroll-content .o-title {
		background: #fff;
		border-bottom: 1px solid #d8d7d8;
		color: #222;
		display: block;
    	font-family: Roboto,sans-serif;
    	font-size: 1.8rem;
    	font-weight: 300;
    	line-height: inherit;
    	margin: 0;
		outline: 0;
    	padding: 10px 16px;
    	position: relative;
		text-decoration: none;
    	transition: background .3s ease-out;
	}
	.c-scroll-content .o-title svg {
		height: 47px;
		max-width: 80px;
		fill: #222;
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg);
		position: absolute;
		top: 0;
		right: 0;
		padding: 15px 20px;
		-webkit-transition: -webkit-transform .5s;
		transition: -webkit-transform .5s;
		transition: transform .5s;
		transition: transform .5s,-webkit-transform .5s;
		display: block
	}
	
	.c-scroll-content .o-title.is-open svg {
    	-webkit-transform: rotate(270deg);
    	transform: rotate(270deg);
	}


	.c-scroll-content .o-title.is-open {
		background-color: rgba(178,206,39,.2);
    	color: #222;
    }
    .c-scroll-content .o-title:focus,
    .c-scroll-content .o-title:hover {
		background-color: rgba(178,206,39,.5);
    	color: #222;
    }
	.c-scroll-container .o-scroll-close { display: none !important }

	.c-menu-button,
	.intranet-menu-button,
	.c-mobile-button,
	.l-header.is-scrolling:not(.no-scroll) #c-src-reveal,
	.l-header.is-scrolling:not(.no-scroll) .c-menu-button,
	.l-header.is-scrolling:not(.no-scroll) .intranet-menu-button,
	#c-src-reveal {
		height: 50px;
	}

	.c-scroll-container {
		top: 78px;
	}

	.c-scroll-container.is-open .o-scroll-close {
		top: 105px;
	}
	
	.c-navigation.is-mobile {
		top: 78px;
	}

	.l-header .c-search-form {
		top: 78px ;
	}
	
	.c-iccrea-logo {
		margin: 0 0 0 -5px;
	}
	.c-iccrea-logo a:focus {
		box-shadow: 0 0 0 2px #fff;
	}
	// .c-iccrea-logo img {
	// 	height: 8px;
	// 	width: 122.97px;
	// }

	.c-bcc-logo {
		max-width: 70px;
	}

	.c-bcc-logo img {
		height: 20px;
		width: auto;
	}
	
	.c-menu-reveal {
		width: 18px;
		height: 18px;
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0)
	}

	.l-header.is-scrolling:not(.no-scroll) .o-menu-icon {
		-ms-transform: translate(0, -11px);
		-webkit-transform: translate(0, -11px);
		transform: translate(0, -11px);
	}

	.c-menu-button,
	.intranet-menu-button {
		width: 60px;
	}

	.o-menu-icon,
	.o-menu-icon:before ,
	.o-menu-icon:after {
		width: 21px;
		height:2px
	}
	
	.o-menu-icon:before {
		top: -7px;
	}

	.o-menu-icon:after {
		top: 7px;
	}

	.c-menu-button.is-open .c-menu-reveal .o-menu-icon:before,
	.intranet-menu-button.is-open .c-menu-reveal .o-menu-icon:before {
		left:0;
		-webkit-transform: rotateZ(-45deg) scaleX(0.75) translate(-7px, -2.33px);
		-ms-transform: rotate(-45deg) scaleX(0.75) translate(-7px, -2.33px);
		transform: rotateZ(-45deg) scaleX(0.75) translate(-7px, -2.33px);
	}
	
	.c-menu-button.is-open .c-menu-reveal .o-menu-icon:after,
	.intranet-menu-button.is-open .c-menu-reveal .o-menu-icon:after {
		left:0;
		-webkit-transform: rotateZ(45deg) scaleX(0.75) translate(-7px, 2.33px);
		-ms-transform: rotate(45deg) scaleX(0.75) translate(-7px, 2.33px);
		transform: rotateZ(45deg) scaleX(0.75) translate(-7px, 2.33px);
	}

	.c-main-logo a {
		height: 26px;
		width: 116px;
	}
	.c-main-logo img {
		height:20px;
	}

	.l-header.is-scrolling:not(.no-scroll) .c-main-logo img {
		-webkit-transform: translate(0, 0);
		-moz-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		-o-transform: translate(0, 0);
		transform: translate(0, 0);
	}
	
	/* Mobile button
	   ---- */

	.l-header.is-scrolling:not(.no-scroll) #o-src-reveal {
		bottom:0;
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	.c-menu-button span,
	.intranet-menu-button span {
		display:none;
	}

	.l-header .c-search-form input[type=submit] {
		height:40px;
		line-height:40px;
		text-align:center;
		padding:0
	}
	
	.l-header .c-search-form input[type=text] {
		font-size:2rem;
	}

	.l-header .c-search-form {
		padding: 0 0 15px 0;
	}
	
	.l-header .c-search-form form {
		border-bottom: none;
		height: 50px;
		position: relative;
	}

	#c-src-reveal:after {
    	content: none;
	}
	.c-menu-button:before,
	.intranet-menu-button:before {
		height: 3px;
	}
}

@media (min-width: 0px) and (max-width: 320px) {

	.l-header .c-search-form form:after {
		position: absolute;
		bottom: 0;
		width:55%;
		height:2px;
		background:$green-digital;
	}

}

@media (max-width: 480px) {

	.l-header .c-search-form form:after {
		position: absolute;
		bottom: 0;
		width: calc(100%-30px);
		height:2px;
		background:$green-digital;
	}

}

@media (min-width: 481px) and (max-width: 767px) {

	.l-header .c-search-form form:after {
		position: absolute;
		bottom: 0;
		width:80%;
		height:2px;
		background:$green-digital;
	}

	.l-header .c-search-form input[type=submit] {
		width: 15%;
		min-width:inherit;
	}	

}

@media (max-width: 991px) {
	.c-mobile-button {
		background-color: transparent;
		border: 0;
		border-radius: 6px;
		display: block;
		height: 100px;
		outline: 0;
		position: absolute;
		right: 0;
		width: 40px;
	}
	.c-mobile-button:focus {
		box-shadow: 0 0 0 3px #264d7a;
	}
	.c-mobile-button:active {
		box-shadow: none;
	}

	.l-header.is-scrolling:not(.no-scroll) #c-src-reveal,
	.l-header.is-scrolling:not(.no-scroll) .c-mobile-button {
		height: 86px;
	}
}

@media (max-width: 767px) {
	.c-mobile-button,
	.l-header.is-scrolling:not(.no-scroll) #c-src-reveal,
	.l-header.is-scrolling:not(.no-scroll) .c-mobile-button {
		height: 50px;
		line-height: 50px;
		transform: none;
	}

	.l-header.is-scrolling:not(.no-scroll) .o-icon-mobile-menu:before {
		margin: 0;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	
	.l-header .c-search-form input[type=submit] {
		width: 15%;
		min-width:inherit;
	}

	.c-note-head {
		height: 46px;
	}

	.c-iccrea-logo {
		margin: 5px 0 0 -5px;
	}
	
	.c-main-logo img {
		height: 35px;
	}

	.c-bcc-logo img {
		max-height: 30px;
	}

	.c-scroll-container {
		top: 146px;
	}

	.c-navigation.is-mobile {
		top: 146px;
	}

	.l-header .c-search-form {
		top: 146px ;
	}

}

/* Implementazioni link solo testo, senza icone */
.c-navigation.only-text {
	padding-right: 0;
}

.c-navigation.only-text a {
	transition: color .3s;
}

@media (max-width: 991px) {
	.c-navigation.only-text.is-mobile > ul > li a {
		color: #222;
	}

	.c-navigation.only-text.is-mobile > ul > li > a:active,
	.c-navigation.only-text.is-mobile > ul > li > a:focus,
	.c-navigation.only-text.is-mobile > ul > li > a:hover,
	.c-navigation.only-text.is-mobile .o-notice.is-open {
		background-color: #264d7a;
		color: #fff;
	}
}
/* Fine implementazioni link solo testo, senza icone */

/* Menu a tendina nei quicklink */
.c-navigation ul.second-level-menu {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    display: none;
    left: -108px;
    padding: 0;
    position: absolute;
    width: 300px;
    z-index: 999;
}

.c-navigation ul.second-level-menu span {
    border-bottom: solid 1px #bcbec0;
    color: #264d7a;
    display: block;
    float: none;
    font-family: 'Roboto Slab', serif;
    font-size: 1.8rem;
    font-weight: 700;
    height: auto;
    line-height: normal;
    margin: 0;
    padding: 15px;
    text-transform: uppercase;
}

.c-navigation ul.second-level-menu li {
    padding: 15px 15px 15px 30px;
    border-bottom: solid 1px #bcbec0;
    height: auto;
    line-height: normal;
    list-style-type: none;
    position: relative;
}

.c-navigation ul.second-level-menu li:before {
    background-color: #264d7a;
    bottom: 10px;
    content: '';
    display: block;
    left: 15px;
    position: absolute;
    top: 10px;
    width: 4px;
}

.c-navigation ul.second-level-menu li:last-child {
    border-bottom: none;
}

.c-navigation ul.second-level-menu li a {
    color: #264d7a;
    display: block;
    font-size: 1.4rem;
    text-decoration: none;
    text-transform: uppercase;
    -moz-transition: color .3s ease-in-out;
    -ms-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
}

.c-navigation ul.second-level-menu li a {
    color: #264d7a !important;
	outline: 0;
}
.c-navigation ul.second-level-menu li a:focus,
.c-navigation ul.second-level-menu li a:hover {
    color: #b2ce27 !important;
}

@media (max-width: 1199px) {
	.c-navigation ul.second-level-menu {
		left: -142px;
	}
}

@media (max-width: 991px) {
	.c-navigation .o-notice {
		margin: 0;
	}

	.c-navigation ul.second-level-menu {
		left: 0;
		text-align: left;
		width: 100%;
	}

	.c-navigation ul.second-level-menu span {
		display: none;
	}

	.c-navigation ul.second-level-menu li {
		padding: 15px 15px 15px 30px;
		border-bottom: solid 1px #bcbec0;
		height: auto !important;
		line-height: normal !important;
		list-style-type: none;
		position: relative;
	}

	.c-navigation ul.second-level-menu li a {
		color: #264d7a;
		display: block;
		font-size: 1.4rem;
		text-decoration: none;
		text-transform: uppercase;
		-moz-transition: color .3s ease-in-out;
		-ms-transition: color .3s ease-in-out;
		-o-transition: color .3s ease-in-out;
		-webkit-transition: color .3s ease-in-out;
		transition: color .3s ease-in-out;
	}

	.c-navigation .o-icon-logout:before {
		content: none;
	}

	.c-navigation.is-mobile .c-icon-logout i:after {
		transform: none;
	}
}
/* Fine menu a tendina nei quicklink */