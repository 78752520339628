body { 
	position:inherit;
}
body.no-overflow { 
	overflow: hidden;
}

.c-error-404 {
	// width:100%;
	// height:460px;
	position:absolute;
	top:50%;
	left:50%;
	text-align:center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
	padding:0;
}

.c-error-404 .c-main-logo {
	width:90%;
	max-width: 400px;
	display:block;
	margin:0 auto 30px auto;
	float: none;
}

.c-error-404 .c-404-image {
	height: auto;
	margin-left: -100px;
	max-width: 250px;
	width: 100%;
}

.c-error-404 a.c-primary-button {
	margin:0;
	line-height: 2.5rem
}