.c-contact-form select::-ms-expand {
    display: none;
}
   
   .c-contact-form input:active,
   .c-contact-form input:focus,
   .c-contact-form input:hover ,
   .c-contact-form textarea:active,
   .c-contact-form textarea:focus,
   .c-contact-form textarea:hover {
       outline:none;
   }
   .c-contact-form .col-md-offset-right-6 {
     margin-right: 50%;
   }
   
   /* Contact form
      ---- */
   
   .c-contact-form {
       padding: 50px 30px 0 30px;
       border: 2px solid #264d7a;
       position: relative;
       margin: 100px 0 0 0;
       width: 100%;
       display: block;
   }
   
   .c-contact-form-menomargin {
   		margin: 50px 0;
	}
   
   .c-contact-form:before {
        content: attr(form-title);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px 10px 20px 84px;
        font-size: 2.5rem;
        color: #264d7a;
        background-image: url(../images/contact-form.svg);
        background-repeat: no-repeat;
        background-color: #fff;
        background-position: 0;
        -webkit-transform: translate(-2px,-36px);
        transform: translate(30px,-33px);
        background-position: 10px 0;
   }

   
@media screen and (min-width:0\0) and (min-resolution: +72dpi) { /* IE 9 e superiore */
     .c-contact-form:before { background-image: url(../images/contact-form.png); }
} 
 
   .c-contact-form:after,
   .c-form-group:after {
       content: '';
       display: block;
       clear: both;
   }
   
   .c-contact-form.c-contact-form-no-border {
       margin-top:0;
       border: none;
       padding-left: 0;
       padding-right: 0;
   }
   
   .c-contact-form.c-contact-form-no-border:before {
       display:none;
       visibility:hidden;
   }
   
   /* Group
      ---- */
   
   .c-contact-form .c-form-group {
       width: 100%; 
       margin:0 0 50px 0;
       text-align:left;
       position:relative;
   }
   
   .c-contact-form .c-form-group-error .c-form-group {
       margin:0;
   }
   
   .c-privacy span.o-message ,
   .c-recaptcha span.o-message ,
   .c-form-checkbox span.o-message ,
   .c-contact-form .c-form-group span.o-message {
       font-family: 'Roboto', sans-serif;
       font-size: 1.4rem;
       color: #ff0000;
       margin: 5px 0 0 5px;
       display: none;
   }
   
   .c-privacy span.o-message-error ,
   .c-recaptcha span.o-message-error ,
   .c-form-checkbox.o-input-error span.o-message ,
   .c-contact-form .c-form-group span.o-message-error {
       display:block;
       margin-bottom:-25px;
   }
   
   .o-input-error .c-checkbox-container label {
       color:#FF0000;
   }
   
   /* Inputs
      ---- */
   
   .c-contact-form input,
   .c-contact-form textarea,
   .c-contact-form label,
   .c-contact-form .c-form-checkbox{
       font-family: 'Roboto', sans-serif;
       font-size: 2rem;
       color:#949494;
       font-weight:300;
       position:relative;
       z-index: 800;
   }
   
   .c-contact-form input:-webkit-autofill,
   .c-contact-form input:-webkit-autofill:hover,
   .c-contact-form input:-webkit-autofill:focus,
   .c-contact-form input:-webkit-autofill:active {
       transition: background-color 5000s ease-in-out 0s;
   }
   
   /* HTML Placeholders
      ---- */
   
    .c-contact-form span.o-etichetta {
    	margin-bottom: 15px;
    	display: inline-block;
    }
    .c-contact-form span.o-etichetta strong {
    	margin-right: 5px;
    }
   .c-contact-form span.o-placeholder {
       color:#66696C;
       font-family: 'Roboto', sans-serif;
       font-size:2rem;
       font-weight:300;
       height:28px;
       left:5px;
       line-height:28px;
       padding: 0 5px;
       position: absolute;
       top:11px;
       -webkit-transition: transform .3s;
       -moz-transition: transform .3s;
       -ms-transition: transform .3s;
       -o-transition: transform .3s;
       transition:transform .3s;
       z-index: 799;
   }
   
   .c-contact-form input.is-filled ~ span.o-placeholder,
   .c-contact-form textarea.is-filled ~ span.o-placeholder,
   .c-contact-form input:active ~ span.o-placeholder ,
   .c-contact-form input:focus ~ span.o-placeholder ,
   .c-contact-form textarea:active ~ span.o-placeholder ,
   .c-contact-form textarea:focus ~ span.o-placeholder {
       background: #fff;
       -ms-transform: translate(0, -25px);
       -webkit-transform: translate(0, -25px);
       transform: translate(0, -25px);
       z-index: 801;
   }
   
   .c-contact-form input[type="file"].is-filled:active ~ span.o-placeholder ,
   .c-contact-form input[type="file"].is-filled:focus ~ span.o-placeholder {
       background: #fff;
       -ms-transform: translate(0, -25px);
       -webkit-transform: translate(0, -25px);
       transform: translate(0, -25px);
       z-index: 801;
   }
   
   .c-contact-form input[type="file"]:active ~ span.o-placeholder ,
   .c-contact-form input[type="file"]:focus ~ span.o-placeholder {
       -ms-transform: translate(0, 0);
       -webkit-transform: translate(0, 0);
       transform: translate(0, 0);
   }
   
   /* Placeholders
      ---- */
   
   .c-contact-form input::-webkit-input-placeholder,
   .c-contact-form textarea::-webkit-input-placeholder {
       color: transparent;
   }
   .c-contact-form input:-moz-placeholder,
   .c-contact-form textarea:-moz-placeholder {
       color: transparent;
   }
   
   .c-contact-form input::-moz-placeholder,
   .c-contact-form textarea::-moz-placeholder {
       color: transparent;
   }
   
   .c-contact-form input::-ms-placeholder,
   .c-contact-form textarea::-ms-placeholder {
       color: transparent;
   }
   
   .c-contact-form input::-ms-input-placeholder,
   .c-contact-form textarea::-ms-input-placeholder {
       color: transparent;
   }
   
   .c-contact-form input::placeholder,
   .c-contact-form textarea::placeholder {
       color: transparent;
   }
   
   .c-contact-form input.o-date:focus::-webkit-input-placeholder ,
   .c-contact-form input.o-date.is-filled::-webkit-input-placeholder {
       color:#222222;
   }
   
   .c-contact-form input.o-date:focus:-moz-placeholder ,
   .c-contact-form input.o-date.is-filled:-moz-placeholder {
       color:#222222;
   }
   
   .c-contact-form input.o-date:focus::-moz-placeholder ,
   .c-contact-form input.o-date.is-filled::-moz-placeholder {
       color:#222222;
   }
   
   .c-contact-form input.o-date:focus::-ms-placeholder ,
   .c-contact-form input.o-date.is-filled::-ms-placeholder {
       color:#222222;
   }
   
   .c-contact-form input.o-date:focus::-ms-input-placeholder ,
   .c-contact-form input.o-date.is-filled::-ms-input-placeholder {
       color:#222222;
   }
   
   .c-contact-form input.o-date:focus::placeholder ,
   .c-contact-form input.o-date.is-filled::placeholder {
       color:#222222;
   }
   
   /* Input error
      ---- */
   
   .c-contact-form .o-input-error {
       border:solid 1px #FF0000 !important;
   }
   
   .c-contact-form .c-privacy.o-input-error ,
   .c-contact-form .c-form-checkbox.o-input-error {
       border:none !important;
   }
   
   .c-contact-form .c-privacy.o-input-error .c-checkmark,
   .c-contact-form .c-form-checkbox.o-input-error .c-checkbox-container.c-checkbox .c-checkmark,
   .c-contact-form .c-form-checkbox.o-input-error .c-checkbox-container.c-checkbox-radio .c-checkmark:before {
       border: solid 2px #FF0000 !important;
   }
  
    .c-contact-form .c-form-checkbox.o-input-error .c-checkbox-container.c-checkbox-radio input:focus ~ .c-checkmark:before,
    .c-contact-form .c-form-checkbox.o-input-error .c-checkbox-container.c-checkbox input:focus ~ .c-checkmark,
    .c-contact-form .c-privacy.o-input-error input:focus ~  .c-checkmark {
        border: solid 2px #b2ce27 !important;
    }

   .c-form-group-error i:before,
   .c-contact-form .c-privacy.o-input-error ,
   .c-contact-form .o-datepicker.o-input-error,
   .c-form-group-filled.c-form-group-error i:before,
   .c-contact-form .o-datepicker.o-input-error ~ span ~ i:before,
   .c-contact-form .o-datepicker.o-input-error:focus ~ span ~ i:before,
   .c-contact-form .o-datepicker.o-input-error.is-filled ~ span ~ i:before ,
   .c-contact-form .o-datepicker.o-input-error.is-filled:focus ~ span ~ i:before {
       color: #FF0000 !important;
   }
   
   /* Checkbox / Radio
      ---- */
   
   .c-contact-form .c-form-checkbox {
       width: 100%;
       max-width: 100%;
       position:relative; 
       margin:0 0 50px 0;
       padding: 0;
       text-align:left;
       min-height: 50px;
   }
   
   .c-contact-form .c-form-checkbox.c-form-checkbox-grid {
       margin:0;
   }
   
   .c-contact-form .c-checkbox-container {
       display: block;
       position:relative;
       padding-left:25px;
   }
   
   
   .c-form-checkbox-grid .c-checkbox-container {
       display: block;
       position:relative;
       padding-left:25px;
       width:50%;
       float:left;
   }
   
   .c-contact-form .c-form-checkbox label {
       display:block;
       position:relative;
       width: 100%;
       padding: 0;
       float: none;
   }
   
   .c-contact-form .c-form-checkbox:after {
       content: '';
       display: block;
       clear: both;
   }
   
   .c-contact-form .c-checkbox-container input {
       position: absolute;
       top: 0%;
       left: 0;
       height: 16px;
       width: 16px;
       opacity: 0;
       cursor: pointer;
       z-index:899;
       margin:0;
       -ms-transform: translate(0, 5px);
       -webkit-transform: translate(0, 5px);
       transform: translate(0, 5px);
   }
   
   .c-contact-form .c-checkbox-container .c-checkmark {
       position: absolute;
       top: 0%;
       left: 0;
       height: 18px;
       width: 18px;
       background-color: #fff;
       z-index:898;
       text-align:left;
       -ms-transform: translate(0, 5px);
       -webkit-transform: translate(0, 5px);
       transform: translate(0, 5px);
   }
   
   /* Checkbox 
      ---- */
   
   .c-contact-form .c-checkbox-container.c-checkbox .c-checkmark {
       background:#fff;
       border:solid 2px #949494;
       border-radius: 5px;
       content: '';
       height: 16px;
       position: absolute;
       -webkit-transition: all 0.3s;
       -moz-transition: all 0.3s;
       -ms-transition: all 0.3s;
       -o-transition: all 0.3s;
       transition: all 0.3s;
       width: 16px;
    }
   
   .c-contact-form .c-checkbox-container.c-checkbox input:checked ~ .c-checkmark {
       background: #264D7A;
       border: solid 2px #264D7A;
   }
   
   .c-contact-form .c-checkbox-container.c-checkbox input:hover ~ .c-checkmark:after ,
   .c-contact-form .c-checkbox-container.c-checkbox input:checked ~ .c-checkmark:after {
       content: '';
       position: absolute;
       left: 4px;
       top: 1px;
       width: 4px;
       height: 8px;
       border: solid #fff;
       border-width: 0 2px 2px 0;
       -webkit-transform: rotate(45deg);
       -ms-transform: rotate(45deg);
       -o-transform: rotate(45deg);
       transform: rotate(45deg);
   }
       
   .c-contact-form .c-checkbox-container.c-checkbox input:disabled:checked ~ .c-checkmark {
       background:#949494;
       border:solid 2px #949494;
   }
   
   
   .c-contact-form .c-checkbox-container.c-checkbox input:hover ~ .c-checkmark {
       border:solid 2px #264D7A;
   }

   .c-contact-form .c-checkbox-container.c-checkbox.c-disabled-input input:focus ~ .c-checkmark,
   .c-contact-form .c-checkbox-container.c-checkbox.c-disabled-input input:hover ~ .c-checkmark {
       border:solid 2px #949494;
   }

    .c-contact-form .c-checkbox-container.c-checkbox input:focus ~ .c-checkmark {
        border-color: #b2ce27 !important;
    }
  
   
   
   /* Radio
      ---- */
   
   .c-contact-form .c-checkbox-container.c-checkbox-radio {
       display: block;
       float:left;
       margin: 0;
       padding: 0 15px 0 25px;
       position: relative;
       -webkit-user-select: none;
       -moz-user-select: none;
       -ms-user-select: none;
       user-select: none;
       height:18px;
       line-height: 18px;
       text-align:right;
   }
   
   .c-contact-form .c-checkbox-container.c-checkbox-radio .c-checkmark {
       top: 0;
       left: 0;
       -ms-transform: translate(0);
       -webkit-transform: translate(0);
       transform: translate(0);
       cursor: pointer;
   }
   
   .c-contact-form .c-checkbox-container.c-checkbox-radio .c-checkmark:before {
       border:solid 2px #949494;
       content: '';
       height: 16px;
       position: absolute;
       width: 16px;
   }
   .c-contact-form .c-checkbox-container.c-checkbox-radio .c-checkmark:after {
       content: '';
       position: absolute;
       top:3px;
       left:3px;
       height: 10px;
       width: 9.5px;
       background-color: #264D7A;
       opacity:0;
       -webkit-transition: opacity 0.3s;
       -moz-transition: opacity 0.3s;
       -ms-transition: opacity 0.3s;
       -o-transition: opacity 0.3s;
       transition:opacity 0.3s;
   }
   
   .c-contact-form .c-checkbox-container.c-checkbox-radio .c-checkmark,
   .c-contact-form .c-checkbox-container.c-checkbox-radio .c-checkmark:before,
   .c-contact-form .c-checkbox-container.c-checkbox-radio .c-checkmark:after {
       border-radius: 50%;
       -webkit-transition: all 0.3s;
       -moz-transition: all 0.3s;
       -ms-transition: all 0.3s;
       -o-transition: all 0.3s;
       transition:all 0.3s;
   }
   
   .c-contact-form .c-checkbox-container input:hover ~ .c-checkmark:before ,
   .c-contact-form .c-checkbox-container input:checked ~ .c-checkmark:before {
       border:solid 2px #264D7A;
   }
   
   .c-contact-form .c-checkbox-container input:checked ~ .c-checkmark:after {
       opacity:1;
       cursor: auto;
   }
   
    .c-contact-form .c-checkbox-container.c-checkbox-radio input:checked {
       cursor: auto;
    }

    .c-contact-form .c-checkbox-container.c-checkbox-radio input:focus ~ .c-checkmark,
    .c-contact-form .c-checkbox-container.c-checkbox-radio input:focus ~ .c-checkmark::before,
    .c-contact-form .c-checkbox-container.c-checkbox-radio input:focus ~ .c-checkmark::after{
        border-color: #b2ce27 !important;
    }
  
   
   /* File upload
      ---- */
   
   .c-contact-form input[type=file] {
       width: 100%;
       height: 50px;
       opacity: 0;
       overflow: hidden;
       position: absolute;
       z-index:9999;
       cursor:pointer;
   }
   
   .c-contact-form input[type=file] + label {
       width: 100%;
       height: 50px;
       padding: 10px;
       background: none;
       border: solid 1px #949494;
       -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
       border-radius: 8px;
   }
   
   .c-contact-form input[type=file]:hover + label ,
   .c-contact-form input[type=file]:active + label ,
   .c-contact-form input[type=file]:focus + label {
       border: solid 1px #b2ce27;
       outline:none;
   }
   
   .c-contact-form .o-input-error + label {
       border: solid 1px #FF0000 !important;
   }
   .c-contact-form .o-input-error[type=file]:focus + label {
        border: solid 1px #b2ce27 !important;
    }
   
   .c-contact-form input[type=file] ~ label ~ span ~ i {
       position:absolute;
       font-size:2.3rem;
       right:10px;
       top:5px;
       background:#fff;
       width:50px;
       height:40px;
   }
   
   .c-contact-form input[type=file] ~ label ~ span ~ i:before {
       position:absolute;
       font-size:2.3rem;
       color:#1b4d80;
       right:10px;
       top:8px;
   }
   
   /* Text and Textarea
      ---- */
   
   .c-contact-form select,
   .c-contact-form textarea,
   .c-contact-form input[type=date],
   .c-contact-form input[type=text],
   .c-contact-form input[type=password] {
       -moz-appearance: none;
       -webkit-appearance: none;
       background:none;
       border: solid 1px #949494;
       -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
       border-radius: 8px;
       max-width: 100%;
       padding: 10px;
       width: 100%;
   }
   
   .c-contact-form input[type=text], .c-contact-form input[type=password] {
       height:50px;
   }
   
   .c-contact-form input:hover,
   .c-contact-form input:active,
   .c-contact-form input:focus,
   .c-contact-form select:hover,
   .c-contact-form select:active,
   .c-contact-form select:focus,
   .c-contact-form textarea:hover,
   .c-contact-form textarea:active,
   .c-contact-form textarea:focus{
       border: solid 1px #A3BF19!important;
       outline:none;
   }
   
   .c-contact-form .o-datepicker:hover ~ span ~ i:before ,
   .c-contact-form .o-datepicker:focus ~ span ~ i:before {
        color: #b2ce27 !important;
   }
   
   .c-contact-form input[type=file] + label {
       border: solid 1px #949494;
       -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
       border-radius: 8px;
       height: 50px;
       padding: 10px;
       width: calc(100% - 2px);
       z-index:800;
   }
   
   .c-contact-form input[type=checkbox] {
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       border-radius: 50%;
   }
   
   .c-contact-form input ~ span ~ i ,
   .c-contact-form select ~ span ~ i {
       position:absolute;
       font-size:2.3rem;
       right:1px;
       top:5px;
       background:#fff;
       width:50px;
       height:40px;
       z-index: 799;
   }
   
   .c-contact-form input ~ span ~ i:before ,
   .c-contact-form select ~ span ~ i:before {
       position:absolute;
       font-size:1.8rem;
       color:#1b4d80;
       right:10px;
       top:11px;
   }
   
   .c-contact-form textarea ~ span ~ i {
       position:absolute;
       right:20px;
       bottom:20px;
       z-index:801;
       opacity:0;
       cursor: text;
   }
   
   .c-contact-form textarea.o-keypress ~ span ~ i {
       opacity:1;
       cursor:pointer;
   }
   
   .c-contact-form textarea.o-input-error ~ span ~ i {
       bottom:45px;
   }
   
   .c-contact-form textarea ~ span ~ i:before  {
       font-size:1.4rem;
       color:#1b4d80;
   }
   
   .c-contact-form textarea ~ span ~ i.c-icon-close {
       position:absolute;
       right:20px;
       bottom:20px;
       z-index:801;
       opacity:0;
       cursor: text;
       margin:0;
       width:15px;
       height:15px;
       background: url(../images/close.svg) no-repeat;
       -webkit-transition: background-image .4s;
       -moz-transition: background-image .4s;
       -ms-transition: background-image .4s;
       -o-transition: background-image .4s;
       transition:background-image .4s;
   }
   
   .c-contact-form textarea.o-keypress ~ span ~ i.c-icon-close {
       opacity:1;
       cursor:pointer;
   }
   
   .c-contact-form textarea.o-input-error ~ span ~ i.c-icon-close {
       bottom:45px;
   }
   
   .c-contact-form textarea ~ span ~ i.c-icon-close:hover {
       background: url(../images/close-hover.svg) no-repeat;
   }
   
   /* Select
      ---- */
   
   .c-contact-form select {
       color: #777;
       font-family: 'Roboto', sans-serif;
       font-size:2rem;
       font-weight:300;
       height:50px;
       position:relative;
       z-index: 800;
   }
   
 
   
   /* On Off 
      ---- */
      
   .c-switch-notice {
       display: block;
       width: 100%;
       position: relative;
   }
   
   .c-switch-notice span {
       font-family: 'Roboto', sans-serif;
       font-size: 2rem;
       color: #222;
       font-weight: 300;
       float:left;
       -webkit-transition: color .4s;
       -moz-transition: color .4s;
       -ms-transition: color .4s;
       -o-transition: color .4s;
       transition:color .4s;
   }
   
   .c-switch-notice .c-switch.is-disabled + span {
       color: #949494;
   }
   
   .c-switch-notice .c-switch {
       position: absolute;
       display: inline-block;
       width: 40px;
       height: 24px;
       margin-top:0;
       left:175px;
   }
   
   .c-switch-notice .c-switch .o-switch {
       position: absolute;
       cursor: pointer;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       background-color: #264D7A;
       border:solid 2px #264D7A;
       border-radius: 28px;
   }
   
   .c-switch-notice .c-switch .o-switch:before {
        background: #fff url(../images/icon-check-blu.svg) no-repeat 50% 50%;
        background-color: #fff;
        border:solid 2px #fff;
        border-radius: 50%;
        bottom: -1px;
        content: "";
        height: 22px;
        position: absolute;
        right: -1px;
        -webkit-transition: transform .4s;
        -moz-transition: transform .4s;
        -ms-transition: transform .4s;
        -o-transition: transform .4s;
        transition:transform .4s;
        width: 22px;
   }
   
   .c-switch-notice .c-switch.is-disabled .o-switch {
       background-color: #949494;
       border:solid 2px #949494;
   }

   .c-switch-notice .c-switch.is-disabled .o-switch:before {
       background: #fff url(../images/cancel-grey.svg) no-repeat 50% 50%;
       -webkit-transform: translate(-16px);
       -ms-transform: translate(-16px);
       transform: translate(-16px);
   }
   .c-switch-notice .c-switch:focus{
       outline-color:#b2ce27!important;
   }
   /* Icons
      ---- */
   
   .c-contact-form select {
       background-image: url(../images/smaller-select.svg);
       background-repeat: no-repeat;
       background-position: center right;
       background-origin: content-box;
       background-size: 19px 8px;
   }
   .c-contact-form input.o-date {
       background-image: url(../images/calendar.svg);
       background-repeat: no-repeat;
       background-position-y: -8px;
       background-position-x: right;
       background-origin: content-box;
       background-size: 25px;
   }
   
   .c-contact-form input.o-pin {
       background-image: url(../images/geo.svg);
       background-repeat: no-repeat;
       background-position-y: -8px;
       background-position-x: right;
       background-origin: content-box;
       background-size: 25px;
   }
   .c-contact-form input[type=file] + label {
       background-image: url(../images/attach.svg);
       background-repeat: no-repeat;
       background-position-y: -5px;
       background-position-x: right;
       background-origin: content-box;
       background-size: 15px;
   }
   
   .c-contact-form .c-form-checkbox.o-input-error:after,
   .c-contact-form .c-form-group.c-form-group-error:after {
       content: '';
       display: block;
       position: absolute;
       width:20px;
       height:20px;
       top: 50%;
       right: 0;
       background-image: url(../images/alert-form.svg);
       background-repeat: no-repeat;
       -ms-transform: translate(25px, -25px);
       -webkit-transform: translate(25px, -25px);
       transform: translate(25px, -25px);
   }
   .c-contact-form .c-form-group.c-upload-group.c-form-group-error:after {
       top: 0;
       -ms-transform: translate(25px, 16px);
       -webkit-transform: translate(25px, 16px);
       transform: translate(25px, 16px);
   }
   .c-contact-form .c-form-group.c-form-group-valid:after {
       content: '';
       display: block;
       position: absolute;
       width:15px;
       height:15px;
       top: 50%;
       right: 0;
       background-image: url(../images/chceck.svg);
       background-repeat: no-repeat;
       -ms-transform: translate(20px, -10px);
       -webkit-transform: translate(20px, -10px);
       transform: translate(20px, -10px);
   }
   
   .c-contact-form .c-form-group.c-form-group-valid input, 
   .c-contact-form .c-form-group.c-form-group-valid select, 
   .c-contact-form .c-form-group.c-form-group-valid textarea {
       border: solid 1px #b2ce27;
   }
   
   .c-attached-file {
       background: #E9EDF2;
       font-size: 1.6rem;
       height: 40px;
       line-height: 40px;
       margin-bottom: 10px;
       margin-top:4px;
       padding:0 20px;
       position:relative;
       width:100%;
    }
    
   .c-attached-file:last-of-type {
       margin-bottom:25px;
   }
   
   .c-upload-group.c-form-group-error .c-attached-file:last-of-type {
       margin-bottom:0px;
   }
   
   .c-attached-file i.c-icon-cancel {
       background-image: url(../images/cancel.svg);
       background-repeat: no-repeat;
       background-position: 15px 15px;
       content: '';
       cursor:pointer;
       display: block;
       height: 40px;
       position: absolute;
       right: 0;
       top: 5px;
       -ms-transform: translate(-20px, -5px);
       -webkit-transform: translate(-20px, -5px);
       transform: translate(-20px, -5px);
       -webkit-transition: background-image .4s;
       -moz-transition: background-image .4s;
       -ms-transition: background-image .4s;
       -o-transition: background-image .4s;
       transition:background-image .4s;
       width: 40px;
   }
   
   .c-attached-file i.c-icon-cancel:hover,.c-attached-file i.c-icon-cancel:focus {
       outline: 0;
       background: url(../images/cancel-hover.svg) no-repeat 15px 15px;
   }

@media screen and (min-width:0\0) and (min-resolution: +72dpi) { /* IE 9 e superiore */
    .c-contact-form select {  background-image: url(../images/select.png); }
    .c-contact-form input.o-pin { background-image: url(../images/geo.png); }
    .c-contact-form input.o-date { background-image: url(../images/calendar.png); }
    .c-contact-form input[type=file] + label { background-image: url(../images/attach.png); } 
     
}   
@media screen and (min-width:0\0) and (min-resolution: .001dpcm)  { /* solo IE 9 */
	.c-contact-form select { background: none;  padding: 10px 10px 10px 20px  }  
}   

   /* Google Recaptcha
      ---- */
      
   .c-recaptcha {
       margin:50px 0;
   }
   
   /* Privacy
      ---- */
      
   .c-privacy {
       font-family: 'Roboto', sans-serif;
       font-size: 1.6rem;
       color:#777777;
       margin:165px 0 0 0;
       padding:0 0 0 50px;
   }

   @media (max-width: 991px) {
       .c-privacy {
           margin: 0 0 20px 0;
       }
   }
   
   .c-privacy a {
       border-bottom: solid 1px #222;
       color: #222;
       text-decoration: none;
       -webkit-transition: border .3s ease-in-out, color .3s ease-in-out;
       -moz-transition: border .3s ease-in-out, color .3s ease-in-out;
       -ms-transition: border .3s ease-in-out, color .3s ease-in-out;
       -o-transition: border .3s ease-in-out, color .3s ease-in-out;
       transition: border .3s ease-in-out, color .3s ease-in-out;
   }
   
   .c-privacy a:hover {
       color: #b2ce27;
       border-bottom: solid 1px #b2ce27;
   }
    .c-privacy-2 {
       margin:20px 0;
    }
   
   /* Submit
      ---- */
   
   .c-contact-form input[type=submit] {
        background: #b2ce27;
        border: none!important;
        border-radius: 0;
        color: #264d7a!important;
        display: inline-block;
        font-family: Roboto,sans-serif;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.5rem;
        margin: 0 25px 0 0;
        min-height: 50px;
        outline: 0;
        padding: 12px 25px;
        position: relative;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        -webkit-transition: background-color .3s;
        transition: background-color .3s;
   }
   
   .c-contact-form input[type=submit]:hover, 
   .c-contact-form input[type=submit]:focus {
        background: #264d7a;
        color: #fff!important;
   }
   
   /* Layout 2
      ---- */
      
   .c-contact-form-layout-2 select {
       padding:0px 20px;
       height:52px
   }
   
   .c-contact-form-layout-2 textarea {
       padding:20px 20px 15px 20px;
   }
   
   .c-contact-form-layout-2 input[type=text] ,
   .c-contact-form-layout-2 input[type=date] ,
   .c-contact-form-layout-2 input[type=password] {
       padding:20px 20px 15px 20px;
       height:52px
   }
   
   .c-contact-form-layout-2 span.o-placeholder {
       top: 12px;
       left: 20px;
       height: 28px;
       line-height: 28px;
       -webkit-transition: font-size .3s, transform .3s;
       -moz-transition: font-size .3s, transform .3s;
       -ms-transition: font-size .3s, transform .3s;
       -o-transition: font-size .3s, transform .3s;
       transition:font-size .3s, transform .3s;
   }
   
   
   .c-contact-form-layout-2 input.is-filled ~ span.o-placeholder, 
   .c-contact-form-layout-2 textarea.is-filled ~ span.o-placeholder, 
   .c-contact-form-layout-2 input:active ~ span.o-placeholder, 
   .c-contact-form-layout-2 input:focus ~ span.o-placeholder, 
   .c-contact-form-layout-2 textarea:active ~ span.o-placeholder, 
   .c-contact-form-layout-2 textarea:focus ~ span.o-placeholder,
   .c-contact-form-layout-2 input[type=file].is-filled ~ span.o-placeholder {
       -ms-transform: translate(0, -25px);
       -webkit-transform: translate(0, -25px);
       transform: translate(0, -25px);
       font-size:1.6rem;
   }
   
   .c-contact-form-layout-2 input[type=file]:active ~ span.o-placeholder,
   .c-contact-form-layout-2 input[type=file]:focus ~ span.o-placeholder {
       font-size:2rem;
   }
   
   .c-contact-form-layout-2 input[type=file],
   .c-contact-form-layout-2 input[type=file] + label {
       border: none;
       height:52px;
   }
   
   .c-contact-form-layout-2 input[type=file] + label {
        border: 1px solid #949494;
        margin-bottom:0;
        padding:16px 20px 0 20px;
   }
   
   .c-contact-form-layout-2 .c-form-checkbox {
       min-height: 52px;
   }
   
   .c-contact-form-layout-2 .c-privacy span.o-message, 
   .c-contact-form-layout-2 .c-recaptcha span.o-message, 
   .c-contact-form-layout-2 .c-form-checkbox span.o-message, 
   .c-contact-form-layout-2.c-contact-form .c-form-group span.o-message {
       margin-top:9px;
   }
   
   .c-contact-form-layout-2.c-contact-form .c-form-group textarea + span + span.o-message ,
   .c-contact-form-layout-2.c-contact-form .c-form-group input[type=file] + label + span + span.o-message {
       margin-top:0;
       -ms-transform: translate(0, -3px);
       -webkit-transform: translate(0, -3px);
       transform: translate(0, -3px);
   }
   
   .c-contact-form-layout-2 .c-upload-group-layout-2 input[type=file] + label {
       background: none;
       padding: 14px 120px 0 20px;
       // overflow: hidden;
       text-overflow: ellipsis;
       white-space: nowrap;
   }
   
   .c-contact-form-layout-2 .c-upload-group-layout-2 input[type=file] + label:after {
       border: 1px solid #b2ce27;
       border-top-right-radius: 8px;
       border-bottom-right-radius: 8px;
       background: #b2ce27;
       color: #264d7a!important;
       content: 'Allega';
       display: block;
       font-family: Roboto,sans-serif;
       font-size: 1.4rem;
       font-weight: 700;
       height: 52px;
       outline: 0;
       padding: 12px 0;
       position: absolute;
       right: -3px;
       text-align: center;
       text-decoration: none;
       text-transform: uppercase;
       top: -1px;
       -webkit-transition: background-color .3s, border-color .3s;
       transition: background-color .3s, border-color .3s;
       width: 90px;
   }
   
   .c-contact-form-layout-2 .c-upload-group-layout-2 input[type=file]:hover + label:after {
        background: #264d7a;
        border-color: #264d7a;
        color: #fff!important;
   }
   
   
   @media (max-width: 992px) {
       
       .c-contact-form {
           padding: 50px 10px 0 10px;
           border:none;
       }
   
       .c-contact-form:before {
        //    left: 50%;
        //    -ms-transform: translate(-50%, -60px);
        //    -webkit-transform: translate(-50%, -60px);
        //    transform: translate(-50%, -60px);
        position: relative;
        transform: translate(-9px,-33px);
       }
   
       .c-privacy span.o-message-error, 
       .c-recaptcha span.o-message-error, 
       .c-form-checkbox.o-input-error span.o-message, 
       .c-contact-form .c-form-group span.o-message-error {
           margin-bottom:0;
       }
   
       .c-form-checkbox-grid .c-checkbox-container {
           width: 100%;
           float: none;
       }
   
       .c-contact-form .c-form-checkbox {
           padding: 0;
       }
       
       .c-contact-form .c-form-checkbox.c-form-checkbox-grid {
           margin-bottom:25px;
       }
       
       .c-contact-form .col-md-offset-right-6 {
         margin-right: 0;
       }
   
       .c-privacy {
           padding: 0;
       }
   
       /* Datepicker
          ---- */
   
       .c-calendar {
           margin: -25px 0 25px;
       }
   
       .daterangepicker .calendar th, 
       .daterangepicker .calendar td {
           font-size: 1.4rem !important;
       }
       
       .table-condensed>tbody>tr>td, 
       .table-condensed>tbody>tr>th, 
       .table-condensed>tfoot>tr>td, 
       .table-condensed>tfoot>tr>th, 
       .table-condensed>thead>tr>td, 
       .table-condensed>thead>tr>th {
           padding: 5px !important;
       }
   
   }
   
   
    @media (max-width: 767px) {
	//    .c-contact-form:before {
	// 		   left: 30px;
	// 		   -ms-transform: translate(0, -60px);
	// 		   -webkit-transform: translate(0, -60px);
	// 		   transform: translate(0, -60px);
    // 	   }
        .c-contact-form:before {
            font-size: 2rem;
        }
    }
    
   
   /* Datepicker
      ---- */
    .calendar-table > table {
        border-collapse: separate;
    }
      
   .c-calendar{
       text-align: center;
   }
   
   .c-calendar i {
       font-size: 2rem;
       line-height: 50px;
       cursor:pointer;
   }
   .c-calendar i:before {
       color: #1a4e7d;
   }
   
   .daterangepicker.dropdown-menu {
       z-index: 100000;
   }
   
   .daterangepicker.ltr {
       -webkit-transform: translate(0, -30px);
       -moz-transform: translate(0, -30px);
       -ms-transform: translate(0, -30px);
       -o-transform: translate(0, -30px);
       transform: translate(0, -30px);
       border: none !important;
       -moz-box-shadow: #949494 0 2px 3px;
       -webkit-box-shadow: #949494 0 2px 3px;
       box-shadow: #949494 0 2px 3px;
   }
   
   .daterangepicker:before {
       display:none !important;
   }
   
   .daterangepicker.dropup:after {
       background: #fff !important;
       border:none !important;
       bottom: 0px !important;
        -moz-box-shadow: #949494 -2px 2px 3px;
        -webkit-box-shadow: #949494 -2px 2px 3px;
        box-shadow: #949494 -2px 2px 3px;
       content: ''  !important;
       display: block  !important;
       height: 30px  !important; 
       position: absolute !important;
       top:100% !important;
       -webkit-transform: translate(0, -15px) rotate(-45deg);
       -ms-transform: translate(0, -15px) rotate(-45deg);
       -o-transform: translate(0, -15px) rotate(-45deg);
       transform: translate(0, -15px) rotate(-45deg);
       width: 30px  !important;
       z-index:-1;
   }
   .daterangepicker.dropup:before {
       background: #fff !important;
       bottom: 0px !important;
       border: none;
       content: ''  !important;
       display: block  !important;
       height: 11px  !important; 
       position: absolute !important;
       width: 50% !important;
       z-index: +1;
   }
   
   .daterangepicker td.active, 
   .daterangepicker td.active:hover {
       color:#fff !important;
       background-color: #264D7A !important;
   }
   
   .daterangepicker td.in-range,
   .daterangepicker td.available:hover, 
   .daterangepicker th.available:hover {
        background-color: #fff !important;
        border: 1px solid #949494;
        color: #222 !important;
   }

   .daterangepicker td.off:hover {
        color: #777 !important;
   }
   
   .daterangepicker .calendar th, 
   .daterangepicker .calendar td {
       font-size: 1.6rem;
   }
   
   .table-condensed>tbody>tr>td, 
   .table-condensed>tbody>tr>th, 
   .table-condensed>tfoot>tr>td, 
   .table-condensed>tfoot>tr>th, 
   .table-condensed>thead>tr>td, 
   .table-condensed>thead>tr>th {
       padding: 10px;
   }
   
   .daterangepicker .calendar {
       max-width: inherit !important;
   }
   
   .daterangepicker select.monthselect ,
   .daterangepicker select.yearselect {
       background: #fff;
       border-radius: 4px;
       color: #222;
       font-size: 1.6rem !important;
       font-weight:400;
   }
   
   .daterangepicker_input {
       display:none;
   }
   
   .daterangepicker .calendar th {
       color: #264d7a;
   }
   
   .daterangepicker .calendar th.prev,
   .daterangepicker .calendar th.next,
   .daterangepicker .calendar th.month,
   .daterangepicker .calendar td.available {
       color: #222;
   }
   
   .daterangepicker td.available.off, 
   .daterangepicker td.available.off.in-range, 
   .daterangepicker td.available.off.start-date, 
   .daterangepicker td.available.off.end-date {
       color: #777;
   }
   
   @media (min-width: 564px) {
   
       .daterangepicker.ltr .calendar.right {
           margin-left: 15px !important;
       }
       .daterangepicker.ltr .calendar.left {
           margin-right: 15px !important;
       }
       .daterangepicker.ltr .calendar.left.single {
           margin-right: 0 !important;
       }
   
   }
   
   
   .c-contact-form input, 
   .c-contact-form textarea, 
   .c-contact-form label, 
   .c-contact-form .c-form-checkbox { 
       color: #222
   }
   
   .c-contact-form .c-disabled-input ,
   .c-contact-form .c-disabled-input input ,
   .c-contact-form .c-disabled-input label {
       cursor: url(../images/stop.png), auto;
   }
   
   .c-contact-form .c-disabled-input label {
       color: #949494;
   }
   
   .form-control:focus {
     -webkit-box-shadow: none !important;
     box-shadow: none !important;
   }
   
   input:-webkit-autofill,
   input:-webkit-autofill:hover, 
   input:-webkit-autofill:focus
   textarea:-webkit-autofill,
   textarea:-webkit-autofill:hover
   textarea:-webkit-autofill:focus,
   select:-webkit-autofill,
   select:-webkit-autofill:hover,
   select:-webkit-autofill:focus {
     -webkit-box-shadow: inset 0 0 0px 9999px rgba(255,255,255,0.1);
   }

input[type=text]::-ms-clear {
    display: none;
}

#contattaci .c-privacy {
    margin: 0 0 20px;
    padding: 0;
}