.nopaddtop { padding-top: 0 }

.c-breadcrumb {
	background: #f1f1f1;
}

.c-breadcrumb ul {
	list-style-type: none;
	margin: 0;
	font-size: 1.7rem;
	padding: 0
}

.c-breadcrumb ul li { 
	float: left;
	padding: 10px 20px;
}
.c-breadcrumb ul li a {
	text-decoration: none;
	border-bottom:solid 1px #333;
    -webkit-transition: border-color .3s ease-in-out, color .3s ease-in-out;
    -moz-transition: border-color .3s ease-in-out, color .3s ease-in-out;
    -ms-transition: border-color .3s ease-in-out, color .3s ease-in-out;
    -o-transition: border-color .3s ease-in-out, color .3s ease-in-out;
    transition: border-color .3s ease-in-out, color .3s ease-in-out;
}

.c-breadcrumb ul li a:hover{
	color: #b2ce27;
	border-color: #b2ce27
}

.c-breadcrumb ul li:nth-child(2) {
	background: #fcfbfb
}

.c-breadcrumb ul li:last-child {
	background: #fff
}
.c-breadcrumb ul li:first-child {
	background: url("../images/casetta-piena.svg") no-repeat left center ;
	padding-left: 20px
}
/*.c-breadcrumb ul li:first-child:before {
	font-family: 'digitalux-icon-fontregular';
	content: "\e978";
	color: red
}*/
.c-breadcrumb a {
	color: #222;
}

@media (max-width: 576px) {

	.c-breadcrumb-container {
		display:none
	}

}
