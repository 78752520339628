// .c-table-dati {
// 	margin-top: 30px;
// 	margin-bottom: 30px;
// }

.c-table-dati table {
	width: 100%
}

.c-table-dati td {
	width: 13.5%;
	padding: 10px 8px;
	vertical-align: top;
	/* overflow: hidden;
   	text-overflow: ellipsis;
   	display: inline-block;
   	white-space: nowrap; */
   	max-width: 100%;
   	line-height: 1.3;
}
.c-table-dati td:last-child {
	width: auto;
	text-align: center;
	padding-left: 0;
	padding-right: 0;
}

.c-table-dati thead {
	border-bottom: 1px solid #264d7a;
	color: #264d7a;
	text-transform: uppercase
}


.popover{
    max-width: 40%; /* Max Width of the popover (depending on the container!) */
    padding: 10px;
	z-index: 10 !important;
}

.text-center {
	text-align: center;
}

.table-responsive {
	overflow-y: hidden;
}

@media (max-width: 768px) {
	.popover{
	    max-width: 80%;
	}

	.table-responsive {
		border: none;
	}
}

.popover.left {
	border-radius: 10px;
	box-shadow: 0 2px 3px #949494;
	margin-left: -23px;
	padding: 0;
	z-index: 1;
}
.popover.left .arrow:before {
	background-color: #fff;
    border: none;
    box-shadow: 0 2px 3px #949494;
    content: "";
    height: 20px;
    position: absolute;
    right: 0;
    top: calc(50% - 12px);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 20px;
}
.popover.left .arrow:after {
    content: none !important;
}
.popover.left:after {
	background-color: #fff;
	border: none;
	border-radius: 11px;
    content: "";
    height: 100%;
    position: absolute;
    right: -1px;
    top: 0;
    width: 20px;
}

.popover-title,
.popover-content {
	font-family: 'Roboto', sans-serif !important;
	font-size: 1.4rem !important;
	font-weight: 400;
	padding: 15px 10px;
	position: relative;
	z-index: 1;
}
.popover-title { 
	background: #fff;
	border: none;
}
.popover-title strong {
	font-weight: 700;
}
.popover-content {
	padding-bottom: 25px;
}
.o-table-popover {
	width: 100%;
	font-weight: 400
}
.o-table-popover td:first-child {
	width: 40%;
	color: #264d7a;
	border-right: 2px solid #264d7a;
}
.o-table-popover td:last-child {
	padding-left: 15px;
	width: 60%;
}
.o-table-popover td {
	padding: 5px
}

@media screen and (min-width: 1200px) {

	.c-table-dati td {
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}

}