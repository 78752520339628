// .c-table-dati {
// 	margin-top: 30px;
// 	margin-bottom: 30px;
// }

.c-table-dati table {
	width: 100%
}

.c-table-dati a ,
.c-table-dati a:hover {
	border-bottom:none
}

.c-table-dati td {
	width: 13.5%;
	padding: 10px 8px;
	vertical-align: top;
	/* overflow: hidden;
   	text-overflow: ellipsis;
   	display: inline-block;
   	white-space: nowrap; */
   	max-width: 100%;
   	line-height: 1.3;
}
.c-table-dati td:last-child {
	width: auto;
	text-align: center;
	padding-left: 0;
	padding-right: 15px;
}

.c-table-dati thead {
	border-bottom: 1px solid #264d7a;
	color: #264d7a;
	text-transform: uppercase
}


.popover{
    max-width: 40%; /* Max Width of the popover (depending on the container!) */
    padding: 10px;
    border: 1px solid #f5f5f5
}
.popover.left .arrow { border-left-color: #f5f5f5 }

.text-center {
	text-align: center;
}

@media (max-width: 768px) {
	.popover{
	    max-width: 80%;
	}

	.table-responsive {
		border: none;
	}
}
@media (max-width: 480px) {
	.popover{
	    max-width: calc(100% - 30px);
	    left: 25px !important
	}

}

.popover-title, .popover-content {
	font-family: 'Roboto', sans-serif !important;
	font-size: 1.4rem !important;
	font-weight: 300
}
.popover-title { 
	background: #fff;
	padding-top:30px
}
.o-table-popover {
	width: 100%;
	font-weight: 400
}
.o-table-popover td:first-child {
	width: 40%;
	color: #264d7a;
	border-right: 2px solid #264d7a;
}
.o-table-popover td:last-child {
	width: 60%
}
.o-table-popover td {
	padding: 5px
}

@media screen and (min-width: 1200px) {

	.c-table-dati td {
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
	}

}