body.is-scrolling.noscroll {
    padding-top: 0px;
}
body.is-scrolling.noscroll .c-note-head {
    display: block;
}


body.is-login { background: #4d4d4f }
body.is-login .container-white, body.is-login .l-header { background: #fff }


.login { 
   background: url("../images/reticolo_altaRis.jpg") no-repeat;
	font-size: 1.6rem;
    font-weight: 300;
}
.login .o-logo {
	max-width: 330px;
  margin: 0 auto;
  width: 100%;
}

.c-avvisi .interno {
	background: rgba(255, 255, 255, 0.95);
	padding: 70px;
	height: 100%;
}
.c-avvisi h3 {
	color: #264d7a;
    border-bottom: 1px solid #264d7a;
    font-size: 2.3rem;
    position: relative;
    padding: 10px 0;
    margin-bottom: 30px;
}
.c-avvisi h3:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20%;
    height: 3px;
    background-color: #264d7a;
}

.c-login {
	background: rgba(255, 255, 255, 0.95);
	padding: 50px 0px;
	text-align: center;
}

.c-login a, .c-login-form a {
    border-bottom: 1px solid #264d7a;
    color: #264d7a;
    text-decoration: none;
    -webkit-transition: color .3s ease-out, border .3s ease-out;
    transition: color .3s ease-out, border .3s ease-out; 
    word-break: break-all;
}
.c-login a:hover, .c-login-form a:hover {
	  color: #a3bf19;
    border-bottom: 1px solid #a3bf19;
}


.c-login .c-contact-form:before {
    content: '';
    display: none;
    background-image: none;
    
}
.c-login .c-contact-form {
   padding: 0px 0px;
    border: 0px solid #264d7a;
    margin: 50px 0;
}

.c-login .c-contact-form input[type=submit] {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100% !important;
    color: #fff;
    margin-top: 40px;
}

.c-login .c-contact-form .c-form-group {
    margin: 0 0 15px 0;
}
.c-login.c-login-2 .c-contact-form .c-form-group {
    margin: 0 0 30px 0;
}

.c-login h2 {
	color: #bcbec0;
	font-size: 2rem;
	border-bottom: 1px solid #bcbec0;
	padding: 20px 0;
	margin: 10px 70px;
}

.c-avvisi .o-avviso {
	background: url("../images/icon-lamp-blu.png") no-repeat left center;
	padding-left: 140px;
    color: #264d7a;	
    min-height: 120px;
}
.c-avvisi .o-avviso .o-titoletto {
	font-weight: 400;
    color: #264d7a;
    margin: 0
}
.c-avvisi .o-avviso .o-testo {
	text-transform: uppercase;
	color: #264d7a;
}
.c-avvisi .o-avviso .o-scopri {
	font-weight: 400;
    color: #264d7a;
    text-align: center
}
.c-avvisi .o-avviso .o-scopri a {
	color: #264d7a;
	}
.c-avvisi .o-avviso .o-scopri a:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #b5d20c;
    border-right: 2px solid #b5d20c;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .3s;
    transition: all .3s;
    margin-right:10px
}


.c-main-logo.c-login-logo img { margin-left: 50px }

.c-login-form {
	font-size: 1.6rem;
	font-weight: 300
}
.c-login-form .o-message, .c-contact-form-layout-2  .o-message {
	font-family: 'Roboto slab', serif;
	font-weight: 400;
}
.c-login-form .marginbottom70, .c-contact-form-layout-2  .marginbottom70 {
	/*font-size: 20px;*/
	margin-bottom: 70px !important;
}
.c-login-form .marginbottom30, .c-contact-form-layout-2  .marginbottom30{
	margin-bottom: 30px !important;
}

form.c-contact-form.c-login-form.c-login-sicurezza p.o-message.marginbottom30 {
	font-size: 20px;
}

.fs-18 {
	font-size: 18px;
}

.c-contact-form.c-login-form:before {
    /*background-size: 100px;
    padding: 16px 10px 58px 140px;*/
    text-transform: uppercase;
    font-weight:400
}

.c-contact-form.c-login-strong:before {
    content: 'Creazione GBI-ID';
    background-image: url("../images/icona_sicurezza.svg");
}

.c-contact-form.c-login-sicurezza:before {
    /*content: 'Sicurezza';*/
    background-image: url("../images/icona_sicurezza.svg");
}

.c-contact-form.c-login-form .c-checkbox-container.c-checkbox-radio {
   text-align: left;
    color: #222;
    height: auto;
    margin-bottom: 50px
}
.c-contact-form.c-login-form .c-checkbox-container.c-checkbox-radio span, .c-contact-form.c-contact-form-layout-2 .c-checkbox-container.c-checkbox-radio span {
	font-size: 1.6rem
}

.c-contact-form.c-login-form .c-checkbox-container input {
    top: 6px;
}

.c-login-form p.testogrande {
	font-size: 3rem
}

.c-login-form .o-icon-lamp,  .c-contact-form-layout-2 .o-icon-lamp  {
	padding: 10px 0 10px 120px;
	position: relative;
	line-height: 2.8rem;
}
.c-login-form .o-icon-lamp:before,  .c-contact-form-layout-2 .o-icon-lamp:before  {
	    content: '';
    background-color: #c0d751;
    background-image: url(../images/lamp-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    width: 100px;
    height: 100%;
    position: absolute;
    left: 0;
    top:0
}

.c-login-form .o-message-error{
	font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    color: #af5f64;
    margin: 5px 0 0 5px
}


.c-login-form .c-form-group {
	position: relative
}
.c-login-form .c-form-group .o-occhio, .c-contact-form-layout-2 .c-form-group .o-occhio {
	right: 14px;
    position: absolute;
    top: 17px;
    z-index: 2000;
}
.c-login-form .c-form-group .o-occhio, .c-contact-form-layout-2 .c-form-group .o-occhio {
	content: url("../images/icon-occhio.svg");
	    width: 34px;
}


.o-barracaricamento, .o-barracaricamento2 {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 50px;
}
.o-barracaricamento div, .o-barracaricamento2 div {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #bcbec0;
  animation: o-barracaricamento 2s linear infinite;
  animation-direction: alternate;
}
.o-barracaricamento2 div {
  animation: o-barracaricamento2 2s linear;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.o-barracaricamento div:nth-child(1), .o-barracaricamento2 div:nth-child(1) {
  top: 0px;
  left: 0px;
  animation-delay: 0s;
}
.o-barracaricamento div:nth-child(2), .o-barracaricamento2 div:nth-child(2) {
  top: 0px;
  left: 42px;
  animation-delay: 0.1s;
}
.o-barracaricamento div:nth-child(3), .o-barracaricamento2 div:nth-child(3) {
  top: 0px;
  left: 84px;
  animation-delay: 0.2s;
}
.o-barracaricamento div:nth-child(4), .o-barracaricamento2 div:nth-child(4) {
  top: 0px;
  left: 126px;
  animation-delay: 0.3s;
}
.o-barracaricamento div:nth-child(5), .o-barracaricamento2 div:nth-child(5) {
  top: 0px;
  left: 168px;
  animation-delay: 0.4s;
}
.o-barracaricamento div:nth-child(6), .o-barracaricamento2 div:nth-child(6) {
  top: 0px;
  left: 210px;
  animation-delay: 0.5s;
}
.o-barracaricamento div:nth-child(7), .o-barracaricamento2 div:nth-child(7) {
  top: 0px;
  left: 252px;
  animation-delay: 0.6s;
}
.o-barracaricamento div:nth-child(8), .o-barracaricamento2 div:nth-child(8) {
  top: 0px;
  left: 294px;
  animation-delay: 0.7s;
}
@keyframes o-barracaricamento {
  0%, 25%, 66%, 100% {
    background: #bcbec0;
  }
  26%, 65% {
    background: #b2ce27
  }
}

@keyframes o-barracaricamento2 {
   0%, 50% {
    background: #bcbec0;
  }
  51%, 100% {
    background: #b2ce27
  }
}

.c-login-form {
  width: auto !important;
  margin: 50px 70px !important;
}

@media (max-width: 480px) {
  

  .c-login-form {
    width: auto !important;
    margin: 50px 30px !important;
  }

.o-barracaricamento div, .o-barracaricamento2 div {
  width: 20px;
  height: 20px;
  
}
.o-barracaricamento div:nth-child(2), .o-barracaricamento2 div:nth-child(2) {
  left: 30px;
}
.o-barracaricamento div:nth-child(3), .o-barracaricamento2 div:nth-child(3) {
  left: 60px;
}
.o-barracaricamento div:nth-child(4), .o-barracaricamento2 div:nth-child(4) {  
	left: 90px;
}
.o-barracaricamento div:nth-child(5), .o-barracaricamento2 div:nth-child(5) {
  left: 120px;
}
.o-barracaricamento div:nth-child(6), .o-barracaricamento2 div:nth-child(6) {
  left: 150px;
}
.o-barracaricamento div:nth-child(7), .o-barracaricamento2 div:nth-child(7) {
  left: 180px;
}
.o-barracaricamento div:nth-child(8), .o-barracaricamento2 div:nth-child(8) {
  left: 210px;
}
}

@media (min-width:992px){
	.login .prelative { position: relative }
	.login .c-avvisi {
		position: absolute;
    	top: 150px;
    	height: auto;
    	margin: 0;
	} 
	.c-avvisi .interno {
		box-shadow: 0px 0px 9px #bcbec0;
	}
	.c-login {	
    	padding: 150px 0px;
	}
	.c-avvisi .o-avviso {
    	position: absolute;
    	bottom: 70px;
    	margin-right: 70px;
	}
}

@media (max-width: 1199px){
  .login .o-logo {
    max-width: none;
    padding: 0 70px;
  }
}

@media (max-width: 991px){
  .c-login .c-contact-form input[type=submit] {
    left: 0;
    transform: none;
  }
}

@media (max-width:480px){
  .login .o-logo {
    max-width: none;
    padding: 0 30px;
  }

	.c-login h2 {
    	margin: 10px 30px;
	}
  /*
	.c-login .c-contact-form {
    	padding: 0px 30px;
	}
  */
	.c-avvisi .interno {
    	padding: 30px;
	}
}


/*.c-contact-form input, .c-contact-form textarea, .c-contact-form label, .c-contact-form .c-form-checkbox { color: #222 }
.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0px 9999px rgba(255,255,255,0.1);
}*/

