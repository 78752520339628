.o-square-pantone {
	color: #4D4D4F;
}

.o-square-pantone:before {
	content: '';
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 10px;
}

.o-square-pantone.blu-istituzionale:before {
	background-color: #1d388e;
}
.o-square-pantone.verde-istituzionale:before {
	background-color: #00954b;
}
.o-square-pantone.grigio-istituzionale:before {
	background-color: #868685;
}

.c-materiali-brandizzati { line-height: 1.2em; }
.c-materiali-brandizzati .row { margin-bottom: 50px }

.c-materiali-brandizzati .row img { float: right; }
    
@media (max-width: $media-xs) {

    .c-materiali-brandizzati .row img { margin: 10px 0 }

}
