#swipebox-top-bar {
    background: none;
    font-family: 'Roboto slab', serif;
    font-size: 2rem;
    font-weight: 100;
    text-shadow: none;
}
#swipebox-top-bar.visible-bars, #swipebox-bottom-bar.visible-bars  {
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
}
#swipebox-overlay {
	background: rgba(34,34,34, .8);
}
#swipebox-bottom-bar {
    bottom: auto;
    top: 50%;
    background: none;
	/*transform: translateY(-50%);*/
	height: 0px;
}
#swipebox-bottom-bar, #swipebox-arrows { height: 0px; }

#swipebox-top-bar {
    top: 0px;
}

@media (max-width:480px) {
	#swipebox-bottom-bar {
		top: auto;
		bottom: 5%;
		width: 95%;
		left: 2.5%;
	}
}

@media screen and (min-width: 800px) {
	#swipebox-arrows {
	    width: 95%;
	    max-width: 95%;
	}
	#swipebox-close {
    	right: 30px;
    	top: 30px;
	}
	#swipebox-top-bar, #swipebox-top-bar.visible-bars {
		top: 0%;
		/*transform: translateY(-460px);*/
	}
	#swipebox-bottom-bar, #swipebox-bottom-bar.visible-bars {
		top: 50%;
		transform: translateY(-50%)
	}
}

#swipebox-prev,
#swipebox-next {
  text-decoration: none;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius:50px;
  position: relative;
  z-index: 10;
  opacity: 1;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  font-size:0;
  border:none !important;
}

    

#swipebox-prev:after,
#swipebox-next:after {
  content: '';
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius:50px;
  background:#f1f1f1;
  -webkit-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

#swipebox-prev:before ,
#swipebox-next:before {
  content: '';
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  z-index:998;
  background: #f1f1f1 url(../images/dx_single.svg) no-repeat 4px 0 !important;
}


// #swipebox-prev, #swipebox-next {
//   background:#f1f1f1;
//   text-align:center;
// }

#swipebox-prev {
	transform: rotate(180deg) translateY(50%);
}
#swipebox-next {
	transform: translateY(-50%);
}

/*#swipebox-prev,
#swipebox-next {
	background: #f1f1f1 url(../images/dx_single.svg) no-repeat center !important;
    background-size: 12px !important;
    
}
#swipebox-prev:after, #swipebox-next:after {
  content: '';
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius:50px;
  background:#f1f1f1;
  -webkit-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}


#swipebox-prev:hover,
#swipebox-next:hover {
-ms-transform: scale(1.3, 1.3);
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
  
#swipebox-prev {
	transform: rotate(180deg)
}*/

#swipebox-close {
  background-image: none;
}

#swipebox-close:before {
    position: absolute;
    content: '';
    width: 4px;
    top: 50%;
    height: 45px;
    left: 50%;
    background-color: #ffffff;
    border: 0;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}
#swipebox-close:after {
    position: absolute;
    content: '';
    width: 4px;
    height: 45px;
    top: 50%;
    left: 50%;
    background-color: #ffffff;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

#swipebox-slider .slide img { max-height: 85%; max-width: 85%; }

// @media (max-width: 480px) {
//   #swipebox-next:before,
//   #swipebox-prev:before,
//   #swipebox-next:after,
//   #swipebox-prev:after {
//     display: none;
//   }

//   #swipebox-prev {
//     background: #f1f1f1 url(../images/swipebox-prev-arrow.png) no-repeat -2px 0 !important;
//     background-image: url(../images/swipebox-prev-arrow.png) !important;
//     transform: translateY(-50%);
//     transition: none !important;
//   }
  
//   #swipebox-next {
//     background: #f1f1f1 url(../images/swipebox-next-arrow.png) no-repeat 0 0 !important;
//     background-image: url(../images/swipebox-next-arrow.png) !important;
//     transition: none !important;
//   }
// }

@media (min-width: 992px) {
  #swipebox-prev:hover:after,
  #swipebox-next:hover:after {
    border:none;
    -ms-transform: scale(1.3, 1.3);
    -webkit-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }
}