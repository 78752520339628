.c-login-layout .c-faqs-container {
	margin-top:25px;
}

.c-login-layout .c-faqs {
	text-align:left;
}

.c-login-layout .c-faqs-icon ,
.c-login-layout .c-faqs-desc {
	float:left;
}

.c-login-layout .c-faqs-icon img {
	height: auto;
	max-width: 100px;
	width: 100%;
}

.c-login-layout .c-faqs-desc {
	margin-left:15px;
}

.c-login-layout .c-faqs a.c-right-button {
	font-size:1.6rem;
}

// .c-login-layout .c-faqs a.c-right-button:before {
//     top: 8px;
//     width: 8px;
//     height: 8px;
// }

@media (max-width: 991px) {
	
	.c-login-layout.login .c-avvisi {
		margin: 100px 0;
	}

}