.menuinterno .container { padding: 0}
.menuinterno .row { margin: 0}

.menuinterno .c-scroll-content {
	padding: 0
}

.menuinterno .c-scroll-content ul {
    padding: 0 !important;
}
.menuinterno .tab-content {    margin-bottom: -10px; }
.menuinterno .container { width: 100% }

.menuinterno .panel {
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0); 
    box-shadow: 0 1px 1px rgba(0,0,0,0); 
}

.menuinterno .panel:last-child .o-menu-border {
	border: 0px !important
}

.menuinterno .panel {
	margin: 0;	
	font-size: 2rem; 
}

.menuinterno .panel .o-menu-border {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 40px;
	border-bottom: 1px solid #f1f1f1
}
.menuinterno .panel .sel, .borderbottom {
	border-bottom: 1px solid #f1f1f1
}
.menuinterno .panel .sel .o-menu-border, .borderbottom .o-menu-border {
	border: 0px !important
}

.menuinterno .panel li .o-menu-border {
	padding-left: 80px;
}
.menuinterno .panel li li .o-menu-border {
	padding-left: 100px;
}


.menuinterno .panel .freccia { 
	padding: 10px 0; 
	line-height: 5rem; 
	text-align: center 
}


.menuinterno li .o-menu-border a {
    color: #264d7a;
    text-decoration: none;
    position: relative
}
.menuinterno li .o-menu-border a:hover:after {
	content: '';
    height: 2px;
    width: 100%;
    border: 1px solid #264d7a;
    position: absolute;
    left: 0;
    bottom: -4px;
}


.menuinterno li li .o-menu-border a {
	color: #000;
	text-decoration: none
}
.menuinterno li li .o-menu-border a:hover:after {
	border: 0px
}


.menuinterno li li .sel { font-weight: bold !important; background:#eaf1c3; }
.menuinterno .selbold { font-weight: bold !important; }

.c-main-content .o-button {
	text-transform: uppercase;
	background: #b2ce27;
	-webkit-transition: background .3s ease-in-out;
	-moz-transition: background .3s ease-in-out;
	-ms-transition: background .3s ease-in-out;
	-o-transition: background .3s ease-in-out;
	transition: background .3s ease-in-out;
	margin: 10px 0 20px 0;
	float: left;
}
.c-main-content .o-button:hover {
	background: #204d7c;
	color: #fff !important
}


.menuinterno .panel .freccia {
	/*border-bottom: 1px solid #fff;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;*/
	padding-bottom: 2px !important
}

.menuinterno .panel .freccia:hover {
	border-color: #204d7c
}

.menuinterno .panel .freccia a{
	font-size: 0;
    content: '';
    display: block;
	padding:0;
	height: 40px;
    width: 80%;
    margin-left: 10%;
    border: none;
    background: transparent;
    z-index: 900;
    position: relative
}

.menuinterno .panel .freccia a span {
    position: absolute;
    content: '';
    top: 0;
	font-size:0;
}

.menuinterno .panel .freccia a:before {
	content: '';
    width: 0;
    height: 0;
    background: #e7eaec;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transition: all .2s ease-in-out;
}

.menuinterno .panel .sel .freccia a:before {
	background: #fff
}


.menuinterno .panel .freccia a:hover:before {
    width: 44px;
    height: 44px;
    left: 50%;
    top: 50%;
    margin: -22px;
	-ms-transform: scale(0.8, 0.8); /* IE 9 */
    -webkit-transform: scale(0.8, 0.8); /* Safari */
    transform: scale(0.8, 0.8);
}

.menuinterno .panel .freccia a:after{
	/*position:absolute;*/
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: url("../../assets/images/freccia-bot-blu.png");
	width: 14px;
	height: 45px;
}
.menuinterno .panel .freccia a.collapsed:after {
    /* symbol for "collapsed" panels */
    content: url("../../assets/images/freccia-dx-blu.png");  
    color: #bfd64c;   /* adjust as needed, taken from bootstrap.css */
}

.menuinterno .o-scroll-close:after, .menuinterno .o-scroll-close:before {
    height: 35px;
}