/* No found
   ---- */

.c-no-found-item {
	display:none;
}

.c-no-found-item .o-icon-error-mini {
	margin:0 -15px 50px -15px
}

/* Filter form
   ---- */

.c-archivio-filter {
    padding: 50px;
	margin:50px 0;
    outline: 1px solid #b2ce27;
    position: relative;
    width: 100%;
    display: block;
}

.c-archivio-filter:before {
    content: 'FILTRA PER';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 10px 0 10px;
    font-size: 2.5rem;
    color: #b2ce27;
    background-color: white;
    -ms-transform: translate(-2px, -36px);
    -webkit-transform: translate(-2px, -36px);
    transform: translate(-2px, -36px);
}

.c-archivio-filter:after {
    content: '';
    display: block;
    clear: both;
}

/* General
   ---- */
   
.c-archivio-filter input:active,
.c-archivio-filter input:focus,
.c-archivio-filter input:hover {
	outline:none;
}

/* Group
   ---- */

.c-archivio-filter .c-form-group {
	width: 100%; 
	margin:0;
	text-align:left;
	position:relative;
}

.c-archivio-filter .c-form-group-error .c-form-group {
	margin:0;
}

/* Inputs
   ---- */

.c-archivio-filter input,
.c-archivio-filter label {
    font-family: 'Roboto', sans-serif;
	font-size:2rem;
	color:#9fa0a1;
	font-weight:300;
	position:relative;
    z-index: 800;
    width: 100%;
    padding: 10px;
    background: none;
    border: solid 1px #d4d7d9;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.c-archivio-filter input:-webkit-autofill,
.c-archivio-filter input:-webkit-autofill:hover,
.c-archivio-filter input:-webkit-autofill:focus,
.c-archivio-filter input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

/* HTML Placeholders
   ---- */

.c-archivio-filter span.o-placeholder {
    position: absolute;
	top:11px;
	left:5px;
	height:28px;
	line-height:28px;
    font-family: 'Roboto', sans-serif;
	font-size:2rem;
	color:#777777;
	font-weight:300;
    z-index: 799;
    padding: 0 5px;
	-webkit-transition: transform .3s;
	-moz-transition: transform .3s;
	-ms-transition: transform .3s;
	-o-transition: transform .3s;
	transition:transform .3s;
}

.c-archivio-filter input:active ~ span.o-placeholder ,
.c-archivio-filter input:focus ~ span.o-placeholder {
    background: #fff;
    -ms-transform: translate(0, -25px);
    -webkit-transform: translate(0, -25px);
    transform: translate(0, -25px);
    z-index: 801;
}

.c-archivio-filter input.is-filled ~ span.o-placeholder,
.c-archivio-filter input:active ~ span.o-placeholder ,
.c-archivio-filter input:focus ~ span.o-placeholder {
    background: #fff;
    -ms-transform: translate(0, -25px);
    -webkit-transform: translate(0, -25px);
    transform: translate(0, -25px);
    z-index: 801;
}

/* Placeholders
   ---- */

.c-archivio-filter input::-webkit-input-placeholder,
.c-archivio-filter textarea::-webkit-input-placeholder {
	color: transparent;
}
.c-archivio-filter input:-moz-placeholder,
.c-archivio-filter textarea:-moz-placeholder {
	color: transparent;
}

.c-archivio-filter input::-moz-placeholder,
.c-archivio-filter textarea::-moz-placeholder {
	color: transparent;
}

.c-archivio-filter input::-ms-placeholder,
.c-archivio-filter textarea::-ms-placeholder {
	color: transparent;
}

.c-archivio-filter input::-ms-input-placeholder,
.c-archivio-filter textarea::-ms-input-placeholder {
	color: transparent;
}

.c-archivio-filter input::placeholder,
.c-archivio-filter textarea::placeholder {
	color: transparent;
}

/* Text and Select
   ---- */

.c-archivio-filter select {
	width: 100%; 
	padding: 10px;
	background:none;
	border: solid 1px #d4d7d9;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-image: url(../images/arrow-down.jpg);
    background-repeat: no-repeat;
    background-position: center right;
    background-origin: content-box;
}

.c-archivio-filter input[type=text] {
	height:50px;
}

.c-archivio-filter input:hover,
.c-archivio-filter input:active,
.c-archivio-filter input:focus,
.c-archivio-filter select:hover,
.c-archivio-filter select:active,
.c-archivio-filter select:focus {
	border: solid 1px #b2ce27;
	outline:none;
}

.c-archivio-filter input ~ span ~ i ,
.c-archivio-filter select ~ span ~ i {
	position:absolute;
	font-size:2.3rem;
	right:1px;
	top:5px;
	background:#fff;
	width:50px;
	height:40px;
    z-index: 799;
}

.c-archivio-filter input ~ span ~ i:before ,
.c-archivio-filter select ~ span ~ i:before {
	position:absolute;
	font-size:1.8rem;
	color:#1b4d80;
	right:10px;
	top:11px;
}

.c-archivio-filter textarea ~ span ~ i {
	position:absolute;
	right:20px;
	bottom:20px;
	z-index:801;
	opacity:0;
	cursor: text;
}

/* Select
   ---- */

.c-archivio-filter select {
	position:relative;
    font-family: 'Roboto', sans-serif;
	font-size:2rem;
	font-weight:300;
	color:#777777;
	height:50px;
    z-index: 800;
}

.c-archivio-filter select::-ms-expand {
	display: none;
}

.c-archivio-filter select::-ms-expand {
	display: none;
}

.c-form-glossario{
	.c-contact-form{
		padding: 40px 30px 40px 30px;
		.c-form-group{
			margin-bottom: 0;
		}
	}
	
}


@media (max-width: 992px) {
	
	.c-archivio-filter {
    	padding: 50px 10px 30px 10px;
		border:none;
	}

	.c-archivio-filter:before {
		left: 50%;
		-ms-transform: translate(-50%, -38px);
		-webkit-transform: translate(-50%, -38px);
		transform: translate(-50%, -38px);
	}

	.c-archivio-filter .col-md-offset-right-6 {
		margin-right: 0;
	}
	
	.c-archivio-filter .c-form-group:first-of-type {
		margin-bottom:20px;
	}
	
}

/* Grid
   ---- */

.c-grid {
	display:block;
	position:relative;
}

.c-grid:after {
	content: '';
	display: block;
	clear: both;
}

.c-element-item {
	position: relative;
	text-align:center;
	margin-bottom:20px;
	min-height: 275px;
}

.c-element-item img {
	position: relative;
	text-align:center;
	width:50%;
	margin:0 auto
}

.c-element-item .cta-testuale-1-a img {
	height: 10px;
	margin-top: -5px;
	transform: rotate(135deg);
	width: auto;
}
.c-element-item .cta-testuale-1-a {
	display: inline;
}

.c-element-item h5 {
	min-height: 25px;
	margin-top: 10px!important;
}

.c-icon-description {
    display: table;
    width: 100%;
	padding:0 10px;
	background:#e7eaec;
	font-size:12px;
	margin-bottom:25px;
	min-height:80px;
	height: 80px;
	position:relative;
}

.c-icon-description span {
	display: table-cell;
    vertical-align: middle;
    width: 100%;
    margin: 0 auto;
	text-align: center;
	font-size: $xxs-size;
}

.c-icons-download {
	text-align:left;
	position:relative;
	padding-bottom:15px;
	font-size:14px;
	z-index:898
}

.c-icons-download.c-no-description {
	margin-top:130px;
}

.c-icons-download:after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 2px;
	background:#222;
    z-index: 901;
    bottom: 0;
    left: 0;
}

.c-element-item a {
	display:block;
	text-align:left;
	text-decoration:underline;
	color:#264d7a;
	font-size:12px;
	margin-bottom:15px;
}

.cta-download-ico:link, .cta-download-ico:visited{
	color: $cta-green-digital;
	vertical-align: middle;
	position: relative;
    font-family: $roboto;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 0;
    padding: 0;
	text-decoration: none;
	transition: color .3s;
	text-align: center;
	border: 0;

	span{
		position: relative;
		top:-5px;
		left:5px;
	}
	
	&:hover{
		color: $dark-blue-digital;
		border:0;

		svg{
			stroke: $dark-blue-digital
		}
	}


	svg{
		width: 20px;
		height: auto;
		transition: stroke .3s;
		stroke: $cta-green-digital
	}

	
}

@media (max-width: 767px) {
	
	.c-element-item {
		width:100%;
	}

	.c-form-glossario{
		.c-contact-form{
			margin: 70px 0 0 0;
			padding: 20px 10px;
		}
	}
	
}