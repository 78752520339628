// Fix
// $font-fix: 0.625;
$font-fix: 1;

// Spacing
$xxxs-size: 1rem * $font-fix;
$xxs-size: 1.4rem * $font-fix;
$xs-size: 1.6rem * $font-fix;
$s-size: 1.9rem * $font-fix;
$m-size: 2rem * $font-fix;
$l-size: 2.3rem * $font-fix;
$xl-size: 2.4rem * $font-fix;
$xxl-size: 3.3rem * $font-fix;
$xxxl-size: 4rem * $font-fix;

// Media Breakpoint
$media-xs: 480px;
$media-sm: 768px;
$media-md: 992px;
$media-lg: 1200px;
$media-xl: 1400px;

// Fonts
$roboto:'Roboto', sans-serif;
$robotoSlab:'Roboto Slab', serif;
$robotoCondensed:'Roboto Condensed', sans-serif;
$arial: 'Arial', sans-serif;
// $arialItalic: 'Arial Italic', sans-serif;
// $arialBold: 'Arial Bold', sans-serif;
// $arialBoldItalic: 'Arial Bold Italic', sans-serif;
$arialNarrow: 'Arial Narrow', Arial, sans-serif;

// Palette
$dark-blue-digital:#264d7a;
$middle-blue-digital: #92a6bc;
$light-blue-digital: #e9edf2;

$green-digital: #b2ce27;
$middle-green-digital: #d9e793;
$light-green-digital: #e8f0be;
$cta-green-digital: #a3bf19;

$dark-grey:#222;
$off-white: #f1f1f1;

$red-alert: #ff3333;
$yellow-alert: #dfc90d;



%base-text{
    color: $dark-grey;
    font-weight: 400;
    line-height: 1.2;
}

@mixin transform($property) {
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
}

@mixin transition($property) {
    -webkit-transition: $property;
    -moz-transition: $property;
    -ms-transition: $property;
    -o-transition: $property;
    transition: $property;
}