body { position: relative}

.c-social-buttons {
	margin: 0 !important;
	padding: 0;
}

.c-social-buttons li {
	position:relative;
	width:60px;
	height:80px;
	display:inline-block;
	text-align:center;
	cursor:pointer;
	-webkit-transition: background 0.3s ease-in-out;
	-moz-transition: background 0.3s ease-in-out;
	-ms-transition: background 0.3s ease-in-out;
	-o-transition: background 0.3s ease-in-out;
	transition:background 0.3s ease-in-out;
}

.c-social-buttons li span {
	font-size:1.2rem;
	margin-bottom:20px
}

.c-social-buttons li.c-like-button {
	background: url(../../assets/images/like-button.svg) no-repeat left bottom;
}

.c-social-buttons li.c-like-button:hover {
	background-image: url(../../assets/images/like-button-hover.svg);
}

.c-social-buttons li.c-print-button {
	background: url(../../assets/images/print-button.svg) no-repeat left bottom;
}

.c-social-buttons li.c-print-button:hover {
	background-image: url(../../assets/images/print-button-hover.svg);
}

.c-social-buttons li.c-icon-share {
	background: url(../../assets/images/share-button.svg) no-repeat left bottom;
}

.c-social-buttons li.c-icon-share:hover {
	background-image: url(../../assets/images/share-button-hover.svg);
}

.c-social-share {
	background: #fff;
	border:solid 1px #ddd;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
	display:none;
	float:right;
    margin-top: 10px;	
	padding:10px !important;
	position: absolute !important;
    right: 0px;
    top: 100%;
    width: 187px;
	z-index:888;
}

.c-main-content ul.c-social-share {
	position: absolute;
}

.c-social-share:hover li {
	opacity:0.5;
	-webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
}

.c-social-share:hover li:hover {
	opacity:1;
}

.c-social-share li {
	position:relative;
	display:inline-block;
	z-index:900;
	cursor:pointer;
	width:30px;
	height:30px;
	line-height:30px;
	text-align:center;
	margin-right:10px;
}

.c-social-share li.c-facebook-icon {
	background-color:#3b5999
}

.c-social-share li.c-twitter-icon {
	background-color:#55acee;
	margin-right: 0;
}

.c-social-share li.c-linkedin-icon {
	background-color:#0077B5
}

.c-social-share li.c-facebook-icon ,
.c-social-share li.c-twitter-icon ,
.c-social-share li.c-linkedin-icon {
	-webkit-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -o-transition: opacity .3s linear;
    transition: opacity .3s linear;
}

.c-social-share li a {
	border:none;
	vertical-align:middle
}

.c-social-share li a:focus,
.c-social-share li a:hover {
	border-bottom: 0;
}

.c-social-share li a i:before {
	color:#fff
}

.c-social-share li.c-email-icon {
	width:30px;
	height:30px;
	line-height:30px;
}

.c-social-share li.c-email-icon a i {
	font-size:2.4rem;
	vertical-align:sub
}

.c-social-share li.c-email-icon a i:before {
	color:#333;
	-webkit-transition: color .3s;
    -moz-transition: color .3s;
    -ms-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
}

.c-social-share:before {
	content: '';
    display: block;
    position: absolute;
    top: 0px;
    right: 20px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-left: none;
    border-bottom: none;
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    -webkit-transform: translate(0, -11px) rotate(-45deg);
    -ms-transform: translate(0, -11px) rotate(-45deg);
    -o-transform: translate(0, -11px) rotate(-45deg);
    transform: translate(0, -11px) rotate(-45deg);
    z-index: 889;
}


/* button condividi */
.c-condividi { 
    cursor: pointer;
	height: 65px;
    right: 0;
	position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 65px;
    z-index: 9
}
.c-condividi .o-fb, .c-condividi .o-mail, .c-condividi .o-linkedin, .c-condividi .o-twitter {
	position: absolute;
	width: 50px;
    height: 50px;
    display: inline-block;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    opacity: 0.8
}
.c-condividi .o-fb:hover, .c-condividi .o-mail:hover, .c-condividi .o-linkedin:hover, .c-condividi .o-twitter:hover {
	opacity: 1
}
.c-condividi .o-fb span, .c-condividi .o-mail span, .c-condividi .o-linkedin span, .c-condividi .o-twitter span {
	display: none
}

.c-condividi .o-fb {
	background: url("../images/condividi_facebook.svg") no-repeat center center;
	background-size: 50px 50px;
    left: -71%;
	top: -39%;
} 
.c-condividi .o-mail {
	background: url("../images/condividi_mail.svg") no-repeat center center;
	background-size: 55px 54px;
	height: 55px;
    right: 50%;
	top: -85%;
    transform: translateX(50%);
	width: 56px;
} 
.c-condividi .o-linkedin {
	background: url("../images/condividi_linkedin.svg") no-repeat center center;
	background-size: 50px 50px;
	bottom: -34%;
    left: -71%;
} 
.c-condividi .o-twitter  {
    background: url("../images/condividi_twitter.svg") no-repeat center center;
	background-size: 56px 55px;
	bottom: -85%;
    height: 55px;
	right: 50%;
    transform: translateX(50%);
    width: 56px;
} 

.c-condividi .c-condividi-img { display: none }

.c-condividi .c-condividi-button a {
	box-shadow: 0 2px 3px #949494;
	height: 51px;
	transition: box-shadow .3s linear;
	width: 51px;
}
.c-condividi .c-condividi-button a:focus,
.c-condividi .c-condividi-button a:hover {
	box-shadow: 0 4px 6px #949494;
}

@media (max-width: 767px) { 
	.c-condividi .o-fb {
		top: -128%;
		left: -108%;
		transform: translateX(50%);
		width: 50px;
		height: 50px;
		background: url("../images/condividi_facebook-mobile.svg") no-repeat center center
	} 
	.c-condividi .o-mail {
		top: -152%;
    	left: -33%;
		background: url("../images/condividi_mail-mobile.svg") no-repeat center center
	} 
	.c-condividi .o-linkedin {
		top: -70%;
    	left: -130%;
    	bottom: auto;
		background: url("../images/condividi_linkedin-mobile.svg") no-repeat center center
	} 
	.c-condividi .o-twitter  {
		top: 10%;
    	left: -194%;
    	right: auto;
    	bottom: auto;
		transform: translateX(50%);
		background: url("../images/condividi_twitter-mobile.svg") no-repeat center center
	} 
	
	.c-condividi { 
		position: fixed;
    	bottom: 45px;
    	right: 5px;
    	top: auto
    }

}
