.c-breadcrumb2 > div { padding-left: 10px }
   
.menunavigabile {
	font-size: 1.7rem;
	padding: 0;
	font-weight: 400;
}

.menunavigabile .c-scroll-content { 
	position:relative;
	padding: 0;
	width:100%;
}

#hidden-close-menu-top,
#hidden-close-menu-bottom {
	left: -10000px;
	position: absolute;
	top: -10000px;
	z-index: -1;
}

.menunavigabile.c-scroll-container {
    top: 192px;
} 

.menunavigabile .dropdown {
	position: static;
}

.menunavigabile .dropdown-content {
	display: none;
}

.menunavigabile .dropdown-content.sottomenu-desktop-aperto {
	display: block;
}

.displayblock { 
	display: block  !important;
}
.positionrelative {
	position: relative !important
}

.is-open.c-menu-button:before {
    height: 0px;
}

.menunavigabile a.o-sel, .menunavigabile a:hover.o-sel{
		background: rgba(178,206,39,.5); 
		color: #fff;
}

.menunavigabile li {
	margin-bottom: 10px;
}

.menunavigabile li a[href="#"] {
	cursor: default;
	text-decoration: none;
}

.menunavigabile li:hover > a,
.menunavigabile li.in-section { 
	display: block; 
}



.menunavigabile li.c-selected > .dropdown-content.in  {
	display: block;
}

.menunavigabile a{
	-webkit-transition: background 0.3s ease-out;
    -moz-transition: background 0.3s ease-out;
    -ms-transition: background 0.3s ease-out;
    -o-transition: background 0.3s ease-out;
    transition: background 0.3s ease-out;
}
    
    
.menunavigabile a:hover { 
	color: #222;
}

.menunavigabile a, 
.menunavigabile a:visited, 
.menunavigabile a:active { 
	text-decoration: none 
}

.menunavigabile li span.highlighted {
	background-color: rgba(38, 77, 122, .1);
}

@media (min-width: 768px) and (max-width: 991px) {	
	.page-home .menunavigabile.c-scroll-container {
	    top: 146px;
	}
}

@media (min-width: 992px) {	
	.page-home .menunavigabile.c-scroll-container {
	    top: 150px;
	}
}

@media (min-width: 768px) { 

	.menunavigabile .c-scroll-content {
    	background: transparent;
		height: 100%;

		// fixa l'altezza di '.c-scroll-content' in Home dopo che il menu è stato aperto una prima volta, si è scrollata la pag e si è riaperto il menu
		min-height: 100%;
		
    	width: 18%;
	}

	.menunavigabile ul { 
		width: 100%;
		position: absolute;
		left: 0%;
		top: 0;
		bottom: 0;
		padding: 40px 0;
		border-right: 1px solid #d8d7d8;
		border-left: 1px solid #d8d7d8;
		border-color: rgba(215,216,215,.3);
		background: #fff;
		margin-bottom: 0
	} 
 
	.menunavigabile ul ul { 
		width: 100%; 
		position: absolute;
		left: 100%;
		top: 0;
		bottom: 0;
	}

	.menunavigabile li a {
		color: #222222; 
		display: inline-block;
		margin-left: 7px;
		outline: 0;
		padding: 5px 20px 5px 13px;
		text-decoration: none;
		width: 75%;
	}
	.menunavigabile li a:focus,
	.menunavigabile li a:hover {
		background-color: rgba(38, 77, 122, .3);
	}
	.menunavigabile > nav > ul li span a[href^="#"] {
		background-color: transparent;
	}
	.menunavigabile > nav > ul li span a[href^="#"]:focus {
		background-color: rgba(38,77,122,.3);
	}
	.menunavigabile > nav > ul li span a[href^="#"]:active,
	.menunavigabile > nav > ul li span a[href^="#"]:hover {
		background-color: #fff;
	}

	.menunavigabile li span {
		background-color: transparent;
		transition: background-color .3s;
	}

	.menunavigabile li span a {
		border-radius: 30px;
	}

	.menunavigabile  .dropdown .dropdown .dropdown .dropdown ul { border-right: 0px }
	
	.menunavigabile li.c-selected > span {
		background-color: rgba(38, 77, 122, .1);
	}

} 

	.menunavigabile span {
		display: block;
		margin: 0 auto;
		position: relative;
	}
	.menunavigabile .svg-wrapper {
    	background-color: transparent;
		border: none;
		display: inline-block;
		height: 100%;
		outline: none;
		position: absolute;
		right: 0;
		top: 0;
		transition: background-color 0.3s ease-out;
		width: 25%;

		@media (max-width: 767px) {
			&:focus {
				background-color: #e9edf1;
			}
		}
	}
	.menunavigabile .svg-wrapper:hover {
		cursor: pointer;
	}
	.menunavigabile .svg-wrapper::before {
		content: '';
		width: 20px;
		height: 20px;
		background-color: transparent;
		border-radius: 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		transition: background-color .5s;
	}
	.hoverPrimiLI::before,
	.menunavigabile .svg-wrapper:focus::before,
	.menunavigabile .svg-wrapper:hover::before {
		background-color: #264d7a !important;
	}

	.menunavigabile .svg-wrapper svg {
		height: 11px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
	.hoverPrimiLI svg,
	.menunavigabile .svg-wrapper:focus svg,
	.menunavigabile .svg-wrapper:hover svg {
		fill: #fff;
		stroke: #fff;
	}	
	
@media (max-width: 767px) {
	.menunavigabile li:focus > span,
	.menunavigabile li:hover > span,
	.menunavigabile li.c-selected > span {
		background-color: rgba(38, 77, 122, .1);
	}

	.menunavigabile {
		font-size: 1.8rem;
		padding: 0;
		font-weight: 300;
	}
	
	.menunavigabile .dropdown-content { display: none; visibility: visible; opacity: 1;  }
	.menunavigabile .dropdown-content span { padding-left: 16px }
	.menunavigabile .dropdown-content .dropdown-content span { padding-left: 32px }
	.menunavigabile .dropdown-content .dropdown-content .dropdown-content span { padding-left: 48px }
	
	.menunavigabile .dropdown:hover >.dropdown-content { display: none } 
	.menunavigabile .dropdown.is-open > .dropdown-content { display: block }
	
	.menunavigabile.c-scroll-container {  top: 78px; }
	.c-breadcrumb2 { display: none }
	
	.menunavigabile .c-scroll-content {
		background: #fff;
		height: 100%;
		left: 0;
	}
	.menunavigabile ul { 
		padding: 0 0 3px 0;
		background: #fff;
		margin-bottom: 0
	}
	.menunavigabile ul:first-child { 
		margin-left: 0px
	}
	.c-scroll-content > ul > li { 
		border-bottom: 1px solid #d8d7d8 !important;
	}
	.menunavigabile li a {
		color: #222222;
		display: inline-block;
		outline: 0;
		padding: 10px 16px;

		&:focus {
			background-color: #e9edf1;
		}
	}

	.menunavigabile .has-child a {
		display: inline-block;
		width: 75%;
	}
	.menunavigabile .has-child a:only-child { width: 100%; }

	.menunavigabile .has-child > span > a {
		background: none;

		&:focus {
			background-color: #e9edf1;
		}
	}

	.menunavigabile .svg-wrapper:before {
		content: none;
	}

	.menunavigabile .svg-wrapper svg {
 	   	height: 15px;
		top: 50%;
		transition: transform .5s;
		transform: translateY(-50%) rotate(90deg);
 	   	width: 9px;
	}
	
	.menunavigabile .svg-wrapper:focus svg,
	.menunavigabile .svg-wrapper:hover svg {
		fill: inherit;
		stroke: inherit;
	}
    
	.menunavigabile .is-open > span .svg-wrapper svg {
    	transform: translateY(-50%) rotate(270deg)
    }
}

@media (max-width: 480px) { 
	.menunavigabile.c-scroll-container {
		top: 78px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.menunavigabile.c-scroll-container {
		top: 188px;
	} 

	.menunavigabile .c-scroll-content {
    	width: 25%;
	}

	.menunavigabile li a {
		margin-left: 0;
	}
}