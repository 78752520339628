/* Typo
   ---- */
   .c-iniziative-wrapper {
	background-color: #f5f5f5;
	padding: 0 0 35px;
}
.c-main-content .c-center-title {
	text-align:center;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 100;
    padding: 0;
    margin-bottom: 40px;
}

/* Iniziative
   ---- */

.carousel-iniziative {
	width:308px;
	margin:50px auto
}

@media (max-width: 767px) {
	.carousel-iniziative {
		max-width: 308px;
		width: 100%;
	}
}

/* Indicators
   ---- */

.carousel-iniziative .carousel-indicators {
	bottom:-50px;
}

.carousel-iniziative .carousel-indicators li {
	background:#fff;
	border-radius: 0;
    border: 1px solid #a0a0a0;
	width:12px;
	height:12px;
	margin:0 2px;
}

.carousel-iniziative .carousel-indicators li.active {
	background-color:#a0a0a0;
	border-color:transparent;
	margin:0 2px;
}

/* FB
   ---- */
   
.c-facebook-box, 
.c-iniziative-box {
	text-align:center;
}

.c-facebook-box .fb-page {
	margin:5px auto;
}

/* FB fine
   ---- */

.no-padding-left {
	padding-left: 0;
}

#socialwall iframe {
	margin-top: 10px;
	max-height: initial;
}

/* Facebook */
.facebook-link {
	background-color: #3b5998;
	height: 126px;
	margin-bottom: 5px;
	overflow: hidden;
	position: relative;
}

	/* Comuni a tutti i social */
	.facebook-link a,
	.twitter-link a,
	.youtube-link a,
	.instagram-link a,
	.linkedin-link a {
		border: none;
		color: #fff;
		display: block;
		font-size: 2rem;
		font-weight: 900;
		height: 100%;
		text-decoration: none;
	}
	.facebook-link a:focus,
	.facebook-link a:hover,
	.twitter-link a:focus,
	.twitter-link a:hover,
	.youtube-link a:focus,
	.youtube-link a:hover,
	.instagram-link a:focus,
	.instagram-link a:hover,
	.linkedin-link a:focus,
	.linkedin-link a:hover {
		border: none;
		color: #fff;
	}
	.facebook-link a span,
	.twitter-link a span,
	.youtube-link a span,
	.instagram-link a span,
	.linkedin-link a span {
		bottom: 0;
		position: absolute;
		right: 5px;
	}
	/* Fine */

.facebook-link a:before {
	background: url(../images/facebook-ico.svg) no-repeat 5px 0;
	content: '';
    height: 80px;
	left: 15px;
	position: absolute;
	top: 55px;
	width: 40px;
}

@media (max-width: 767px) {
	.facebook-link {
		height: 100px;
	}
	.facebook-link a:before {
		top: 30px;
	}
}
@media (min-width: 410px) and (max-width: 767px) {
	.facebook-link ~ iframe {
		left: 50%;
		position: relative;
		transform: translateX(-50%);
	}
}
/* Fine */

/* Twitter */
.twitter-link-wrapper {
	margin-bottom: 30px !important;
	position: relative;
}
.twitter-link {
	background-color: #1da1f2;
	bottom: 7px;
	position: absolute;
	top: 0;
}
.twitter-link a:before {
	background: url(../images/twitter-ico.svg) no-repeat center center;
	content: '';
    height: 50px;
	left: 25%;
	position: absolute;
	top: 50%;
    width: 50px;
}

@media (max-width: 767px) {
	.twitter-link {
		height: 100px;
		margin: 20px 15px 15px;
		position: relative;
	}
	.twitter-link a:before {
		left: 15px;
	}
}
/* Fine */

/* YouTube */
.youtube-link {
	background-color: #ff0000;
	height: 126px;
}
.youtube-link a:before {
	background: url(../images/youtube-ico.svg) no-repeat center center;
	content: '';
	height: 40px;
	left: 20px;
	position: absolute;
	top: 25%;
	width: 56px;
}
.youtube-link a span {
	right: 20px;
}
@media (max-width: 767px) {
	.youtube-link {
		height: 100px;
		margin: 0 0 15px 15px;
	}
	.youtube-link a:before {
		left: 35px;
	}
}
/* Fine */

/* Instagram */
.instagram-link {
	background-color: #c13584;
	height: 126px;
}
.instagram-link a:before {
	background: url(../images/instagram-ico.svg) no-repeat center center;
	content: '';
	height: 50px;
	left: 20px;
	position: absolute;
	top: 25%;
	width: 50px;
}
.instagram-link a span {
	right: 20px;
}
@media (max-width: 767px) {
	.instagram-link {
		height: 100px;
		margin: 0 0 15px 15px;
	}
	.instagram-link a:before {
		left: 35px;
	}
}
/* Fine */

/* Linkedin */
.linkedin-link {
	background-color: #006699;
	height: 126px;
}
.linkedin-link a:before {
	background: url(../images/linkedin-ico.png) no-repeat center center;
	content: '';
	height: 50px;
	left: 20px;
	position: absolute;
	top: 25%;
	width: 50px;
}
.linkedin-link a span {
	right: 20px;
}
@media (max-width: 767px) {
	.linkedin-link {
		height: 100px;
		margin: 0 0 15px 15px;
	}
	.linkedin-link a:before {
		left: 35px;
	}
}
/* Fine */