.c-fascia-evidenza {
	background: url("../images/126298.jpg") no-repeat right center;
	background-size: 80%;
	margin: 50px 0 !important
}
.c-fascia-evidenza.c-fascia-evidenza-dx {
	background-position: left center;
	background-image: url("../images/ProgettazioneUX.jpg") ;
}
.c-fascia-evidenza-full-width {
	background-image: url("../images/IMG_banner_news.jpg");
	background-size:cover;
}
.c-fascia-evidenza .c-blocco-testo {
	background: #f5f5f5;
    padding: 65px 70px 50px 70px;
    border: 60px solid #fff;
	margin: 40px 
}

h2.c-fascia-evidenza-subtitle {
    margin-top: 0;
    margin-bottom: 0;
}

@media (min-width: 992px) {
	.c-fascia-evidenza.c-fascia-evidenza-dx .c-blocco-testo {
		margin-left: calc(50% - 40px)
	}
}

.c-blocco-testo-noborder { 
	border: 0px !important; 
	background: #fff !important; 
	margin: 100px !important
}
.c-fascia-evidenza h2 {
    font-size: 4.4rem;
    margin: 0 0 35px 0;
    line-height: 1.2;
    font-weight: 100;
    text-align: center;
    color: #264d7a;
} 
.c-fascia-evidenza .c-blocco-testo {
	text-align: center
}
.c-fascia-evidenza p {
	font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 20px !important;
}

.c-fascia-evidenza a.o-button2 {
    font-family: 'Roboto Slab', serif;
    position: relative;
    /*display: inline-block;*/
    text-decoration: none;
    color: #264d7a !important;
    font-size: 2.4rem;
    font-weight: 400;
    -webkit-transition: color .3s;
    transition: color .3s;
    margin-top: 35px;
    padding-left: 20px;
    text-decoration: none !important;
	border:none;  
}
.c-fascia-evidenza a.o-button2:before {
    content: '';
    position: absolute;
    left: 0;
    top: 11px;
    display: block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #264d7a;
    border-right: 2px solid #264d7a;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    
    -webkit-transition: all .3s;
    transition: all .3s;

}

.c-fascia-evidenza a.o-button2:hover {
	color: #b2ce27 !important;
}
.c-fascia-evidenza a.o-button2:hover:before {
	border-color: #b2ce27 !important;
	
    animation: o-button 1s linear infinite;
  	animation-direction: alternate;
	
}

/* LAYOUT D */

.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green {
	background-image: url("../images/articolo-layout-d-green.jpg");
	background-position: top center;
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-blue {
	background-image: url("../images/articolo-layout-d-blue.jpg");
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-black {
	background-image: url("../images/articolo-layout-d-black.jpg");
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d .c-blocco-testo,
.c-fascia-evidenza.c-fascia-evidenza-layout-d .c-blocco-testo-centrale {
    border: 30px solid #fff;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    padding: 65px 70px 50px 70px;
	margin-top: 40px; 
	margin-bottom: 40px;
	text-align:center
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d h2 {
	text-transform:uppercase;
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d h2 ,
.c-fascia-evidenza.c-fascia-evidenza-layout-d p {
    color: #fff;
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d a.o-button2 {
    color: #fff !important;
	padding-left:0
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d a.o-button2:before {
	display:none;
}
.c-fascia-evidenza.c-fascia-evidenza-layout-d a.o-button2:hover {
    color: #b2ce27 !important;
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo ,
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo-centrale {
	background:rgba(40,74,61,0.7) !important;
}
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo:hover,
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo-centrale:hover {
	background:rgba(40,74,61,1) !important;
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-blue .c-blocco-testo,
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-blue .c-blocco-testo-centrale {
	background:rgba(38,77,122,0.7) !important;
}
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-blue .c-blocco-testo:hover ,
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-blue .c-blocco-testo-centrale:hover {
	background:rgba(38,77,122,1) !important;
}

.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-black .c-blocco-testo,
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-black .c-blocco-testo-centrale {
	background:rgba(34,34,34,0.7) !important;
}
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-black .c-blocco-testo:hover ,
.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-black .c-blocco-testo-centrale:hover {
	background:rgba(55,55,55,1) !important;
}


/* LAYOUT E */
.c-fascia-evidenza-layout-e {
	margin: 50px 0 0 !important;
}
.c-fascia-evidenza-layout-e .c-news1 .c-news-foto {
    background: #D9E793;
}

.c-fascia-evidenza-layout-e .c-news1 .c-news-foto h2 {
    font-weight: 100;
    color: #222222;
    font-size: 4rem;
}

.c-fascia-evidenza-layout-e .c-news-verde-col .c-news-blu {
   color: #fff;
   background: #373737
}

.c-fascia-evidenza-layout-e .c-news-verde-col .c-news-verde.c-news-palette {
	background: #DEDEDE url("../images/palette.jpg") no-repeat bottom right
}

.c-fascia-evidenza-layout-e .c-news-verde-col .c-news-verde.c-news-palette .o-bloccotesto {
	width: 50%;
}

.c-fascia-evidenza-layout-e .c-news-verde-col .c-news-blu h2 {
    margin-top: 0;
}
.c-fascia-evidenza-layout-e .c-news1 .c-news-blu .o-bloccotesto {
    margin: 30px 40px;
}

@keyframes o-button {
   0%, 100% {
    left: 0px;
  }
  50% { left: -15px }
}

@media (max-width: 1200px) {
	.c-fascia-evidenza .c-blocco-testo {
		margin: 60px;
		border-width: 40px;
		padding: 30px
	}
}

@media (max-width: 992px) {
	.c-fascia-evidenza {
		background-size: cover
	}
	
	.c-fascia-evidenza.c-box-bottom .c-blocco-testo {
		margin-top: 90%
	}
	.c-fascia-evidenza.c-box-bottom {
   		background-size: 150%;
   		background-position: right top
	}
	.c-fascia-evidenza.c-fascia-evidenza-layout-d .c-blocco-testo-centrale {
		margin: 60px
	}
}

@media (max-width: 480px) {
	.c-fascia-evidenza .c-blocco-testo {
		margin: 20px;
		border-width: 15px;
		padding: 20px
	}
	.c-blocco-testo-noborder { 
		margin: 20px !important
	}
	.c-fascia-evidenza p {
    	font-size: 1.6rem;
    	line-height: 1.4;
	}
	.c-fascia-evidenza h2 {
		font-size: 2.6rem;
	} 

	.c-fascia-evidenza.c-fascia-evidenza-layout-d .c-blocco-testo-centrale {
		margin: 20px
	}

	.c-fascia-evidenza.c-fascia-evidenza-layout-d .c-blocco-testo,
	.c-fascia-evidenza.c-fascia-evidenza-layout-d .c-blocco-testo-centrale {
		border-width: 15px;
		padding: 20px;
		margin-top: 20px; 
		margin-bottom: 20px;
		text-align:center
	}

	.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo ,
	.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo-centrale {
		background:rgba(40,74,61,1) !important;
	}
	
	.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-blue .c-blocco-testo,
	.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-blue .c-blocco-testo-centrale {
		background:rgba(38,77,122,1) !important;
	}
	
	.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-black .c-blocco-testo,
	.c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-black .c-blocco-testo-centrale {
		background:rgba(55,55,55,1) !important;
	}

}