/* Hamburger menu > nav
   ---- */

   .c-scroll-content .o-title {
       font-family: Roboto Slab,sans-serif;
   }
   
   .c-menu-button-dx:hover .c-scroll-container-dx {
       display: block
   }
   
   .c-menu-button-dx .c-scroll-container-dx.chiuso {
       display: none
   }
   
   .c-menu-button-dx .c-scroll-content-dx a {
       text-transform: uppercase;
       text-align: center;
       margin: 20px 0;
       font-size: 1.8rem;
       line-height: 1rem !important;
       color: #fff;
       display: block
   }
   .c-menu-button-dx .c-scroll-content-dx a:hover { text-decoration: none }
   .c-menu-button-dx .c-scroll-content-dx img { 
       display: block;
       margin: 20px auto
   }
   .c-scroll-content ul.c-scroll-nav>li>a:focus,
   .c-scroll-content ul.c-scroll-nav>li>a:hover {
       color: #a3bf19;
       background-color: transparent;
       text-decoration: none;
       -webkit-transform: translate(5px);
       -moz-transform: translate(5px);
       -ms-transform: translate(5px);
       -o-transform: translate(5px);
       transform: translate(5px);
   }
   
   .c-scroll-container-dx {
       position: fixed;
       display:none;
       width:100%;
       top: 150px;
       bottom: 0;
       right: 0;
       left: 0;
       margin: 0;
       z-index: 899;
       overflow-x: hidden;
       -ms-overflow-x: hidden; 
       overflow-y: auto;
       -ms-overflow-y: auto; 
       background-color: transparent;
       -webkit-overflow-scrolling: touch;
       -moz-overflow-scrolling: touch;
       -o-overflow-scrolling: touch;
       overflow-scrolling: touch;
       box-sizing:content-box;
       background-image: url('../images/main-nav-bg.png');
       background-repeat:repeat;
       cursor: pointer;
       text-align: center;
   }
   
   .c-scroll-content-dx {
       /*width:80%;
       margin: 0 10%;*/
       padding: 0px;
       position: relative;
       z-index: 900;
       cursor: default;
       background: transparent;
       line-height: 5rem;
   }
   .c-scroll-content-dx .container, .c-scroll-content-dx .container-fluid {
       background-color:#264d7a;
       padding: 20px 
   }
   
   .c-scroll-content-dx ul {
       list-style:none;
   }
   .c-scroll-content-dx a {
       opacity: .8;
       -webkit-transition: all 0.3s ease-out;
       -moz-transition: all 0.3s ease-out;
       -ms-transition: all 0.3s ease-out;
       -o-transition: all 0.3s ease-out;
       transition: all 0.3s ease-out;
   }
   .c-scroll-content-dx a:hover {
       opacity: 1
   }
   .menu-solotesto .c-scroll-content-dx a:hover {
       opacity: 1;
       color: #92a6bc
   }
   .c-scroll-content-dx a:hover img {
       border:3px solid #fff
   }
   .c-scroll-content-dx img {
       max-width:100%;
       border:3px solid #264d7a;
       -webkit-transition: all 0.3s ease-out;
       -moz-transition: all 0.3s ease-out;
       -ms-transition: all 0.3s ease-out;
       -o-transition: all 0.3s ease-out;
       transition: all 0.3s ease-out;
   }
   
   .c-scroll-content-dx .menu-icone a:hover img {
       border:0px solid #fff;
       opacity: .5
   }
   .c-scroll-content-dx .menu-icone img {
       max-width:100%;
       border:0px solid #264d7a;
       -webkit-transition: all 0.3s ease-out;
       -moz-transition: all 0.3s ease-out;
       -ms-transition: all 0.3s ease-out;
       -o-transition: all 0.3s ease-out;
       transition: all 0.3s ease-out;
   }
   .c-scroll-content-dx .menu-icone div {
       margin-bottom: 20px 
   }
   
   
   .c-scroll-container-dx.is-open .c-scroll-content-dx {
       display:block;
       left:0;
   }
   
   .c-scroll-content-dx .o-title {
       font-family: 'Roboto Slab', serif;
       color:#21436d;
       font-size:2.4rem;
       font-weight:400;
       margin-top:0px;
       margin-bottom:20px;
   }
   
   .c-scroll-content-dx ul.c-scroll-nav {
       list-style:none;
       padding-left:0;
       margin-bottom:50px;
   }
   
   .c-scroll-content-dx ul.c-scroll-nav>li {
       margin-bottom:5px;
   }
   
   .c-scroll-content-dx ul.c-scroll-nav>li>a {
       font-weight:300;
       font-size:1.7rem;
       color: #222;
       position: relative;
       display: inline-block;
       text-decoration:none;
       -webkit-transition: transform 0.3s ease-out;
       -moz-transition: transform 0.3s ease-out;
       -ms-transition: transform 0.3s ease-out;
       -o-transition: transform 0.3s ease-out;
       transition: transform 0.3s ease-out;
   }
   
   .c-scroll-content-dx ul.c-scroll-nav>li>a:hover {
       color: #b2ce27;
       background-color: transparent;
       -webkit-transform: translate(5px);
       -moz-transform: translate(5px);
       -ms-transform: translate(5px);
       -o-transform: translate(5px);
       transform: translate(5px);
   }
   
   /* Hamburger menu > close
      ---- */

    .c-scroll-container.is-open .o-scroll-close {
        top: 15px !important;
    }
   
   .o-scroll-close-dx {
       position: fixed;
       font-size: 0;
       content: '';
       display: none;
       padding:0;
       top: 165px;
       left: 0;
       height: 33px;
       width: 33px;
       border: none;
       background: transparent;
       z-index: 900;
   }
   
   .c-scroll-container-dx.is-open .o-scroll-close {
       left:calc(83.33% + 15px);
   }
   
   .o-scroll-close-dx span {
       position: absolute;
       content: '';
       top: 0;
       font-size:0;
       background-color: #212121;
       -webkit-transition-duration: 0.5s;
       -moz-transition-duration: 0.5s;
       -ms-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
       transition-duration: 0.5s;
   }
   
   .o-scroll-close-dx:before{
       position:absolute;
       content: '';
       width: 4px;
       top: 50%;
       height: 45px;
       left: 50%;
       background-color: #ffffff;
       border: 0;
       -webkit-transform: translate(-50%, -50%) rotate(45deg);
       -ms-transform: translate(-50%, -50%) rotate(45deg);
       transform: translate(-50%, -50%) rotate(45deg);
   }
   
   .o-scroll-close-dx:after{
       position:absolute;
       content: '';
       width: 4px;
       height: 45px;
       top: 50%;
       left: 50%;
       background-color: #ffffff;
       -webkit-transform: translate(-50%, -50%) rotate(-45deg);
       -ms-transform: translate(-50%, -50%) rotate(-45deg);
       transform: translate(-50%, -50%) rotate(-45deg);
   }
   
   .o-scroll-close-dx:focus{
       outline:0;
   }
   
   @media (max-width:992px){
       .c-scroll-container-dx {
           display: none
       }
       .c-menu-button-dx:hover .c-scroll-container-dx {
           display: none
       }
   
   }

   @media (min-width: 0px) and (max-width: 768px) {
        .c-scroll-content ul.c-scroll-nav li a {
            padding-left: 32px;
        }

        .c-scroll-content .o-title.is-open {
            border-bottom: none;
        }

        .c-scroll-content .o-title.is-open,
        .c-scroll-content .o-title:focus,
        .c-scroll-content .o-title:hover,
        .c-scroll-content ul.c-scroll-nav li a:focus,
        .c-scroll-content ul.c-scroll-nav li a:hover {
            background-color: rgba(38,77,122,.1) !important;
            border-radius: 0;
            box-shadow: none;
        }

        .c-scroll-content .o-title svg {
            height: 15px;
            padding: 0;
            right: 31px;
            top: 50%;
            -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg);
            width: 9px;
        }
        
        .c-scroll-content .o-title.is-open svg {
            -webkit-transform: translateY(-50%) rotate(270deg);
            transform: translateY(-50%) rotate(270deg);
        }
   }