.home0119 .c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo {
    background: rgba(38,77,122,0.85) !important;
    padding: 0
}
.home0119 .c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo a:first-child {
    padding: 65px 70px 80px 70px;
    display: block;
    border: 0;
}
    
.home0119 .c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo:hover {
	background: rgba(38,77,122,1) !important;
}

.home0119 .c-fascia-evidenza {
	margin: 0 !important
}

.home0119 .c-main-content h1 {
    margin-bottom: 50px;
}


.home0119 .c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green {
    background-image: url(../images/1030x502_A.jpg);
    background-position: left center;
}

// .home0119 .c-news {
// 	background: #fff
// }

.home0119 .c-news1 .c-news-foto {
    background: #D9E793;
}

.home0119 .c-news1 .c-news-foto h2 {
    font-weight: 100;
    color: #222222;
    font-size: 4rem;
}

.home0119 .c-news-verde-col .c-news-blu {
   color: #fff;
   background: #373737
}

.home0119 .c-news-verde-col .c-news-blu h2 {
	margin-top: 0 
}

.home0119 .c-news-verde-col .c-news-verde.c-news-palette {
	background: #DEDEDE url("../images/PerBCC_wall.jpg") no-repeat bottom right;
	width: calc(100% - 15px)
}

.home0119 .c-news-verde-col .c-news-verde.c-news-palette .o-bloccotesto {
	width: 50%;
}

.home0119 .c-news-blu .o-bloccotesto {
	margin: 30px 40px;
}

.home0119  .carousel-catalogo .c-box-item {
  margin-top: 0;
  min-height: 525px;
}

.home0119  .carousel-catalogo .c-box-item img {
	  // max-width: 140px;
    // margin: 20px auto !important;
    height: auto;
    width: 100%;
}
// .home0119 .carousel-catalogo .o-btn {
//     bottom: 58px;
// }
    
// .home0119 .c-main-content h3 {
//     margin: 0px 0 0px 0 !important;
// }

@media (max-width: 991px) {
  .home0119  .carousel-catalogo .c-box-item img {
    width: auto;
  }
}

@media (max-width: 500px) {
  .home0119 .carousel-catalogo .c-box-item {
    min-height: 540px;
  }
}


.home0119 .c-main-content a.c-right-button.o-button-white {
	color: #fff !important;
	position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}
.home0119 .c-main-content a.c-right-button.o-button-white:hover {
    color: #b2ce27 !important;
}
.home0119 a.c-right-button.o-button-white:before { border-color: #fff }

.home0119 .banner .carousel-caption2 {
	font-size: 2.4rem;
	color: #fff;
	padding-top:15px;
	position: absolute;
    bottom: 15px;
    transform: translateY(50%);
    z-index: 9999;
}
.home0119 .banner .carousel-caption2 .interno {
	box-shadow: 0px -17px 20px rgba(0,0,0, .15);
    padding: 0;
}
.home0119 .banner .carousel-caption2 .interno p {
	background: #fff;
    position: relative;
    padding: 25px;
    color: #000;
    font-family: "Roboto slab";
  	font-weight: 400;
}
.home0119 .banner .carousel-inner {
    overflow: visible;
}

.home0119 .banner .carousel-control {
	
}
.home0119 .banner .carousel-control .glyphicon-chevron-left, .home0119 .banner .carousel-control .glyphicon-chevron-right {
	background: #F1F1F1 !important;
	border-radius: 30px;
	padding: 20px;
	bottom: 50%;
    top: 50%;
}
.home0119 .banner .carousel-control .glyphicon-chevron-left {
    left: 20px;
}
.home0119 .banner .carousel-control .glyphicon-chevron-right {
    right: 20px;
}
.home0119 .banner .carousel-control .glyphicon-chevron-left:before, .home0119 .banner .carousel-control .glyphicon-chevron-right:before {
    border-top: 5px solid #264D7A;
    border-left: 5px solid #264D7A;
}

.home0119 .banner .carousel-control .glyphicon-chevron-left:before, .home0119 .banner .carousel-control .glyphicon-chevron-right:before {
    width: 15px;
    height: 15px;
}


	
.home0119 .c-banner-layout-2 .c-indicatori-slide .o-bullet:hover {
	background-color: #264D7A!important;
}
.home0119 .c-banner-layout-2 .c-indicatori-slide .o-bullet {
    height: 12px;
    width: 12px;
}

.home0119 .c-banner-layout-2 .carousel-indicators .o-bullet.active {
	background-color: #A0A0A0;
}

.home0119 .banner .c-indicatori-slide {
	position:relative;
	bottom: 0;
    margin-top: 0px;
}
.home0119 .c-banner-layout-2 .c-indicatori-slide .o-bullet {
	border:1px solid #A0A0A0;
    background-color: #fff;
}

.home0119 .flex-direction-nav .flex-prev {
    left: 50px;
}
.home0119 .flex-direction-nav .flex-next {
    right: 50px;
}

.home0119 .carousel-catalogo-2 .c-box-item {
    min-height: 445px;
}

.home0119 .carousel-fade .carousel-inner .item { background: #b2ce27 }
/*
  Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
  CSS from:       http://codepen.io/transportedman/pen/NPWRGq
  and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
  Inspired from:  http://codepen.io/Rowno/pen/Afykb 
*/

.home0119 .carousel-inner, .home0119 .carousel-fade .carousel-inner .item {
	min-height: 0px
}

.home0119 .carousel-fade .carousel-inner .item {
  opacity: 0;
}

.home0119 .carousel-fade .carousel-inner .active {
  	-webkit-animation: fade-slide 2.5s 1; /* Safari 4+ */
	-moz-animation: fade-slide 2.5s 1; /* Fx 5+ */
	-o-animation: fade-slide 2.5s 1; /* Opera 12+ */
	animation: fade-slide 2.5s 1; /* IE 10+, Fx 29+ */
}

.home0119 .carousel-inner .c-slider-background img { width: 100% }

/* Carousel Fading slide */
.home0119 .carousel-fade .carousel-inner { background: #fff; }
.home0119 .carousel-fade .carousel-control { z-index: 2; }  
.home0119 .carousel-fade .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  }

.home0119   .carousel-fade .next.left,
.home0119   .carousel-fade .prev.right,
.home0119   .carousel-fade .item.active { opacity: 1; }

.home0119   .carousel-fade .active.left,
.home0119   .carousel-fade .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
    }


/* Safari Fix */
@media all and (transform-3d), (-webkit-transform-3d) {
    .home0119 .carousel-fade .carousel-inner > .item.next,
    .home0119 .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .home0119 .carousel-fade .carousel-inner > .item.prev,
    .home0119 .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .home0119 .carousel-fade .carousel-inner > .item.next.left,
    .home0119 .carousel-fade .carousel-inner > .item.prev.right,
    .home0119 .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}


/*.home0119 .carousel-fade .carousel-inner .active.left .c-slider-background,
.home0119 .carousel-fade .carousel-inner .active.right .c-slider-background {
  left: 0px;
  	-webkit-animation: fade-slide-out2 0.8s 1; /* Safari 4+ * /
	-moz-animation: fade-slide-out2 0.8s 1; /* Fx 5+ * /
	-o-animation: fade-slide-out2 0.8s 1; /* Opera 12+ * /
	animation: fade-slide-out2 0.8s 1; /* IE 10+, Fx 29+ * /
  z-index: 1;
}

.home0119 .carousel-fade .carousel-inner .item {
  left: 0px;
  right: 0px;
}
.home0119 .carousel-fade .carousel-inner .active.left,
.home0119 .carousel-fade .carousel-inner .active.right {
  left: 0px;
  right: 0px;
  	-webkit-animation: fade-slide-out2 2.5s 1; /* Safari 4+ * /
	-moz-animation:    fade-slide-out2 2.5s 1; /* Fx 5+ * /
	-o-animation:      fade-slide-out2 2.5s 1; /* Opera 12+ * /
	animation:         fade-slide-out2 2.5s 1; /* IE 10+, Fx 29+ * /
  z-index: 1;
}

.home0119 .carousel-fade .carousel-inner .next.left,
.home0119 .carousel-fade .carousel-inner .prev.right {
  	-webkit-animation: fade-slide 2.5s 1; /* Safari 4+ * /
	-moz-animation:    fade-slide 2.5s 1; /* Fx 5+ * /
	-o-animation:      fade-slide 2.5s 1; /* Opera 12+ * /
	animation:         fade-slide 2.5s 1; /* IE 10+, Fx 29+ * /
}

.home0119 .carousel-fade .carousel-control {
  z-index: 2;
}

.home0119 .flex-direction-nav, ul.flex-direction-nav {
	position: absolute !important;
    top: 50%;
    width: 100%;
}

@-webkit-keyframes fade-slide {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes fade-slide {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes fade-slide {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fade-slide {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fade-slide-out2 {
  0%   { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}
@-moz-keyframes fade-slid-out2 {
  0%   { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}
@-o-keyframes fade-slide-out2 {
  0%   { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes fade-slide-out2 {
  0%   { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}


@-webkit-keyframes fade-slide-out {
  50%   { opacity: 1; }
  100% { opacity: 0; }
}
@-moz-keyframes fade-slid-out {
  50%   { opacity: 1; }
  100% { opacity: 0; }
}
@-o-keyframes fade-slide-out {
  50%   { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fade-slide-out {
  50%   { opacity: 1; }
  100% { opacity: 0; }
}
*/

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/

    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
@media all and (transform-3d), (-webkit-transform-3d) {}



@media (max-width: 992px) {
	.home0119 .banner .carousel-control { display: none }
	.home0119 .banner .carousel-caption2  { 
		position: absolute;
    	width: calc(100% - 30px);
    	margin: 0 15px;
    	transform: translateY(0);
    	padding-bottom: 0;
    	top: 90%;
    	border: 1px solid transparent;
     }
	.home0119 .banner .carousel-caption2 .interno { 
		position: relative;
		top: -8px;
    	transform: inherit;
     }
     .home0119 .banner .c-indicatori-slide {
     	position: relative;
     	bottom: 0px;
     	margin-top: 0
     }
     .home0119 .banner .c-indicatori-slide .o-bullet { 
     	width: 15px;
    	height: 15px;
    	border: 0;
    	margin: 5px;
    	background: #acacac;
     }
     .home0119 .banner .c-indicatori-slide .o-bullet.active { 
    	background: #264d7a;
     }
     .home0119 .banner .c-indicatori-slide .o-bullet span {
     	display: none 
     }
     .home0119 .banner .carousel-indicators {
		position: absolute;
		bottom: 5px !important;
		left: 50% !important;
		width: auto;
		padding-left: 0;
		margin-left: 0;
		text-align: center;
		list-style: none;
		transform: translateX(-50%);
	}
	.home0119 .c-indicatori-slide .container { width: 100%; margin:0 }
	.home0119 .banner .carousel-caption2 p.infotesto {
    	margin-left: 0%
    }
    .home0119  .banner .flex-direction-nav { display:none }
    
	.carousel { 
	    padding-bottom: 50px;
	}
	.home0119 .c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green .c-blocco-testo {
	    background: rgba(38,77,122,1) !important;
	}
	.home0119 .c-fascia-evidenza.c-fascia-evidenza-layout-d.c-fascia-evidenza-green {
    	background-position: left center;
	}
	
}
 
@media (max-width:480px) {
	
	.home0119 .banner .carousel-caption2  { 
    	top: 85%;
    	height: 200px;
     }
	.home0119 .banner .carousel-caption2 {
		/*position: absolute;
		width: calc(100% - 30px);
		margin: 0px auto;
		right: 0px !important;
		left: 0px !important;
		bottom: 15px !important;
		top: 15px; 
		background: rgba(0,0,0,1) !important;*/
	}
	.home0119 .banner .item {
    	padding: 0px 0;
    	background-size: 150%;
    	background-repeat: no-repeat;
    	background-position: top center
	}
	/*.home0119 .banner .carousel-indicators {
    	bottom: 66px !important;
    }*/
	/*.banner .carousel-caption h2 {
		font-size: 4.5rem;
	}
	.banner .carousel-caption h3 {
		font-size: 1.6rem;
	}*/
	.home0119 .c-news-verde-col .c-news-verde.c-news-palette {
		width: 100%
	}
}

@media (min-width: 992px) {
	.home0119 .carousel-indicators { display: none }
}
/*.home0119 .banner .c-indicatori-slide {
    position: relative;
    bottom: 0px;
    margin-top: 70px;
}
.home0119 .banner .carousel-indicators {
	bottom:-50px;
}

.home0119 .banner .carousel-indicators li, .home0119 .c-banner-layout-2 .c-indicatori-slide .o-bullet:nth-last-of-type(1) {
	border-radius: 0;
    border: 1px solid #a0a0a0;
	width:12px;
	height:12px;
	margin:0 2px;
}

.home0119 .banner .carousel-indicators li.active {
	background-color:#a0a0a0;
	border-color:transparent;
	margin:0 2px;
}
.home0119 .banner .c-indicatori-slide .o-bullet:hover, .home0119 .banner .c-banner-layout-2 .carousel-indicators .active:hover {
	background: red
}
.home0119 .banner .c-banner-layout-2 .c-indicatori-slide .o-bullet {
	background:#fff;
}*/


.home0119 .c-main-content {
  padding: 0;
}

.home0119 .c-news {
  padding: 0 0 25px;
}

.home0119 .banner.nopadding.c-main-content {
  padding: 0 0 80px;
}

@media (max-width: 400px) {
  .home0119 .banner.nopadding.c-main-content {
    padding: 0 0 110px;
  }
}