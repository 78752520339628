@font-face {
	font-family: 'fontawesome';
	src:url('../fonts/fontawesome/fontawesome.eot?-e43dk9');
	src:url('../fonts/fontawesome/fontawesome.eot?#iefix-e43dk9') format('embedded-opentype'),
		url('../fonts/fontawesome/fontawesome.woff?-e43dk9') format('woff'),
		url('../fonts/fontawesome/fontawesome.ttf?-e43dk9') format('truetype'),
		url('../fonts/fontawesome/fontawesome.svg?-e43dk9#fontawesome') format('svg');
	font-weight: normal;
	font-style: normal;
} /* Made with http://icomoon.io/app */

/* General style */
.grid-gallery ul {
	list-style: none;
	margin: 0;
	padding: 0;
    width: 100%;
    margin-left: 0px;
}

.grid-gallery figure {
	margin: 0;
}

.grid-gallery figure img {
	display: block;
	width: 100%;
    transition: opacity .3s, transform .3s;
}

.grid-gallery figure .c-grid-preview {
	position: relative;
	display: block;
	list-style: none;
	text-transform: uppercase;
	color: white;
	transition: background .3s;
	text-align: center;
	font-weight: 400;
	background-color: #b2ce27;
}

.grid-gallery figure .c-grid-preview:hover {
	cursor: pointer;
}

.grid-gallery figure:hover .c-grid-preview img {
	-ms-transform: scale(0.85);
	transform: scale(0.85);
}

.grid-gallery figcaption h3 {
	margin: 0;
	padding: 0 0 0.5em;
	color:#21436d;
	font-size: 1.8rem;
	-webkit-transition: color 0.2s;
	transition: color 0.2s;
}
.grid-gallery figcaption h3 a, .grid-gallery figcaption h3 a:hover {
	border-bottom: 0px
}
.grid-wrap figure:hover h3, .grid-wrap figure:hover h3 a,
.grid-wrap figcaption h3:hover, .grid-gallery figcaption h3:hover a  {
	color:#b2ce27;
}

.grid-gallery figcaption p {
	margin: 0;
	font-size: 1.7rem;
}

.grid-gallery p.c-gallery-cta {
	text-align: right;
    margin: 10px 0 0 0 !important;
}

/* Grid style */
.grid-wrap {
	margin: 0 auto;
	padding: 0;
}

.grid {
	margin: 0 auto;
}

.grid li {
	width: 25%;
	float: left;
	cursor: pointer;
}

.grid figure {
	padding: 15px;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.grid figcaption {
	background: #f5f5f5;
	padding: 25px;
	word-break: break-word;
}

/* Slideshow style */
.slideshow {
	position: fixed;
	background: rgba(0,0,0,0.6);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
	transition: opacity 0.5s, visibility 0s 0.5s;
}

.slideshow-open .slideshow {
	opacity: 1;
	visibility: visible;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}
.slideshow-open .slideshow.slideshow-none {
	opacity: 0;
	visibility: hidden;
}
.slideshow ul {
	width: 100%;
	height: 100%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: translate3d(0,0,150px);
	transform: translate3d(0,0,150px);
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.slideshow ul.animatable li {
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.slideshow-open .slideshow ul {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.slideshow li {
	width: 660px;
	height: 560px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -280px 0 0 -330px;
	visibility: hidden;
}

.slideshow li.show {
	visibility: visible;
}
.slideshow.slideshow-none li.show {
	visibility: hidden;
}
.slideshow li:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255,255,255,0.8);
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

.slideshow li.current:after {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.3s, visibility 0s 0.3s;
	transition: opacity 0.3s, visibility 0s 0.3s;
}

.slideshow figure {
	width: 100%;
	height: 100%;
	background: #fff;
	border: 30px solid #fff;
	overflow: scroll;
}

.slideshow figcaption {
	padding-bottom: 20px;
}

.slideshow figcaption h3 {
	font-weight: 300;
	font-size: 200%;
}

/* Navigation */

.slideshow nav span.nav-prev {
	left: 50px;
}

.slideshow nav span.nav-next {
	right: 50px;
}

.slideshow nav span.nav-prev ,
.slideshow nav span.nav-next {
  text-decoration: none;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius:50px;
  margin: -25px 0 0 0;
  position: absolute;
  top: 50%;
  z-index: 10;
  opacity: 1;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  font-size:0;
  border:none !important;
}
.slideshow nav span.nav-prev.disabled ,
.slideshow nav span.nav-next.disabled {
	opacity: 0.3;
}

.slideshow nav span.nav-prev:after ,
.slideshow nav span.nav-next:after {
  content: '';
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius:50px;
  background:#f1f1f1;
  -webkit-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.slideshow nav span.nav-prev:hover:after ,
.slideshow nav span.nav-next:hover:after {
  border:none;
  -ms-transform: scale(1.3, 1.3);
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.slideshow nav span.nav-prev:before ,
.slideshow nav span.nav-next:before {
  content: '';
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  z-index:998;
}

.slideshow nav span.nav-prev:before {
  background:url(../images/sx_single.svg) no-repeat center;
}

.slideshow nav span.nav-next:before {
  background:url(../images/dx_single.svg) no-repeat center;
}

.slideshow nav span.nav-close {
	position:fixed;
    top: 65px;
    right: 25px;
	padding: 0.5em 1em;
	color: #31373a;
	cursor:pointer
}

span.nav-close:before{
	position:absolute;
    content: '';
	width: 4px;
	top: 50%;
	height: 45px;
	left: 50%;
	background-color: #ffffff;
	border: 0;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
	-ms-transform: translate(-50%, -50%) rotate(45deg);
	transform: translate(-50%, -50%) rotate(45deg);
}

span.nav-close:after{
	position:absolute;
    content: '';
	width: 4px;
	height: 45px;
	top: 50%;
	left: 50%;
    background-color: #ffffff;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
	-ms-transform: translate(-50%, -50%) rotate(-45deg);
	transform: translate(-50%, -50%) rotate(-45deg);
}


/* Info on arrow key navigation */
.info-keys {
	position: fixed;
	top: 10px;
	left: 10px;
	width: 130px;
	font-size: 8px;
	padding-top: 20px;
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 1px;
	text-align: center;
}

.info-keys:before,
.info-keys:after {
	position: absolute;
	top: 0;
	width: 16px;
	height: 16px;
	border: 1px solid #fff;
	text-align: center;
	line-height: 14px;
	font-size: 12px;
	font-family: fontawesome;
}

.info-keys:before {
	left: 10px;
	content: "\e603";
}

.info-keys:after {
	right: 10px;
	content: "\e604";
}

/* Example media queries (reduce number of columns and change slideshow layout) */

@media screen and (max-width: 60em) {
	/* responsive columns; see "Element sizing" on http://masonry.desandro.com/options.html */
	.grid li {
		width: 33.3%;
	}

	.slideshow nav span.nav-prev, .slideshow nav span.nav-next {
    	top: auto;
    	bottom: 30px;
	}
	.slideshow nav span.nav-prev {
    	left: 20px;
	}
	.slideshow nav span.nav-next {
    	right: 20px;
	}
	.info-keys { display:none }
	.slideshow nav span.nav-close:after {
		content: none
	
	}
	.slideshow-open .slideshow ul { margin-left: 0 }
	.slideshow li {
		width: 100%;
    	height: 100%;
    	top: 50px;
    	left: auto;
    	margin: 0;
	}
	.slideshow nav span.nav-close {
		position: absolute;
		top: 0;
		right: 0;
		background: #fff;
		border-bottom: 1px solid #21436d;
		width: 100%;
		height: 55px;
		text-align: left;
	}
	span.nav-close:before {
		position: relative;
		content: '';
		width: 50px;
		top: auto;
		height: 20px;
		left: auto;
		background-color: #ffffff;
		border: 0;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		display: inline-block;
		background: url(../images/freccia-scropri.svg) no-repeat center center;
		background-size: contain;
		margin: 15px;
	}
}

@media screen and (max-width: 35em) {
	.grid li {
		width: 50%;
	}
}

@media screen and (max-width: 24em) {
	.grid li {
		width: 100%;
	}
} 