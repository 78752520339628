/* General
   ---- */

   .carousel-catalogo {
	margin-bottom: 32px;
}

.carousel-catalogo .carousel-inner {
	display:none;
}

/* Box item
   ---- */

.carousel-catalogo .c-box-item {
	background:#f5f5f5;
	height:100%;
	text-align:center;
	padding:18px 17px;
	margin-top:50px;
	min-height: 210px;
}

.carousel-catalogo .c-box-item img {
	display:block;
	margin:auto;
	max-width:100%;
}

.carousel-catalogo .c-box-item img.o-last-image {
	margin-top:15px;
}

.carousel-catalogo .c-box-item h3 {
	color: #264d7a;
	font-family: 'Roboto', sans-serif;
	margin: 20px 0 0 0;
	font-size: 2.5rem;
}

.carousel-catalogo .c-box-item p {
	font-family: 'Roboto Slab', cursive;
	margin: 12px 0;
	font-weight: 500;
	font-size: 1.6rem;
}

/* Button
   ---- */

.carousel-catalogo .o-btn {
	position:absolute;
	bottom:18px;
	left:50%;
	display:inline-block;
	-webkit-transform: translate(-50%, 5px);
	-moz-transform: translate(-50%, 5px);
    -ms-transform: translate(-50%, 5px);
	-o-transform: translate(-50%, 5px);
    transform: translate(-50%, 5px);
    padding-top: 7px;
    padding-bottom: 7px;
    -webkit-transition: background-color .5s, color .5s;
    transition: background-color .5s, color .5s;
	border-bottom:none;
	white-space: nowrap;
}

.carousel-catalogo .o-btn, 
.carousel-catalogo .o-btn:visited {
	background: #b2ce27;
    border: none!important;
	border-radius: 0;
	bottom: 18px;
    color: #264d7a!important;
    display: inline-block;
    font-family: Roboto,sans-serif;
    font-size: 1.4rem;
	font-weight: 700;
	left: 50%;
    line-height: 2.5rem;
    margin: 0 25px 0 0;
    min-height: 50px;
    outline: 0;
    padding: 12px 25px;
    position: absolute;
    text-decoration: none;
    text-align: center;
	text-transform: uppercase;
	-webkit-transform: translate(-50%,5px);
    transform: translate(-50%,5px);
    -webkit-transition: background-color .3s;
	transition: background-color .3s;
}

.carousel-catalogo .o-btn:hover, 
.carousel-catalogo .o-btn:active, 
.carousel-catalogo .o-btn:active:hover, 
.carousel-catalogo .o-btn:active:focus, 
.carousel-catalogo .o-btn:focus {
	background: #264d7a;
    color: #fff!important;
}

/* Indicators
   ---- */

.carousel-catalogo .carousel-indicators {
	bottom:-50px;
}

.carousel-catalogo .carousel-indicators li {
	background:#fff;
	border-radius: 0;
    border: 1px solid #868685;
	width:12px;
	height:12px;
	margin:0 2px;
}

.carousel-catalogo .carousel-indicators li.active {
	background-color:#868685;
	border-color:transparent;
	margin:0 2px;
}

/* Carousel Layout 1
   ---- */

.carousel-catalogo-1 .c-box-item {
	min-height:500px;
}


/* Carousel Layout 2
   ---- */

.carousel-catalogo-2 .c-box-item {
	min-height:410px;
}

/* Carousel Layout 3
   ---- */

// .carousel-catalogo-3 .c-box-item {
// 	min-height:300px;
// }