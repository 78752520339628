.menuinterno .c-scroll-content {
    width: 33% !important;
    left: -33% !important;
}
.menuinterno.is-open .o-scroll-close {
    left: calc(33.33% + 15px) !important;
}

.menuinterno.is-open .c-scroll-content {
	left:0 !important;
}

@media (max-width: 767px) { 
	.menuinterno .c-scroll-content {
		width: 80% !important;
		left: -80% !important;
	}
	.menuinterno.is-open .o-scroll-close {
		left: calc(80% + 15px) !important;
	}
}
