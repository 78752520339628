body {
	height:auto !important;
}

.c-table-header h6.o-titletable {
    color: #264D7A;
}
.c-table-form.c-contact-form {
    padding: 0;
    margin: 0;
    border: none;
}

.c-table-form.c-contact-form:before {
    display: none;
	visibility:hidden
}

.c-table-form.c-contact-form input {
    height: auto;
}

.c-table-form.c-contact-form .c-form-group ,
.c-table-form.c-contact-form .c-form-group {
    width: 100%;
    margin: 0;
    text-align: left;
    position: relative;
    padding-top: 15px;
}
.c-table-form.c-contact-form span.o-placeholder ,
.c-tabella-dati.c-contact-form span.o-placeholder {
    top: 50%;
    height: auto;
    line-height: 1.3rem;
    font-size: 1.1rem;
    -ms-transform: translate(0);
    -webkit-transform: translate(0);
    transform: translate(0);

}

.c-table-form.c-contact-form input.is-filled ~ span.o-placeholder,
.c-table-form.c-contact-form input:active ~ span.o-placeholder ,
.c-table-form.c-contact-form input:focus ~ span.o-placeholder  {
    background: #fff;
    -ms-transform: translate(0, -18px);
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    z-index: 801;
}

.c-tabella-dati .c-table-form.c-contact-form input.is-filled ~ span.o-placeholder,
.c-tabella-dati .c-table-form.c-contact-form input:active ~ span.o-placeholder ,
.c-tabella-dati .c-table-form.c-contact-form input:focus ~ span.o-placeholder {
    background: #fff;
    -ms-transform: translate(0, -21px);
    -webkit-transform: translate(0, -21px);
    transform: translate(0, -21px);
    z-index: 801;
}

thead.c-filter tr td input[type=text] ~ span ~ i.c-icon-close {
    height: 15px;
    right: 2px;
    top: 12px;
    width: 15px;
}

.c-tabella-dati-wrapper.no-before:before,
.c-tabella-dati-wrapper.no-after:after {
	display: none;
}

.c-tabella-dati-wrapper .table-responsive {
    overflow: initial;
}

tbody a.c-circle-button {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 20px;
    height: 20px;
    background: url(../images/check-blu.svg) no-repeat;
    -webkit-transition: background-image 0.3s;
    -moz-transition: background-image 0.3s;
    -ms-transition: background-image 0.3s;
    -o-transition: background-image 0.3s;
    transition: background-image 0.3s;
    border: none;
}


.c-tabella-dati thead td {
    font-family: 'Roboto', serif;
    font-size: 1.4rem;
	color:#264d7a;
	line-height: 1.2;
    padding: 0 5px;
}

.c-tabella-dati thead td span.o-placeholder{
    font-weight: 400;
}

.c-tabella-dati tbody td {
    font-family: 'Roboto', serif;
	font-weight:300;
    font-size: 1.4rem;
	color:#222;
	position:relative
}

.c-tabella-dati tbody td:before {
    background-color: #949494;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: -2px;
    position: absolute;
    right: -2px;
}
.c-tabella-dati tbody td:nth-child(1):before {
    left: 0;
}
.c-tabella-dati tbody td:last-child:before {
    right: 0;
}
.c-tabella-dati tbody td.white-bg:before {
    display: none;
}

.c-tabella-dati tbody td span {
	color:#264D7A;
}

table.collapse-capogruppo tr td {
	border: none;
	font-weight: 500;
}

.c-tabella-dati > .table-responsive tbody tr td.width-5 {
	width: 5%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-6 {
	width: 6%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-8 {
	width: 8%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-10 {
	width: 10%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-12 {
	width: 12%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-13 {
	width: 13%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-15 {
	width: 15%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-20 {
	width: 20%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-22 {
	width: 22%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-25 {
	width: 25%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-65 {
	width: 65%;
}

.c-tabella-dati > .table-responsive tbody tr td.width-85 {
	width: 85%;
}

.c-tabella-dati > .table-responsive tbody tr td.white-bg,
table.collapse-capogruppo tr td.white-bg {
	background-color: #fff;
}

.text-4d4d4f {
	color: #4d4d4f;
	font-weight: 300;
}

.c-tabella-dati table tbody tr.vertical-padding-6 td {
	padding-bottom: 6px !important;
	padding-top: 6px !important;
}

.c-tabella-dati table tr td span.caret:hover {
	color: #b2ce27 !important;
}

.c-tabella-dati > h4 span.caret.open,
.c-tabella-dati table span.caret.open {
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}


a.o-button {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 1.4rem;
	text-transform: uppercase;
    background: #b2ce27;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    -ms-transition: background .3s ease-in-out;
    -o-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
    margin: 10px 0 20px 0;
    color: #fff !important;
    padding: 15px 30px;
    text-decoration: none;
    border: none !important;
}
a.o-button:hover {
	background: #204d7c;
	color: #fff !important
}

/* fine IE11 hack */
.c-tabella-dati > .table-responsive.accordion-societa-gruppo tbody tr td:last-child > span.o-tooltip {
	opacity: 0;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}
.c-tabella-dati > .table-responsive.accordion-societa-gruppo tbody tr td:last-child:hover > span.o-tooltip {
    ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    opacity: 1.0;
}
.c-tabella-dati > .table-responsive.accordion-societa-gruppo tbody tr td:last-child:hover > span.o-tooltip:hover {
	ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
}

tr.child-item-1,
tr.child-item-2,
tr.child-item-3 {
	display: none;
}
.c-tabella-dati > .table-responsive {
	border-collapse: separate;
}
.c-tabella-dati > .table-responsive thead tr {
	display:table-row
}
.c-tabella-dati > .table-responsive tbody tr td {
	width: 12.5%;
}
.c-tabella-dati > .table-responsive tbody tr td {
	border: 2px solid #fff;
	vertical-align: middle;
	font-weight: 500;
	padding: 10px;
}
.c-tabella-dati > .table-responsive tbody tr td:first-child,
.c-tabella-dati > .table-responsive thead tr td:last-child ,
.c-tabella-dati > .table-responsive tbody tr td:first-child,
.c-tabella-dati > .table-responsive thead tr td:last-child {
	width: 5%;
}
.c-tabella-dati > .table-responsive tbody tr td:nth-child(2) ,
.c-tabella-dati > .table-responsive thead tr td:nth-child(2) {
	width: 9.5%;
}
.c-tabella-dati > .table-responsive tbody tr td:nth-child(3) ,
.c-tabella-dati > .table-responsive thead tr td:nth-child(3) {
	width: 41%;
}
.c-tabella-dati > .table-responsive thead tr td:nth-child(5),
.c-tabella-dati > .table-responsive tbody tr td:nth-child(5) ,
.c-tabella-dati > .table-responsive thead tr td:nth-child(5),
.c-tabella-dati > .table-responsive tbody tr td:nth-child(5) {
	width: 7%;
}
.c-tabella-dati > .table-responsive thead tr td:nth-child(6),
.c-tabella-dati > .table-responsive tbody tr td:nth-child(7) ,
.c-tabella-dati > .table-responsive thead tr td:nth-child(6),
.c-tabella-dati > .table-responsive tbody tr td:nth-child(7) {
	width: 10%;
}

.c-tabella-dati > .table-responsive tbody tr td.bg-92a6bc {
	background-color: #92a6bc !important;
}

.c-tabella-dati tbody tr td:nth-child(2) span.o-tooltip {
	opacity: 0;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	-webkit-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}
.c-tabella-dati tbody tr td:nth-child(2):hover span.o-tooltip {
	ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=100);
	-moz-opacity: 1.0;
	-khtml-opacity: 1.0;
	opacity: 1.0;
	-webkit-transform: translate(-50%, -20px);
	transform: translate(-50%, -20px);
}

/* IE11 hack per risolvere problemi legati ad altezze tbody td */
@media all and (-ms-high-contrast:none) {

  *::-ms-backdrop, .c-tabella-dati > h4[data-toggle='collapse'] + table thead tr td, .c-tabella-dati > h4[data-toggle='collapse'] + table tbody tr td {
	float: none;
  }
  *::-ms-backdrop, .c-tabella-dati > h4[data-toggle="collapse"] + table thead tr {
    display: table-caption;
  }
  *::-ms-backdrop, .c-tabella-dati > h4[data-toggle="collapse"] + table tbody tr {
    background-color: none;
  }

}
/* fine IE11 hack */

.c-tabella-dati table span.caret {
  border-top: 8px dashed;
  border-top: 8px solid \9;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  color: #264d7a;
  cursor: pointer;
  -moz-transition-duration: 800ms;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
}

.tr-liv-1,
.tr-liv-1 td {
  background-color: #e9edf1 !important;
}

.tr-liv-2,
.tr-liv-2 td,
.tr-liv-4-e-pari,
.tr-liv-4-e-pari td {
  background-color: #f8f9fa !important;
}

.tr-liv-3,
.tr-liv-3 td,
.tr-liv-5-e-dispari,
.tr-liv-5-e-dispari td {
  background-color: #fff !important;
}

.c-tabella-dati > table tbody tr td:nth-child(5) > span.o-tooltip {
  opacity: 0;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transition: all .3s linear;
       -o-transition: all .3s linear;
          transition: all .3s linear;
}
.c-tabella-dati > table tbody tr td:nth-child(5):hover > span.o-tooltip {
    ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    opacity: 1.0;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
}
.c-tabella-dati > table tbody tr td:nth-child(5):hover > span.o-tooltip:hover {
	ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0);
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;
}

.icona-info {
	background: url('../images/icona-info-grigia.svg') no-repeat top left;
	cursor: pointer;
	display: inline-block;
	height: 20px;
	margin: 0;
	padding: 0;
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	width: 20px;
	position:relative;
}

.c-main-content a.icona-info{
	border-bottom: none
}

.icona-info:hover,
.icona-info:focus,
.icona-info:active {
	background: url('../images/icona-info-verde.svg') no-repeat top left;
}
div.info-tooltip {
	background-color: #fff;
	border-radius: 25px;
	border-right: 8px solid #b2ce27;
	display: none;
	font-size: 1.3rem;
	left: -500px;
	padding: 0 20px;
	position: absolute;
	text-align: left;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	transform: translate(0, -50%);
	width: 500px;
	z-index: 801;
}
div.info-tooltip:after {
	content: '';
	display: block;
	position: absolute;
	top: 48%;
	right: -20px;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	border-top: 8px solid #b2ce27;
	-ms-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
/* IE11 hack per centrare correttamente freccia di div.info-tooltip con l'icona info */
@media all and (-ms-high-contrast:none) {

  *::-ms-backdrop,
  div.info-tooltip:after {
    top: 49.5%;
  }

}
/* fine IE11 hack */
.info-tooltip-open {
	display: block !important;
}
.c-tabella-dati > table tbody tr td div.info-tooltip table td {
	background-color: #fff !important;
	border: none;
	padding: 0 5px;
	vertical-align: top;
}

.c-tabella-dati > table tbody tr td div.info-tooltip table td:first-child,
.c-tabella-dati > table tbody tr td div.info-tooltip table td:nth-child(2n+3) {
	color: #264d7a;
}
.c-tabella-dati > table tbody tr td div.info-tooltip table td:first-child {
	width: 20%;
}
.c-tabella-dati > table tbody tr td div.info-tooltip table td:nth-child(2) {
	width: 80%;
}
.c-tabella-dati > table tbody tr td div.info-tooltip table tr:last-child td:nth-child(2),
.c-tabella-dati > table tbody tr td div.info-tooltip table tr:last-child td:nth-child(5),
.c-tabella-dati > table tbody tr td div.info-tooltip table tr:last-child td:nth-child(6) {
	width: 16% !important;
}
.c-tabella-dati > table tbody tr td div.info-tooltip table tr:last-child td:nth-child(3) {
	width: 10% !important;
}
.c-tabella-dati > table tbody tr td div.info-tooltip table tr:last-child td:nth-child(4) {
	width: 22%;
}

.info-tooltip table td:before {
    display: none;
}


.c-table-form {
    padding: 0;
    border: 0px solid #264d7a;
    position: relative;
    margin: 1px 0;
    width: 100%;
    display: contents;
}

.c-table-form:before {  
    display: none
}

.c-table-dati thead {
	border-bottom:none;
}

.c-table-dati thead td,
.c-table-dati thead td:last-child {
    width: 13.5%;
	padding: 0 10px 0 0;
}

.c-table-dati thead tr td:last-child {
	padding-right:0;
}

.c-ricerca-form {
	display: none;
}

.c-ricerca-form .icons {
    position: absolute;
    right: 55px;
    top: -15px; 
}
.c-ricerca-form .icons img {
    background: #fff;padding: 3px
}
.c-ricerca-form .icons p {
    display: inline;
    float: left;
    position: relative;
    margin-right: 10px;
}
.c-ricerca-form .icons .o-tooltip.o-tooltip-top {
    top: -42px;
}

.c-filter .c-contact-form input {
    height: auto;
}

.c-table-dati input {
    border: 0px;
    background: transparent;
    font-size: 2rem;
}

.c-table-dati .c-contact-form input.o-date {
    background-image: none;
}

.c-freccette, .c-freccette-2 {
    text-align: right;
    margin: 10px;
}

.c-freccette * {
    visibility: hidden;
}
.c-freccette .o-freccetta-dx, .c-freccette-2 .o-freccetta-dx, .c-freccette-3 .o-freccetta-dx{
    transform: rotate(180deg);
    left: -6px;
    position: relative;
}



.c-freccette-2 { display: inline }

.c-freccette-2 img {
	margin: 0;
    width: 18px
}

.c-freccette-2 a {
	-webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.c-freccette-2 a:hover  {
	opacity: .5;
	display: inline;
}
.c-freccette-2 a:hover img  {
	display: inline;
}

.c-freccette-2 a img {
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


.c-freccette-2 a.o-sel img {
	-webkit-transform: scale(1.6);
    -ms-transform: scale(1.6);
    transform: scale(1.6);
}
.c-freccette-2 a.o-sel img.o-freccetta-dx {
	-webkit-transform: rotate(180deg) scale(1.6);
    -ms-transform: rotate(180deg) scale(1.6);
    transform: rotate(180deg) scale(1.6);
}

.c-freccette-3 {
    display: inline;
    margin-left: 10px;
    position: relative;
}

.c-freccette-3 a {
    background: no-repeat center center;
    background-image: url(../images/freccia.svg);
    display: inline-block;
    height: 8px;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    top: 16px;
    width: 12px;
}

.c-freccette-3 a:nth-child(1) {
    transform: rotate(180deg);
}

.c-freccette-3 a:hover  {
    background-image: url(../images/freccia_verde.svg);
}

.c-freccette-3 a.o-sel {
	-webkit-transform: scale(1.6);
    -ms-transform: scale(1.6);
    transform: scale(1.6);
}
.c-freccette-3 a.o-sel:nth-child(1) {
    -webkit-transform: scale(1.6) rotate(180deg);
    -ms-transform: scale(1.6) rotate(180deg);
    transform: scale(1.6) rotate(180deg);
}


td:hover .c-freccette *, 
td .c-freccette .visibile, 
td .c-freccette .visibile * {
    visibility: visible;
}

.c-freccette > a[data-target="#generafiltri"] .o-filter.hover,
.c-freccette > a[data-target="#generafiltri"]:hover .o-filter.default,
.c-freccette > a:nth-child(2) .o-freccetta-dx.hover,
.c-freccette > a:nth-child(2):hover .o-freccetta-dx.default {
    display: none;
}
.c-freccette > a[data-target="#generafiltri"]:hover .o-filter.hover,
.c-freccette > .o-freccetta-dx:hover,
.c-freccette > a:nth-child(2):hover .o-freccetta-dx.hover {
    display: inline;
}

.c-table-dati .o-modificabile {
	position:relative;
}

.c-table-dati .o-modificabile:hover span {
    color: #E2E2E2;
}

.c-table-dati .o-modificabile:hover a {
    border-radius: 30px;
    display: inline-block;
    width: 25px;
    height: 25px;
    content: "";
    background: #B2CE27 url(../images/Modifica_NO-ombra.svg) no-repeat center center;
    background-size: 60%;
    position: absolute;
    right: 45%;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
	
	.table-responsive,
	.c-tabella-dati table {
		display:block
	}
	
	.c-tabella-dati-wrapper .table-responsive {
		overflow-y: hidden;
	}	
}