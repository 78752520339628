/* Pagination
   ---- */

   .c-pagination {
	margin: 50px auto 0;
	text-align: center;
}

.c-pagination .pagination {
	padding: 0;
}

.c-pagination .pagination > li {
	display: inline-block;
}

/* Pagination links
   ---- */

.c-pagination .pagination>li>a, 
.c-pagination .pagination>li>span {
    font-family: 'Roboto', sans-serif;
	font-weight:700;
	font-size:1.4rem;
	width: 34px;
	height: 34px;
	line-height:32px;
	text-align:center;
	margin:0 12.5px 0 12.5px;
	padding:0;
	border-color: #264d7a;
	color:#264d7a;
	-webkit-border-radius: 0px ;
	-moz-border-radius: 0px ;
	border-radius: 0px ;
    -webkit-transition: background-color 0.3s, color 0.3s ;
    -moz-transition: background-color 0.3s, color 0.3s ;
    -ms-transition: background-color 0.3s, color 0.3s ;
    -o-transition: background-color 0.3s, color 0.3s ;
    transition: background-color 0.3s, color 0.3s, color 0.3s ;
}

.c-pagination .pagination>li>a:focus,
.c-pagination .pagination>li>a:hover,
.c-pagination .pagination>li.c-current-page>a:focus,
.c-pagination .pagination>li.c-current-page>a:hover {
    color: #264d7a;
    background-color: #a3bf19;
    border-color: #a3bf19;
    -webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s ;
    -moz-transition: background-color 0.3s, border-color 0.3s, color 0.3s ;
    -ms-transition: background-color 0.3s, border-color 0.3s, color 0.3s ;
    -o-transition: background-color 0.3s, border-color 0.3s, color 0.3s ;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s ;
}

.c-pagination .pagination>li:last-of-type>a, 
.c-pagination .pagination>li:last-of-type>span {
	margin-left: 0;
	margin-right: 0;
}

.c-pagination .pagination>li.c-current-page>a {
	line-height:30px;
	border:none;
	border-bottom:solid 4px #264d7a;
	background: none
}
.c-pagination .pagination>li.c-current-page>a:focus,
.c-pagination .pagination>li.c-current-page>a:hover {
	background-color: transparent;
	border-color: #264d7a;
	cursor: default;
}

/* Arrows
   ---- */

.c-pagination .pagination a.c-pagination-icon {
	border: none;
	height: 34px;
	padding: 0;
	text-align: center;
    width: 34px;
}
.c-pagination .pagination a.c-pagination-icon:focus,
.c-pagination .pagination a.c-pagination-icon:hover {
	background-color: transparent;
}

.c-pagination .pagination a.c-pagination-icon i {
	display: inline-block;
	height: 28px;
	line-height: 28px;
	width: 28px;
}

.c-pagination .pagination a.c-pagination-icon i:before {
	color: #264d7a;
	font-size:2rem;
	text-shadow: 1px 0 #264d7a;
	transition: color .3s, text-shadow .3s;
}
.c-pagination .pagination a.c-pagination-icon:focus i:before,
.c-pagination .pagination a.c-pagination-icon:hover i:before {
	color: #a3bf19;
	text-shadow: 1px 0 #a3bf19;
}

/* Mobile
   ---- */

@media (max-width: 480px) {
	
	.c-pagination .pagination a.c-pagination-icon i {
		line-height:28px;
	}
	
	.c-pagination .pagination a.c-pagination-icon i:before {
		font-size: 1.8rem;
	}
	
	.c-pagination .pagination a.c-pagination-icon {
		width: 28px;
		height: 28px;
		margin-left: -2.5px;
		margin-right: 0;
	}
	
	.c-pagination .pagination>li>a, 
	.c-pagination .pagination>li>span {
		width: 28px;
		height: 28px;
		line-height:26px;
		margin: 0 2.5px 0 2.5px;
	}

}

@media (min-width: 481px) and (max-width: 662px) {

	.c-pagination .pagination a.c-pagination-icon i {
		line-height:30px;
	}
	
	.c-pagination .pagination a.c-pagination-icon i:before {
		font-size: 1.8rem;
	}
	
	.c-pagination .pagination a.c-pagination-icon {
		width: 30px;
		height: 30px;
		margin-left: -7.5px;
		margin-right: 0;
	}
	
	.c-pagination .pagination>li>a, 
	.c-pagination .pagination>li>span {
		width: 30px;
		height: 30px;
		line-height:28px;
		margin: 0 7.5px 0 7.5px;
	}

}