.c-mappa-form {
    margin: 50px 15px 100px 15px;
    padding: 50px 30px 30px;
	width: auto;
}

.c-mappa-box .c-singola-banca {
    margin-bottom: 25px;
    border: 1px solid #dbdbdb;
    padding: 20px 35px 10px;
    color: #222;
    position: relative;
}

.c-mappa-box .c-singola-banca .c-etichetta {
    position: absolute;
    width: 8px;
    top: 20%;
    bottom: 20%;
    left: -4px;
	background: #264d7a;
}

.c-mappa-box .c-singola-banca .c-nome-banca {
    font-size: 2.4rem;
    font-weight: 400;
    font-family: 'roboto slab';
    margin-bottom: 15px;
    line-height: 1.05;
}

.c-mappa-box .c-singola-banca .c-dati-banca {
    font-size: 1.7rem;
    display: inherit;
    font-weight: 300;
    line-height: 24px;
}

.c-mappa-box .c-singola-banca .c-banca-accordion {
    display: none;
}

.c-mappa-box .c-singola-banca .c-apri-accordion {
    position: absolute;
    border-right: 37px solid #b2ce27;
    border-top: 37px solid transparent;
    display: block;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    cursor: pointer;
    transition: .3s;
    -webkit-transition: .3s;
}

.c-mappa-box .c-singola-banca .c-apri-accordion:before {
    content: "+";
    color: #264d7a;
    position: absolute;
    top: -24px;
    left: 20px;
    font-size: 2.4rem;
    font-weight: 700;
    transition: .3s;
    -webkit-transition: .3s;
}

.c-mappa-box .c-singola-banca .c-apri-accordion:hover {
    border-right-color: #264d7a;
}

.c-mappa-box .c-singola-banca .c-apri-accordion:hover:before {
    color: white;
}

.c-mappa-box .c-singola-banca .c-apri-accordion.meno:before {
    content: "-";
    font-size: 2.8rem;
    top: -26px;
    left: 21px;
}

.c-mappa-box .c-singola-banca .c-calcola-percorso {
    transition: .3s;
    -webkit-transition: .3s;
    color: #fff;
    background: #b2ce27;
    text-transform: uppercase;
    border-radius: 0;
    border: 1px solid transparent;
    font-weight: 700;
    outline: 0;
    text-decoration: none;
    height: auto;
    font-size: 1.4rem;
    padding: 4px 10px;
    margin: 5px 0 10px;
}

.c-mappa-box .c-singola-banca .c-calcola-percorso:hover {
    box-shadow: none;
    color: #b2ce27;
    background: #fff;
    border-color: #b2ce27;
}