.color-palette {
	border: 1px solid #4D4D4F;
	margin: 0 10px 15px;
	padding: 10px;
} 

.color-palette p {
    font-size: 1.8rem;
	font-weight: 300;
	line-height: 2.4rem;
    margin-bottom: 0 !important;
    padding: 0;
}

.color-palette div.row div {
	padding: 0 4px;
	font-size: 1.4rem;
    font-weight: 300;
}
.color-palette div.row.palette-mb div {
    font-weight: 500;
}
.color-palette .palette-mb {
	margin-bottom: 10px
}

.color-palette > span {
	display: block;
	margin: -10px -10px 5px -10px;
	height: auto;
    padding: 20px 10px;
}
.scala-grigio .color-palette > span {
    padding: 35px 10px;
}

.color-palette .nome {
	font-family: Roboto Slab,serif;
    color: #fff;
    font-size: 2.4rem;
    font-weight: 500;
}
.color-palette .nome.scuro { color: #222 }

.color-palette .nome span {
	float: right;
}

.color-palette .batteria-alta{
	content: url("../images/batteria-alta.svg");
	width: 30px;
	margin-top: 6px;
    margin-bottom: -3px;
}

.color-palette .batteria-media{
	content: url("../images/batteria-media.svg");
	width: 30px;
	margin-top: 6px;
    margin-bottom: -3px;
}

.color-palette .batteria-bassa{
	content: url("../images/batteria-bassa.svg");
	width: 30px;
	margin-top: 6px;
    margin-bottom: -3px;
}

.dark-text > span {
	color: #222;
}

.blu-digital > span {
	background-color: #264d7a;
}

.verde-digital > span {
	background-color: #b2ce27;
}

.verde-digital-testuale > span {
	background-color: #a3bf19;
}

.blu-digital-opaco > span {
	background-color: #92a6bc;
}

.blu-digital-2 > span {
	background-color: #e9edf2;
}

.blu-istituzionale > span {
	background-color: #1d388e;
}

.blu-istituzionale-variante > span {
	background-color: #2d4b9b;
}

.verde-digital-1 > span {
	background-color: #d9e793;
}

.verde-digital-2 > span {
	background-color: #e8f0be;
}

.verde-digital-scuro {
	background-color: #284a3d;
}

.verde-istituzionale > span {
	background-color: #00954b;
}

.rosso-alert > span {
	background-color: #ff3333;
}

.grigio-fcfbfb > span {
	background-color: #fcfbfb;
}

.grigio-f8f9fa > span {
	background-color: #f8f9fa;
}

.grigio-f5f5f5 > span {
	background-color: #f5f5f5;
}

.grigio-f1f1f1 > span {
	background-color: #f1f1f1;
}

.grigio-e7eaec > span {
	background-color: #e7eaec;
}

.grigio-d4d7d9 > span {
	background-color: #d4d7d9;
}

.grigio-bcbec0 > span {
	background-color: #bcbec0;
}

.grigio-9fa0a1 > span {
	background-color: #9fa0a1;
}

.grigio-949494 > span {
	background-color: #949494;
}

.grigio-66696c > span {
	background-color: #66696c;
}

.grigio-4d4d4f > span {
	background-color: #4d4d4f;
}

.grigio-373737 > span {
	background-color: #373737;
}

.grigio-222222 > span {
	background-color: #222222;
}

.grigio-777777 > span {
	background-color: #777777;
}

.grigio-istituzionale > span {
	background-color: #868685;
}

.grigio-808285 > span {
	background-color: #808285;
}

.blu {
	background-color: #213a8f;
}

.verde {
	background-color: #00964f;
}

.colore-primario {
	background-color: #2abb9c;
}

.variante-1 {
	background-color: #4e7777;
}

.variante-2 {
	background-color: #6da0a0;
}

.for-estero {
	background-color: #a3a0b3;
}

.pertempo {
	background-color: #8e992e;
}

.crediper {
	background-color: #8f1d24;
}

.investiper {
	background-color: #009dac;
}

.protettiper {
	background-color: #e9b400;
}

.institutional-colore-primario {
	background-color: #006b89;
}

.institutional-variante {
	background-color: #0c9ec7;
}

.corporate-colore-primario {
	background-color: #98862a;
}

.corporate-variante {
	background-color: #d9b71f;
}

.retail-colore-primario {
	background-color: #a84b22;
}

.retail-variante {
	background-color: #bba6a1;
}

.accessibile-su-bianco {
	min-height: auto !important;
}
.accessibile-su-bianco > span {
	margin-bottom: -10px;
}
.accessibile-su-bianco > span > span {
	bottom: 10px;
	color: #fff;
	font-size: 1.6rem;
    font-weight: 500;
	left: 10px;
	position: absolute;
}

@media (min-width: 1200px) {
	.color-palette {
		width: calc(33.3% - 20px);
	}

	.colori-istituzionali,
	.colori-digital,
	.varianti-digital,
	.scala-grigio,
	.grigi-su-bianco {
		position: relative;
	}

	.colori-istituzionali .nota,
	.colori-digital .nota,
	.varianti-digital .nota,
	.scala-grigio .nota,
	.grigi-su-bianco .nota {
		bottom: 0;
		right: 0;
		position: absolute;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.color-palette {
		min-height: 265px;
		width: 47%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.color-palette {
		min-height: 265px;
		width: 31%;
	}
}

@media (max-width: 767px) {
	.color-palette {
		margin: 0 50% 15px;
		transform: translateX(-50%);
	}
}