.nopaddtop { padding-top: 0 }

.c-breadcrumb2 {
	background: #F5F5F5;
}

.c-breadcrumb2 ul {
	list-style-type: none;
	margin: 0;
	font-size: 1.6rem;
	padding: 0
}

.c-breadcrumb2 ul li { 
	float: left;
	font-weight: 400;
	padding: 10px;
	position: relative;
}

.c-breadcrumb2 ul li:first-child { 
	padding: 10px;
}

.c-breadcrumb2 ul li:last-child { 
	font-weight: 700;
}
.c-breadcrumb2 ul ul li:last-child { 
	font-weight: 400;
}

.c-breadcrumb2 > nav > ul > li:last-child > a:hover { 
	color: #222;
}

.c-breadcrumb2 ul li.o-home a {
	width: 24px;
	height: 22px;
	position:relative;
	display: block;
	float: left;
}

.c-breadcrumb2 ul li.o-home a img {
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    position:absolute;
    top: 0;
    left: 0;
}

.c-breadcrumb2 ul li.o-home a:hover img,
.c-breadcrumb2 ul li.o-home a img.o-breadcrumb-hover {
	opacity: 0;
}

.c-breadcrumb2 ul li.o-home a:hover img.o-breadcrumb-hover {
	opacity: 1;
}

.c-breadcrumb2 ul li.c-breadcrumb-parent {
    padding-right: 10px;
}

.c-breadcrumb2 ul li.c-breadcrumb-parent ul {
    left: 0px !important;
}

.c-breadcrumb2 ul li.c-breadcrumb-parent ul li {
	font-size:1.6rem;
}
.c-breadcrumb2 ul li.c-breadcrumb-parent ul li.o-sel {
    font-weight: 700;
}

.c-breadcrumb2:not(.c-breadcrumb3) ul > li:not(:last-child):after {
	content: "";
    display: inline-block;
    position: absolute;
    right: 0;
    font-weight: 300;
    background: url(../images/freccia-right-grigia.png) no-repeat center center;
    width: 12px;
    height: 50%;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
}
.c-breadcrumb2:not(.c-breadcrumb3) ul ul > li:after { content: none !important }

.c-breadcrumb2 ul ul li:before {
	content: none !important;
}

.c-breadcrumb2 ul ul li {
	padding: 10px !important;
}
.c-breadcrumb2 ul li a {
	text-decoration: none
}

.c-breadcrumb2 a {
	color: #222;
}

.c-breadcrumb2 ul li.c-breadcrumb-has-dropdown {
	cursor:pointer;
	padding:0;
}

.c-breadcrumb2 ul li a {
	display: inline-block;
    outline: 0;
	-webkit-transition: background-color .3s, color .3s;
    -moz-transition: background-color .3s, color .3s;
    -ms-transition: background-color .3s, color .3s;
    -o-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
}
.c-breadcrumb2 ul li a:focus {
    border-radius: 6px;
    box-shadow: 0 0 0 3px #264d7a;
}
.c-breadcrumb2 ul li a:active {
    box-shadow: none;
}

.c-breadcrumb2 > nav > ul > li:first-child {
    padding: 0 10px 0 0;
}
.c-breadcrumb2 > nav > ul > li:first-child > a {
    padding-left: 5px;
}

.c-breadcrumb2 > nav > ul > li {
    padding: 0;
}
.c-breadcrumb2 > nav > ul > li > a {
    padding: 10px;
}

.c-breadcrumb2.disabled ul li:hover a {
   color: #222;
}

.c-breadcrumb2 a:hover,
.c-breadcrumb2 li:not(:last-child):hover > a {
    color: #264D7A;
}

.c-breadcrumb2 ul ul li a {
	-webkit-transition: transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out;
    -ms-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}
.c-breadcrumb2 ul ul li:hover a {
   color: #264D7A;
    -webkit-transform: translate(8px);
	-moz-transform: translate(8px);
    -ms-transform: translate(8px);
	-o-transform: translate(8px);
    transform: translate(8px); 
}

.c-breadcrumb2 ul ul {
display: none;
    position: absolute;
    top: 40px;
    left: 20px;
    font-size: 1.4rem;
    background: #ccc;
    z-index: 999;
    border-top: 3px solid #264D7A;
    background-color: #fff;
    width: 200px;
    -moz-box-shadow: #949494 0 2px 3px;
    -webkit-box-shadow: #949494 0 2px 3px;
    box-shadow: #949494 0 2px 3px;
}


.c-breadcrumb-children {
	position:inherit !important
}

.c-breadcrumb2 ul ul li {
	display:block;
	float:none;
	width:100%;
}

.c-breadcrumb2:not(.disabled) ul li:hover ul {
	display: block;
}

@media (max-width: 767px) {
	.c-breadcrumb2 { display: none }
}

.c-breadcrumb3 {
    height: 92px;
    margin-bottom: 10px;
}

.c-breadcrumb3 ul .c-breadcrumb-children ul {
    left: 0;
	display:block;
	width:100%;
	z-index:898;
	padding-left:15px;
    -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
    top: 40px;
	border: 0px
}

.c-breadcrumb3 ul li.c-breadcrumb-parent {
    padding-right: 30px;
}

.c-breadcrumb3 ul li.c-breadcrumb-parent {
	background:#fff;
	padding-left:30px;
}


.c-breadcrumb3 ul li:not(.c-breadcrumb-parent):not(.c-breadcrumb-children):not(:nth-last-child(3)) { 
    background: url("../images/freccia-dx-bianca.svg") no-repeat right center;
    padding-right: 30px
}
.c-breadcrumb3 ul ul li { background: none !important; padding-right: 10px }

.c-breadcrumb3 ul li.o-sel, .c-breadcrumb3 ul ul li.o-sel:last-child {
	font-weight: 500
}

.c-breadcrumb3 ul li.c-breadcrumb-parent:before {
	    width: 10px;
    height: 10px;
    border-style: solid;
    border-width: 21px 0 21px 15px;
    border-color: transparent transparent transparent #F5F5F5;
    line-height: 0px;
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translate(0px, -50%) rotate(0deg);
}

.c-breadcrumb3 ul li.c-breadcrumb-parent:after {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 21px 0 21px 15px;
	border-color: transparent transparent transparent #fff;
	line-height: 0px;
	content: "";
	position: absolute;
	top:0;
	right:-15px;
	transform: translateY(0%);
}

.c-breadcrumb3 ul .c-breadcrumb-children ul li {
	display:inline-block;
	width:auto;
	padding:15px 15px 15px 10px !important
}
.c-breadcrumb3 ul .c-breadcrumb-children ul li:not(:last-child) {
	margin-right: 15px 
}

.c-breadcrumb3 ul ul li:hover a {
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
}