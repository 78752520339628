.modal-body { padding: 60px; padding-bottom: 0px; }
.modal-body img { width: 100% }
.modal-content {
    border-radius: 0px;
}
.modal-footer {
    padding: 0 60px 40px 60px;
    text-align: left;
    border-top: 0px solid #e5e5e5;
    font-weight: 300;
    color: #222;
    font-size: 1.6rem
}
.modal-footer h3 {
	font-size: 2.25rem;
    color: #264d7a;
    margin: 0 0 10px 0
}
.modal-footer p { margin: 0 }
.modal-header {
    padding: 0px;
    border-bottom: 0px solid #e5e5e5;
}
/*.modal-header .close {
    margin-top: -2px;
    position: absolute;
    left: 105%;
    top: 0px;
}*/


.modal-header .close:before {
    position: absolute;
    content: '';
    width: 4px;
       top: 50%;
    height: 45px;
    left: 50%;
    background-color: #ffffff;
    border: 0;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}
.modal-header .close:after {
    position: absolute;
    content: '';
    width: 4px;
       top: 50%;
    height: 45px;
    left: 50%;
    background-color: #ffffff;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    -ms-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.modal-header .close span {
    position: absolute;
    content: '';
    top: 0;
    font-size: 0;
    background-color: #fff;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
.modal-header .close {
    // position: fixed;
    position: absolute;
    font-size: 0;
    content: '';
    display: block;
    padding: 0;
	top: 2%;
    left: 102%;
    margin-left: 0;
    height: 33px;
    width: 33px;
    border: none;
    background: transparent;
    z-index: 900;
    opacity: 1;
}


.modal-title { display: none }

@media (max-width: 767px) {
	.modal-lg {
	    width: 90% !important;
	}
}
@media (min-width: 768px) {
	.modal-lg {
	    width: 80% !important;
	}
}
@media (min-width: 1200px) {
	.modal-lg {
	    width: 1100px !important;
	}
}


@media (max-width: 767px) {  
	.modal .close:before, .modal .close:after {
		display: none
	}
	.modal .close span { content: normal }
	.modal .close span {
		position: relative;
		content: normal;
		font-size: 1.8rem;
		background: transparent;
	}
	.modal .close {
		font-size: 1.8rem;
		content: normal;
		display: block;
		padding: 10px;
		top: 0%;
		margin-left: 0;
		height: auto;
		width: 100%;
		background-color: #b2ce27;
		color: #222;
		text-shadow: 0 0px 0 #fff;
		text-transform: uppercase;
		text-align: right;
		opacity: 1;
		position: absolute; 
		z-index: 900
	}

	.modal-open .modal {
		overflow-x: hidden;
		overflow-y: hidden;
	}
	.modal-dialog{
		overflow-y: auto !important;
   		overflow-x: auto !important;
    	height: calc(100% - 30px);
    	top: 15px;
    	margin: 0px auto;
    	width: calc(100% - 30px) !important;
	}
	.modal-content{
		height: 100%;
		overflow-y: auto;
		border: 0px;
		position: initial;
		padding-top: 30px;
	}
}