@media (min-width: 481px) { 
	.l-footer-mobile {
		padding: 0 !important
	}
}


.l-prefooter {
    background-color: #373737;
}


@media (min-width: 481px) { 
	.l-prefooter  .row{
		display: table;
    	margin-left: 0;
    	width: 100%;
	}
	.l-prefooter .c-prefooter {
		display: table-cell;
		float: none;
		vertical-align: middle;
		line-height: 1.8rem;
	}
}


.l-prefooter a:link, .l-prefooter a:visited, .c-footer-social a:link, .c-footer-social a:visited {
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: color .3s ease-in-out;
	-moz-transition: color .3s ease-in-out;
	-ms-transition: color .3s ease-in-out;
	-o-transition: color .3s ease-in-out;
	transition: color .3s ease-in-out;
    font-size: 1.2rem;
    margin: 20px 0;
}

.l-prefooter a:hover, .c-footer-social a:hover {
  zoom: 1;
  color: #b2ce27
}
.c-footer-social a { font-size: 2.2rem !important; margin: 2px !important }

.c-prefooter {
	padding: 15px 0;
	border-right: 1px solid #fff;
}

.c-prefooter:first-child {
    border-left: 1px solid #fff;
}

@media (min-width: 992px) { 
	.c-fixed-footer {
		position:fixed;
		width:100%;
		bottom:0;
	}
}

@media (max-width: 480px) { 
	.c-prefooter, .c-prefooter:first-child {
		border: 0px !important
	}
}


.l-footer {
    background-color: #4d4d4f;
    color: #F1F1F1;
    font-size: 1.2rem;
    padding-bottom: 20px;
    padding-top: 30px;
}

.l-footer p {
	margin-bottom: 20px
}

.l-footer p:last-child {
	margin-bottom: 0px
}

.l-footer.l-footer-mini p:last-child {
	margin-bottom: 20px
}

.c-footer-info{
	text-transform: none;
}

.visibile-480 { display: none }

@media (max-width: 480px) {
	.c-footer-menu.col-xs-6 { width: 100% }
	.hidden-xxs { display: none }
	.nascosto-480 { display: none }
	.l-footer .col-xs-3, .l-prefooter .col-xs-3, .l-subfooter .col-xs-3 { width: 100%; float: none }
	.l-prefooter .text-center { text-align: left }
	.l-prefooter { 	padding: 0px 15px !important }
	.c-prefooter { border-bottom: 1px solid rgba(255,255,255,.1) !important; padding: 15px 0px !important }
	.c-prefooter:first-child { border-bottom: 1px solid rgba(255,255,255,.1) !important; }
	.c-footer-menu h6 { border-bottom: 1px solid rgba(255,255,255,.1) !important; padding-bottom: 9px;  }
	.c-prefooter:last-child { border-bottom: 0px solid #fff !important}
	.visibile-480 { display: block }
	.c-footer-links li { margin: 15px 0 }
}

@media (min-width: 481px) { .nascosto-sopra-480 { display: none } }

@media (max-width: 480px) {
	.c-subfooter { margin: 15px 0 }
}

.c-footer-info a:link,
.c-footer-info a:active,
.c-footer-info a:visited,
.c-footer-links a:link,
.c-footer-links a:active,
.c-footer-links a:visited,
.c-subfooter a:link, 
.c-subfooter a:active,
.c-subfooter a:visited {
	color: #F1F1F1;
	display: inline-block;
	font-weight: 300;
	line-height: 1.58;
	outline: 0;
	position: relative;
	text-decoration: none;
	-ms-transition: transform .3s, color .3s;
	transition: transform .3s, color .3s;
}

.c-footer-info a:focus,
.c-footer-info a:hover {
	background-color: transparent;
  	color: #b2ce27;
}
.c-footer-links a:focus,
.c-footer-links a:hover {
  color: #b2ce27;
  background-color: transparent;
  -ms-transform: translate(5px);
  transform: translate(5px);
}
.c-subfooter a:focus,
.c-subfooter a:hover {
  color: #b2ce27;
  background-color: transparent;
}

.c-footer-social img {
	height: 18px;
	margin: 0 3px
}

.c-footer-logo img {
	height: auto;
	max-width: 100%;
	width: 243px;
}

.c-footer-menu {
	text-transform: uppercase
}
.c-footer-menu ul {
	list-style-type: none;
	padding: 0;
	margin-bottom: 0px
}
.c-footer-menu h6 {
	color: #b2ce27;
	font-weight: 500;
	font-size: 1.4rem;
	margin: 0;
	padding: 20px 0 10px 0
}

.c-footer-menu h6:first-of-type {
	padding-top: 10px
}

@media (max-width: 480px) {
	.c-footer-menu h6 {
		padding-top: 10px
	}
}

.c-footer-menu h6 a {
	color: #b2ce27;
	font-weight: 500;
	text-decoration: none
}

.l-subfooter {
	background: #4d4d4f;
	border-top: 1px solid #F1F1F1;
	padding: 30px 0;
}
.c-subfooter { 
	text-transform: uppercase;
	font-size: 1.3rem
}

@media (min-width: 481px) { 
	.c-footer-menu .collapse { display: block } 
}

.c-back-top { 
	background: rgba(212, 215, 217, .8);
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    right: 15px;
    z-index: 99;
    -webkit-transition: background-color .3s, transform .5s ease-in-out;
    transition: background-color .3s, transform .5s ease-in-out;
    cursor: pointer;
    display: none;
}

.c-back-top:hover {
    background: rgba(212, 215, 217, .6);
}

.c-back-top:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    border-right: 4px solid white;
    border-top: 4px solid white;
    -ms-transform: translate(-50%, -30%) rotate(-45deg);
    transform: translate(-50%, -30%) rotate(-45deg);
}

@media (min-width: 481px) { 
	.c-footer-bottom { position: relative }
	.c-footer-bottom  .o-footer-bottom { position: absolute; bottom: 0; right: 0}
}
@media (max-width: 480px) {
	.c-footer-bottom .o-footer-bottom {
		margin-top: 20px;
	}
}
 