.c-news {
	background: #f5f5f5
}
.c-news img {
     	margin-right: auto;
     	margin-left: auto;
}
.c-news1 .c-news-foto {
	background: #fff;
	padding: 0;
	margin-right: 15px
}
.c-news1 .c-news-foto .o-bloccotesto {
	margin: 40px
}
.c-news1 .c-news-foto h2 {
	font-weight: 300;
	color: #264D7A;
}

.c-news1 .c-news-foto .o-info {
	color: #222;
	font-size: 1.4rem;
	margin: 20px 0
}

.c-news1 .c-news-blu, .c-news1 .c-news-verde { margin-left: 15px; }

.c-news1 .c-news-blu {
	background: #264D7A;
	overflow: hidden; 
}
.c-news1 .c-news-blu h2 {
	font-weight: 300;
	color: #fff;
}
.c-news1 .c-news-blu h4 {
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: #fff;
    line-height: 2.5rem;
	margin: 50px 0
}

.c-news1 .c-news-blu .o-info {
	color: #fff;
	font-size: 1.4rem;
	margin: 90px 0
}

.c-news1 .c-news-blu .o-info a {
	color: #fff !important;
	border-color: #fff;
}
.c-news1 .c-news-blu .o-info a:focus,
.c-news1 .c-news-blu .o-info a:hover {
	color: #a3bf19 !important;
	border-color: #a3bf19;
}

.c-news1 .c-news-blu .o-virgolette {
	padding-top: 130px;
	position: relative
}

.c-news1 .c-news-blu .o-virgolette:before {
	content: url("../images/double-quote-light-white.svg");
	opacity: 0.4;
	position: absolute;
	top: 0; 
    left: 0;
	width: 120px;
}

.c-news1 .c-news-blu .o-bloccotesto {
	margin: 40px
}

.c-news1 .c-news-verde {
	background: #d9e78f;
    display: inline-block;
    margin-top: 60px
}
.c-news1 .c-news-verde h2 {
	font-weight: 300;
	color: #222;
}

.c-news1 .c-news-verde .o-info {
	color: #222;
	font-size: 1.4rem;
	margin: 50px 0
}

@media (min-width: 1200px) {

    .c-news1 .c-news-blu {
        min-height: 541px;
    }
}

@media (min-width: 992px) {
	
	.c-news-verde-row {
		position: absolute;
		bottom: 0;
		right: 15px;
		left: 15px;
	}
	.c-news-verde-col {
		bottom: 0;
		position: absolute;
		top: 0;
		right: 0;
	}
	.c-news1 {
		position: relative;
	}
}

.c-news1 .c-news-verde .o-info a {
	color: #264D7A !important;
	border-color: #264D7A;
}
.c-news1 .c-news-verde .o-info a:focus,
.c-news1 .c-news-verde .o-info a:hover {
	color: #a3bf19 !important;
	border-color: #a3bf19;
}

.c-news1 .c-news-verde .o-bloccotesto {
	margin: 40px
}

.c-main-content p.mostrapiu.mostrapiu { margin:30px 0 !important; }
.mostrapiu a:after { content: 'nascondi' }
.mostrapiu .collapsed:after { content: 'mostra più'; }
.mostrapiu a span { display: none; }

.c-news-foto2 .o-newspicc {
	background: #fff;
	padding: 20px 20px;
	margin-bottom: 30px
}

.c-news-foto2 .row div:nth-child(1) .o-newspicc { margin-right: 0px }
.c-news-foto2 .row div:nth-child(2) .o-newspicc { margin-left: 0px }
.c-news-foto2 h4 {
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: #264D7A;
	margin: 0 0 15px 0;
}

.c-news-foto2 p { 
	margin-bottom: 0px !important
}

.c-news-2 .o-newspicc {
	background: #fff;
	padding: 15px 15px 15px 50px;
	margin-bottom: 30px;
	border-left: 8px solid #b2ce27;
    position: relative;
}

.c-news-2 .o-newspicc .o-data {
    font-weight: 300;
    color: #264D7A;
    font-size: 2rem;
}

.c-news-2 .o-newspicc .o-titolo {
    color: #264D7A;
    font-weight: 300;
}

.c-news-2 .o-newspicc .o-categoria {
    position: absolute;
    right: 15px;
    color: #264D7A;
    top: 15px;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
}

.c-news-2 .row div:nth-child(1) .o-newspicc { margin-right: 0px }
.c-news-2 .row div:nth-child(2) .o-newspicc { margin-left: 0px }
.c-news-2 .o-data {
	margin-bottom: 0 !important
}

.c-news-foto2, .c-news-2 {
	margin-top: 50px
}

.c-archivionews-form:before { content: none }
.c-archivionews-form { border: 0px; padding: 0px; margin: 10px 0 }
.c-archivionews-form input[type=submit] { right: 15px }
.c-archivionews-form .c-form-group {
	margin-bottom: 0px !important;
}

@media (max-width: 767px) {
    .c-archivionews-form .c-form-group {
        margin-bottom: 10px !important;
    }
}

.c-news-3 .o-newspicc {
	border-bottom: 5px solid #e7eaec; 
	padding-bottom: 30px;
    margin-bottom: 30px;
}

.c-news-3 .o-newspicc h4 .o-data {
	border-right: 3px solid #b2ce27;
	color: #222;
    display: inline-block;
	font-size: 1.8rem;
	font-weight: 300;
	line-height: 30px;
	margin-right: 15px;
	padding-right: 15px;
}

.c-news-3 .o-newspicc h4 a {
    color: #264D7A;
    border: 0px;
    font-size: 2.3rem;
}

.c-news-3 .o-newspicc h4 a:hover {
	color: #a3bf19;
}

.c-news-3 .o-newspicc p {
	margin: 15px 0 !important
}

.c-archivionews-form input[type=submit] {
    position: relative;
    right: auto;
    bottom: -2px;
    width: 100% !important;
}


@media (max-width: 767px) {
	.c-news1 .c-news-foto {
    	margin-right: 0px; 
	}
	.c-news1 .c-news-blu, .c-news1 .c-news-verde {
   	 margin-left: 0px;
	}
	.c-news1 .c-news-blu {
		margin-top:40px
	}
	.c-news1 .c-news-verde {
		margin-top:40px
	}
	.o-altrenews .o-newspicc, .c-news-foto2 .o-newspicc, .c-news-2 .o-newspicc {
    	margin-bottom: 20px;
	}
}

@media (max-width: 479px) {
	
	.o-altrenews .o-newspicc, .c-news-foto2 .o-newspicc, .c-news-2 .o-newspicc {
    	margin-bottom: 10px;
	}
	.c-news1 .c-news-blu, .c-news1 .c-news-verde {
    	margin-top: 20px;
	}
	.o-altrenews p.o-testo {
    	margin: 0 !important;
    	margin-left: 80px !important;
	}
	.o-altrenews .o-data { 
		margin-right: 0
	}
	
	.c-news-2 .row div:nth-child(1) .o-newspicc {
   		margin-right: 0px !important;
	}
	.c-news-2 .row div:nth-child(2) .o-newspicc {
    	margin-left: 0px !important;
	}
	.c-news-foto2 .o-newspicc img {
     	margin: 0 auto 20px auto;
    }
    
    .c-news-foto2 .row div:nth-child(1) .o-newspicc {
    	margin-right: 0px;
	}
    .c-news-foto2 .row div:nth-child(2) .o-newspicc {
    	margin-left: 0px;
	}

    .o-altrenews .row div:nth-child(1) .o-newspicc {
    	margin-right: 0px;
	}
    .o-altrenews .row div:nth-child(2) .o-newspicc {
    	margin-left: 0px;
	}
    
}