.c-main-content a.c-secondary-button {
    text-decoration: none;
}

.c-main-content a.c-right-button {
	border: none;
    color: #264d7a;
    text-decoration: none;
}

a.c-primary-button {
    background: #b2ce27;
	border:none !important;
    border-radius: 0;
    color: #264D7A !important;
	display:inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.5rem;
	margin:0 25px 0 0;
    min-height: 50px;
    outline: 0;
    padding: 12px 25px;
	position: relative;
    text-decoration: none;
	text-align: center;
	text-transform: uppercase;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

a.c-primary-button:hover, 
a.c-primary-button:active, 
a.c-primary-button:focus {
    background: #264d7a;
    color: #fff !important;
}

a.c-primary-button.c-disabled-button {
    background: #E8F0BE;
    color: #9FA0A1 !important;
	cursor: url(../images/stop.png), auto;
}

a.c-secondary-button {
    background: #fff;
    border: 0!important;
    border-radius: 0;
    box-shadow: inset 0 0 0 2px #264d7a;
    color: #264d7a !important;
    display: inline-block;
    font-family: Roboto,sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0 25px 0 0;
    min-height: 50px;
    padding: 12px 25px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

a.c-secondary-button:hover, 
a.c-secondary-button:active, 
a.c-secondary-button:focus {
    background: #264d7a;
    border-color: #264d7a !important;
    color: #fff !important;
}

a.c-draw-button.c-disabled-button,
a.c-draw-button.c-disabled-button:hover,
a.c-draw-button.c-disabled-button:active,
a.c-draw-button.c-disabled-button:focus,
a.c-secondary-button.c-disabled-button,
a.c-secondary-button.c-disabled-button:hover, 
a.c-secondary-button.c-disabled-button:active, 
a.c-secondary-button.c-disabled-button:focus {
    background:none;
    background-color:#fff;
    box-shadow: inset 0 0 0 2px #9FA0A1 !important;
    color: #9FA0A1 !important;
}

a.c-draw-button.c-disabled-button:before,
a.c-draw-button.c-disabled-button:hover:before,
a.c-draw-button.c-disabled-button:active:before,
a.c-draw-button.c-disabled-button:focus:before,
a.c-draw-button.c-disabled-button:after,
a.c-draw-button.c-disabled-button:hover:after,
a.c-draw-button.c-disabled-button:active:after,
a.c-draw-button.c-disabled-button:focus:after {
    border: none;
}

a.c-draw-button.c-disabled-button,
a.c-secondary-button.c-disabled-button {
	cursor: url(../images/stop.png), auto;
}

a.c-draw-button {
    background: #fff;
    border:0 !important ;
    border-radius: 0;
	box-shadow: inset 0 0 0 2px #264D7A;
    color: #264D7A;
	display:inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
	font-weight: 700;
    margin:0 25px 0 0;
    min-height: 50px;
    outline: 0;
    padding: 12px 25px;
	position: relative;
	text-align:center;
    text-decoration: none;
	text-transform:uppercase;
    -webkit-transition: color 0.3s, border-color 0.3s;
    -moz-transition: color 0.3s, border-color 0.3s;
    -ms-transition: color 0.3s, border-color 0.3s;
    -o-transition: color 0.3s, border-color 0.3s;
    transition: color 0.3s, border-color 0.3s;
}

a.c-draw-button::before, 
a.c-draw-button::after {
	box-sizing: inherit;
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border: 2px solid transparent;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
a.c-draw-button:focus::before, 
a.c-draw-button:focus::after,
a.c-draw-button:hover::before, 
a.c-draw-button:hover::after {
	width: 100%;
	height: 100%;
}
a.c-draw-button:focus::before,
a.c-draw-button:hover::before {
	border-top-color: #a3bf19;
	border-right-color: #a3bf19;
	transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
}
a.c-draw-button:focus::after,
a.c-draw-button:hover::after {
	border-bottom-color: #a3bf19;
	border-left-color: #a3bf19;
	transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
}
a.c-draw-button:focus,
a.c-draw-button:hover {
	color: #a3bf19;
}

a.c-right-button {
	position: relative;
	display:inline-block;
    font-family: 'Roboto Slab', sans-serif;
    text-decoration: none !important; 
	border:none;
	font-weight:400;
    font-size: 1.8rem;
    color: #264d7a;
    border-radius: 0;
    padding: 0 0 0 18px;
}

a.c-right-button:before {
    border-right: 3px solid #264d7a;
    border-top: 3px solid #264d7a;
    content: "";
    height: 10px;
    left: 1px;
    position: absolute;
    top: 7px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .3s;
    transition: all .3s;
    width: 10px;
}

a.c-right-button:focus,
a.c-right-button:hover {
	color: #a3bf19;
}

a.c-right-button:focus:before,
a.c-right-button:hover:before {
	border-color: #a3bf19 !important;
    animation: o-button 1s linear infinite;
  	animation-direction: alternate;
}

a.c-right-button.white {
    color: #fff;
}
a.c-right-button.white:before {
    border-color: #fff;
}
a.c-right-button.white:hover {
    color: #b2ce27;
}
a.c-right-button.white:hover:before {
    border-color: #b2ce27;
}

a.c-secondary-right-button {
    font-family: 'Roboto', sans-serif;
	font-weight: 700;
}

a.c-circle-button {
    background: transparent url(../images/check-icona-blu.svg) no-repeat 0 0;
    background-size: cover;
	border:none;
	display:inline-block;
	height:35px;
	margin:0 25px 0 0;
    outline: 0;
	position: relative;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
	width:35px;
}

a.c-circle-button:focus,
a.c-circle-button:hover {
    background: transparent url(../images/check-blu.svg) no-repeat 0 0;
    background-size: cover;
	border:none;
}

a.c-circle-button.c-circle-button-layout-2 {
    background: url(../images/check-bianco.svg) no-repeat;
	width:25px;
	height:25px;
}

a.c-circle-button.c-circle-button-layout-2:focus,
a.c-circle-button.c-circle-button-layout-2:hover {
    background: url(../images/check-blu.svg) no-repeat;
}

a.c-circle-button.c-circle-button-layout-3 {
    background: #b2ce27 url(../images/cancel-icon-blu.svg) no-repeat 10px 10px;
	background-size: 15px;
	-webkit-border-radius: 35px;
	-moz-border-radius: 35px;
	border-radius: 35px;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -ms-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
}

a.c-circle-button.c-circle-button-layout-4 {
    background-image: url(../images/cancel-icons-hover.svg);
    background-repeat: no-repeat;
	background-position:center;
	background-size:10px;
    background-color: #b2ce27;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
    -webkit-transition: background-image 0.3s, background-color 0.3s;
    -moz-transition: background-image 0.3s, background-color 0.3s;
    -ms-transition: background-image 0.3s, background-color 0.3s;
    -o-transition: background-image 0.3s, background-color 0.3s;
    transition: background-image 0.3s, background-color 0.3s;
	width:25px;
	height:25px;
}

a.c-circle-button.c-circle-button-layout-3:focus,
a.c-circle-button.c-circle-button-layout-4:focus,
a.c-circle-button.c-circle-button-layout-3:hover,
a.c-circle-button.c-circle-button-layout-4:hover {
    background: #264d7a url(../images/cancel-icons-hover.svg) no-repeat 10px 10px;
    background-size: 15px;
}

a.c-circle-button.c-circle-button-layout-5 {
    background: #b2ce27 url(../images/piu-blu.svg) no-repeat 10px 10px;
	background-size:15px;
	-webkit-border-radius: 35px;
	-moz-border-radius: 35px;
	border-radius: 35px;
    -webkit-transition: background-image 0.3s, background-color 0.3s;
    -moz-transition: background-image 0.3s, background-color 0.3s;
    -ms-transition: background-image 0.3s, background-color 0.3s;
    -o-transition: background-image 0.3s, background-color 0.3s;
    transition: background-image 0.3s, background-color 0.3s;
}

a.c-circle-button.c-circle-button-layout-5:focus,
a.c-circle-button.c-circle-button-layout-5:hover {
    background: #264d7a url(../images/piu-bianco.svg) no-repeat 10px 10px;
    background-size:15px;
}

a.c-circle-button.share-widget {
    background:  url(../../assets/images/share-widget.svg) no-repeat 0 0;
    background-color: #b2ce27;
    border-radius: 51px;
    margin: 7px 0 0 7px;
    transition: all .3s;
}
a.c-circle-button.share-widget:hover {
    background: url(../../assets/images/share-button-white-hover.svg) no-repeat 0 0;
    background-color: #264d7a;
}

a.c-circle-button.c-disabled-button {
	cursor:default;
}

a.c-circle-button.c-disabled-button,
a.c-circle-button.c-disabled-button:hover {
    background: url(../images/check-nero-disabled.svg) no-repeat;
}

a.c-circle-button.c-circle-button-layout-2.c-disabled-button,
a.c-circle-button.c-circle-button-layout-2.c-disabled-button:hover {
    background: url(../images/check-bianco-disabled.svg) no-repeat;
}

.c-contact-form .c-form-group {
    width: 100%;
    margin: 0 0 50px 0;
    text-align: left;
    position: relative;
}

.c-cta-search {
	margin:0;
	padding:0;
	border:none;
}

.c-cta-search:before{
	display:none;
}

.c-cta-search input[type=text] {
	height: 52px;
    padding: 20px 80px 15px 20px;
    width: calc(100% - 2px);
}

.c-cta-search span.o-placeholder {
    top: 11px;
    left: 20px;
    height: 28px;
    line-height: 28px;
    -webkit-transition: font-size .3s, transform .3s;
    -moz-transition: font-size .3s, transform .3s;
    -ms-transition: font-size .3s, transform .3s;
    -o-transition: font-size .3s, transform .3s;
    transition: font-size .3s, transform .3s;
	font-size: 2rem;
}

.c-cta-search input[type=submit] {
	height:52px;
	padding:0 20px;
	position:absolute;
	top:0;
	right:0;
	margin:0;
	border:none;
	background-color:#b2ce27;
	-webkit-border-top-right-radius: 8px;
	-webkit-border-bottom-right-radius: 8px;
	-moz-border-radius-topright: 8px;
	-moz-border-radius-bottomright: 8px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.c-cta-search input[type=text]:hover,
.c-cta-search input[type=text]:active,
.c-cta-search input[type=text]:focus {
	border: solid 1px #66696C;
}

.c-cta-search input[type=text].is-filled ~ span.o-placeholder,
.c-cta-search input[type=text]:active ~ span.o-placeholder, 
.c-cta-search input[type=text]:focus ~ span.o-placeholder {
    -ms-transform: translate(0, -25px);
    -webkit-transform: translate(0, -25px);
    transform: translate(0, -25px);
    font-size: 1.6rem;
}

.c-cta-search-disabled input[type=text]{
	cursor: url(../images/stop.png), auto;
}

.c-cta-search-disabled input[type=submit] {
	cursor: url(../images/stop.png), auto;
	background-color:#dce89f;
}

.c-cta-search-disabled input[type=submit]:hover ,
.c-cta-search-disabled input[type=submit]:active ,
.c-cta-search-disabled input[type=submit]:focus {
	background-color:#dce89f;
}

.c-cta-search-disabled input[type=text]:active ~ span.o-placeholder, 
.c-cta-search-disabled input[type=text]:focus ~ span.o-placeholder {
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.c-cta-search-layout-2 input[type=submit] {
	width:50px !important;
    font-size: 0px;
    text-indent: -9999px;
	background-image:url(../images/cerca-white.svg);
    background-size: 25px;
	background-repeat: no-repeat;
    background-position: center;
}

.c-cta-search-layout-2 input[type=submit]:hover, 
.c-cta-search-layout-2 input[type=submit]:focus {
	background-image:url(../images/cerca-white.svg);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
}

a.cta-testuale-1-a,
a.cta-testuale-1-b {
	border-bottom: 1px solid #264d7a;
    color: #264d7a;
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    margin-left: 15px;
    outline: 0;
    padding: 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .3s, border-bottom .3s;
}
a.cta-testuale-1-a:focus,
a.cta-testuale-1-b:focus,
a.cta-testuale-1-a:hover,
a.cta-testuale-1-b:hover {
    border-bottom: 1px solid #a3bf19;
    color: #a3bf19;
    text-decoration: none;
}
a.cta-testuale-1-a img,
a.cta-testuale-1-b img {
    left: -15px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

a.cta-testuale-2 {
    font-size: 1.4rem;
    font-weight: 700;
}

a.cta-icona {
    border-bottom: none;
    display: inline-block;
    margin-right: 25px;
    transition: background-image .3s;
}
a.cta-icona:focus,
a.cta-icona:hover {
    border-bottom: none;
    cursor: pointer;
}

.cta-visualizza {
    background: no-repeat left top;
    background-image: url('../images/visualizza.svg');
    height: 19px;
    width: 30px;
}
.cta-visualizza:hover {
    background-image: url('../images/visualizza-hover.svg');
}

.cta-modifica {
    background: no-repeat left top;
    background-image: url('../images/modifica_NO-ombra-blu.svg');
    background-size: contain;
    height: 20px;
    width: 20px;
}
.cta-modifica:hover {
    background-image: url('../images/modifica_NO-ombra-verde.svg');
}

.cta-stampa {
    background: no-repeat left top;
    background-image: url('../images/stampa_outline.svg');
    background-size: contain;
    height: 31px;
    width: 22px;
}
.cta-stampa:focus,
.cta-stampa:hover {
    background-image: url('../images/stampa_over.svg');
}

.cta-mail {
    background: no-repeat left top;
    background-image: url('../images/mail_outline.svg');
    background-size: contain;
    height: 31px;
    width: 22px;
}
.cta-mail:focus,
.cta-mail:hover {
    background-image: url('../images/mail_over.svg');
}

.cta-esporta {
    background: no-repeat left top;
    background-image: url('../images/esporta.svg');
    height: 18px;
    width: 30px;
}
.cta-esporta:hover {
    background-image: url('../images/esporta-hover.svg');
}

.cta-xls {
    background: no-repeat left top;
    background-image: url('../images/xls_outline.svg');
    background-size: contain;
    height: 31px;
    width: 22px;
}
.cta-xls:hover {
    background-image: url('../images/xls_over.svg');
}

@keyframes o-button {
   0%, 100% {
    left: 0px;
  }
  50% { left: -15px }
}

.c-generic-icon {
	position: relative;
    width: 35px;
	float:left;
	margin-right:25px;
}

.c-generic-icon .o-tooltip.o-tooltip-top ,
body.is-scrolling .c-generic-icon .o-tooltip.o-tooltip-top {
    -ms-transform: translate(-50%, -35px);
    -webkit-transform: translate(-50%, -35px);
    transform: translate(-50%, -35px);
}

.c-generic-icon .c-circle-button img {
    height: 25px;
}

.c-generic-icon a.c-circle-button {
	background-color:#B2CE27;
    background-image: none;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border: none;
    text-align: center;
    line-height: 35px;
    border-radius: 35px;
	margin-right:0
}

/*c-cta-dati*/

.c-cta-dati{font-size: 1.4rem;line-height: 1.1;overflow-x: auto;padding-top: 10px;padding-bottom:25px;}
.c-accordion + .c-cta-dati{padding-top: 0;padding-bottom:0;}

.c-accordion ~ div p { padding: 20px }

.c-cta-dati table{min-width:calc( 100% - 100px ) !important; width: 99.999%;border: 0;border-spacing: 0; margin-top: 40px;}
.c-cta-dati .c-contact-form .c-filter  input,
.c-cta-dati .c-contact-form textarea,
.c-cta-dati .c-contact-form label,
.c-cta-dati .c-contact-form .c-form-checkbox,
.c-cta-dati .c-contact-form td{padding: 0 5px;}
.c-cta-dati  { margin: 0 }
.c-cta-dati  tbody td {border-right:1px solid #F5F5F5;padding: 10px 10px 10px 12px; font-weight: 500;}
.c-cta-dati > .table-responsive thead tr td,
.c-cta-dati > .table-responsive tbody tr td {  width: 20.33%; /* x3*/ min-width:inherit }
.c-cta-dati > .table-responsive thead tr td.c-auto-width,
.c-cta-dati > .table-responsive tbody tr td.c-auto-width {  width: 7% /* x3*/ }
.c-cta-dati > .table-responsive thead tr td.c-flag,
.c-cta-dati > .table-responsive tbody tr td.c-flag { width:5%; padding-left: 10px; padding-right: 10px; text-align:center}
.c-cta-dati > .table-responsive thead tr td.c-flag-large,
.c-cta-dati > .table-responsive tbody tr td.c-flag-large { width:11%; padding-left: 10px; padding-right: 10px; text-align:center}

.c-cta-dati > .c-accordion-table.table-responsive tr td {  width: auto }

.c-cta-dati > .table-responsive tbody tr td.white-bg {
  background-color: #fff;
}

.c-main-content .cta-card-1 {
    background-color: #ffffff;
    border-bottom: none;
    border-radius: 10px;
    box-shadow: #949494 0 2px 3px;
    color: #222222;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 24px;
    max-width: 361px;
    opacity: 1;
    overflow: hidden;
    padding: 30px 0 30px 30px;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    transition: box-shadow .3s linear;
}
.c-main-content .cta-card-1:focus,
.c-main-content .cta-card-1:hover {
    box-shadow: #949494 0 4px 6px;
    color: inherit;
    border-bottom: none;
}
.c-main-content .cta-card-1:before {
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 50px;
    position: absolute;
    right: -13px;
    top: 15px;
    vertical-align: middle;
    width: 50px;
}
.c-main-content .cta-card-1.card-carte:before {
    background: transparent url(../images/bg-card-carte-blu.svg) no-repeat 0 0;
    background-size: contain;
    height: 61px;
    top: 12px;
    transition: background .3s linear;
    width: 98px;
}
.c-main-content .cta-card-1.card-carte:focus:before,
.c-main-content .cta-card-1.card-carte:hover:before {
    background: transparent url(../images/bg-card-carte-verde.svg) no-repeat 0 0;
    background-size: contain;
}

.c-main-content .cta-card-2 {
    background: #fff url(../images/linee_verdi.svg) no-repeat 0 0;
    // background-color: #ffffff;
    // background-image: url(../images/linee_verdi.svg);
    border-bottom: none;
    border-radius: 10px;
    box-shadow: #949494 0 2px 3px;
    color: #222222;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 24px;
    max-width: 361px;
    opacity: 1;
    overflow: hidden;
    padding: 30px 0 30px 85px;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    transition: background .3s linear, box-shadow .3s linear;
}
.c-main-content .cta-card-2:focus,
.c-main-content .cta-card-2:hover {
    background: #fff url(../images/linee_blu.svg) no-repeat 0 0;
    // background-image: url(../images/linee_blu.svg);
    box-shadow: #949494 0 4px 6px;
    color: inherit;
    border-bottom: none;
}
.c-main-content .cta-card-2:before {
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 50px;
    left: 20px;
    position: absolute;
    top: 15px;
    vertical-align: middle;
    width: 50px;
}
.c-main-content .cta-card-2.card-carte:before {
    background-image: url(../images/bg-card-carte.svg);
    height: 30px;
    top: 25px;
    width: 50px;
}
.c-main-content .cta-card-3 {
    background-color: #ffffff;
    border-bottom: none;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .3);
    color: #222222;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 24px;
    max-width: 361px;
    opacity: 1;
    overflow: hidden;
    padding: 30px 0 30px 85px;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    transition: box-shadow .2s;
}
.c-main-content .cta-card-3:focus,
.c-main-content .cta-card-3:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .1);
    color: inherit;
    border-bottom: none;
}
.c-main-content .cta-card-3:before {
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 50px;
    left: 20px;
    position: absolute;
    top: 15px;
    vertical-align: middle;
    width: 50px;
}
.c-main-content .cta-card-3.card-carte:before {
    background-image: url("../images/bg-card-carte.svg");
    height: 30px;
    top: 25px;
    width: 50px;
}
.c-main-content .cta-card-3:focus:before,
.c-main-content .cta-card-3:hover:before {
    -moz-animation: toTopFromBottom 0.7s forwards;
    animation: toTopFromBottom 0.6s forwards;
}
@-moz-keyframes toTopFromBottom {
    49% {
      -moz-transform: translateY(100%);
    }
  
    50% {
      opacity: 0;
      -moz-transform: translateY(-100%);
    }
  
    51% {
      opacity: 1;
    }
}
@-webkit-keyframes toTopFromBottom {
    49% {
      -webkit-transform: translateY(100%);
    }
  
    50% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
    }
  
    51% {
      opacity: 1;
    }
}
@keyframes toTopFromBottom {
    49% {
      transform: translateY(100%);
    }
  
    50% {
      opacity: 0;
      transform: translateY(-100%);
    }
  
    51% {
      opacity: 1;
    }
}



@media (max-width: 1170px) {

	.c-cta-dati > .table-responsive thead tr td,
	.c-cta-dati > .table-responsive tbody tr td {  width: 15%;  }
	.c-cta-dati > .table-responsive thead tr td.c-auto-width,
	.c-cta-dati > .table-responsive tbody tr td.c-auto-width {  width: 10% }
	.c-cta-dati > .table-responsive thead tr td.c-flag,
	.c-cta-dati > .table-responsive tbody tr td.c-flag { width:10%; }
	.c-cta-dati > .table-responsive thead tr td.c-flag-large,
	.c-cta-dati > .table-responsive tbody tr td.c-flag-large { width:15%; }

}

/* Tab layout B */
div.scrollmenu-container {
	width: 100%;
}

div.scrollmenu-container input {
    background-color: #E9EDF2;
    border: 0;
    border-bottom: 3px solid #E9EDF2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    bottom: 14px;
    color: #222;
    cursor: pointer;
    font-weight: 900;
    padding: 11px 15px 6px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    width: 4%;
}
div.scrollmenu-container input:focus,
div.scrollmenu-container input:hover {
    border-bottom: 3px solid #264d7a;
    color: #222;
    margin-top: 12px;
    outline: none;
    padding-top: 11px;
}

ul.scrollmenu {
    display: inline-block;
    height: 49px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    white-space: nowrap;
    width: 91.39%;
}

.scrollmenu-border-bottom {
    background-color: #e9edf2;
    bottom: 1px;
    height: 1px;
    position: absolute;
    width: 100%;
}

div.c-cta-dati-wrapper div.scrollmenu-container {
    height: 49px;
    position: relative;
}
div.c-cta-dati-wrapper ul.scrollmenu li {
    background-color: #E9EDF2;
    border: 1px solid #E9EDF2;
    border-bottom: 1px solid #E9EDF2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: transparent 0 -2px 2px;
    display: inline-block;
    margin: 0;
    position: relative;
    text-align: left;
    top: 5px;
    transition: box-shadow .3s;
    width: 22%;
}

/* Edge */
@supports (-ms-ime-align:auto) {
    div.c-cta-dati-wrapper ul.scrollmenu li {
        top: 4px;
    }
}

/* IE 11 */
@media all and (-ms-high-contrast:none) {
    div.scrollmenu-container input {
        bottom: 16px;
    }

    div.c-cta-dati-wrapper ul.scrollmenu li {
        top: 4px;
    }
}

div.c-cta-dati-wrapper ul.scrollmenu li.active {
    background-color: #fff;
    border-color: #dee5ed;
    border-bottom: 1px solid #fff;
    box-shadow: #949494 0 -2px 2px;
    color: #222;
    margin-top: 0;
    z-index: 1;
}

ul.scrollmenu a {
    border-bottom: 3px solid transparent;
    color: #222;
    display: block;
    font-weight: 400;
    padding: 9px 15px 6px;
    text-decoration: none;
    transition: border-bottom .3s, font-weight .3s, padding-top .3s;
}
ul.scrollmenu a:focus,
ul.scrollmenu a:hover {
    border-bottom: 3px solid #264d7a;
    color: #222;
    margin-top: 0;
    padding-top: 15px;
}
ul.scrollmenu li.active a:focus,
ul.scrollmenu li.active a:hover {
    border-bottom: 3px solid transparent;
}

div.c-cta-dati-wrapper ul.scrollmenu li.active a {
    font-weight: 700;
    padding-top: 15px;
}

.c-cta-tab-layout-2 ul.scrollmenu {
	overflow:inherit;
}

.c-cta-tab-layout-2 ul.scrollmenu a {
	text-align:center;
	border:none;
    border-bottom: 6px solid #dee5ed;
	border-radius:0;
    padding-bottom: 15px;
    background: none;
    color: #264d7a;
    font-weight: 400;
	width:auto;
	font-family: Roboto Slab,serif;
	font-size: 1.8rem;
    margin-right: 30px;
}

/* Tab Bootstrap personalizzate con Layout A */
.c-cta-tab-layout-2 {
    font-size: 1.4rem;
    line-height: 1.1;
    overflow-x: auto;
    padding-bottom: 25px;
    padding-left: 0;
    padding-top: 10px;
    white-space: nowrap;
}

.c-cta-tab-layout-2 li {
    display: inline-block;
    margin: 0 10px;
}
.c-cta-tab-layout-2 li:first-child {
    margin-left: 0;
}
.c-cta-tab-layout-2 li:last-child {
    margin-right: 0;
}

.c-cta-tab-layout-2 li a {
    border-bottom: 6px solid #dee5ed;
    color: #264d7a;
    font-family: Roboto Slab,serif;
    font-size: 1.8rem;
    font-weight: 400;
    outline: 0;
    padding: 10px;
    text-decoration: none;
    -webkit-transition: border-color .3s;
    transition: border-color .3s;
    width: auto;
}

.c-cta-tab-layout-2 li:last-child a {
    margin-right: 0;
}

.c-cta-tab-layout-2 li.active a,
.c-cta-tab-layout-2 li a:focus,
.c-cta-tab-layout-2 li a:hover {
    border-bottom: 6px solid #264d7a;
    border-color: #264d7a;
    color: #264d7a;
}

.c-cta-tab-layout-2 li.active a,
.c-cta-tab-layout-2 li a:hover {
    text-shadow: 1px 0 0 #264d7a;
}

.c-cta-tab-layout-2.animated-scroll {
    line-height: 3;
    margin: 0;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
}

a.left-scroll,
a.right-scroll {
    background: #fff url('../images/freccia-paginazione.svg') no-repeat 50% 50%;
    border-bottom: none;
    cursor: pointer;
    display: none;
    font-size: 2rem;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 39px;
    transform: translateY(-50%);
    -webkit-transition: background .3s;
    transition: background .3s;
    width: 30px;
}
a.left-scroll:focus,
a.left-scroll:hover,
a.right-scroll:focus,
a.right-scroll:hover {
    background: #fff url('../images/freccia-paginazione-hover.svg') no-repeat 50% 50%;
    border-bottom: none;
}

a.left-scroll {
    left: 6px;
}

a.right-scroll {
    transform: rotate(180deg);
    right: 6px;
    top: 24px;
}

.tab-content .tabpanel-focused:focus {
    border-radius: 6px;
    box-shadow: 0 0 0 3px #264d7a;
    outline: 0;
}
/* Fine */


/* Accordion */

.c-main-content .c-accordion {
	background-color: #f5f5f5;
    border-bottom: 0;
	color: #264d7a;
    cursor: pointer;
    display: block;
	font-family: 'Roboto', serif;
	font-size: 1.8rem;
    font-weight: 400;
	margin: 15px 0 0 0;
    outline: 0;
	padding: 20px;
    position: relative;
}
.c-main-content .c-accordion:focus,
.c-main-content .c-accordion:hover {
    border-bottom: 0;
	color: #264d7a;
}
.c-main-content .c-accordion:before {
    background-color: transparent;
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    transition: background-color .3s;
    width: 100%;
}

.c-main-content .c-accordion:focus:before,
.c-main-content .c-accordion:hover:before,
.c-main-content .c-accordion[aria-expanded="true"]:before {
    background-color: #A3BF19;
}

.c-main-content .c-accordion span.caret {
	border-top: 10px dashed;
	border-top: 10px solid \9;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	color: #264d7a;
	float: right;
	margin-top: 5px;
	-moz-transition-duration: 800ms;
	-webkit-transition-duration: 800ms;
	transition-duration: 800ms;
}

.c-main-content .c-accordion:focus span.caret,
.c-main-content .c-accordion:hover span.caret {
	-moz-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
}
.c-main-content .c-accordion span.caret.open {
    -moz-transform: rotate(540deg);
	-ms-transform: rotate(540deg);
	-webkit-transform: rotate(540deg);
	transform: rotate(540deg);
}

.c-cta-dati .c-accordion-table {
	margin-bottom: 10px;
	margin-top: 10px;
}

.c-accordion-table .c-contact-form {
    padding: 0;
    border: 0px solid #264d7a;
    position: relative;
    margin: 1px 0;
    width: 100%;
    display: contents;
}

.c-accordion-table .c-contact-form .c-form-group {
    margin: 0 0 10px 0;
}

.c-accordion-table .c-contact-form:before {  
    display: none
}

.c-accordion-table .c-contact-form td {
	padding:0 5px;
}

.c-accordion-table > tbody > tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.c-accordion-table > thead tr td:first-child,
.c-accordion-table >tbody tr td:first-child {
	text-transform: uppercase;
	width: 20%;
}

.c-accordion-table .c-contact-form input[type=text] {
    height: auto;
}

.c-accordion-table .c-contact-form span.o-placeholder {
	top: 50%;
	transform: translateY(-50%);
	height: auto;
	line-height: 1.3rem;
    font-size: 1.1rem;	
}

.c-accordion.c-accordion-layout-b {
    background: #fff no-repeat 11px 19px;
    background-image: url(../images/design-blu.svg);
    font-family: Roboto Slab, serif;
    font-size: 2rem;
    font-weight: 700;
    padding: 48px 59px 21px 63px;
    position: relative;
    text-transform: uppercase;
    transition: background-image .3s;
}
.c-accordion.c-accordion-layout-b:focus,
.c-accordion.c-accordion-layout-b:hover,
.c-accordion.c-accordion-layout-b[aria-expanded="true"] {
    background-image: url(../images/design-verde.svg);
}
.c-accordion.c-accordion-layout-b:before {
    background-color: #949494;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: background-color .3s, height .3s;
    width: 100%;
}
.c-accordion.c-accordion-layout-b:focus:before,
.c-accordion.c-accordion-layout-b:hover:before,
.c-accordion.c-accordion-layout-b[aria-expanded="true"]:before {
    height: 3px;
}

.c-accordion.c-accordion-layout-b span.caret {
    border: none;
    height: 23px;
    margin: 0;
    position: absolute;
    right: 21px;
    transform: rotate(0);
    transition: transform .3s;
    width: 23px;
}
.c-accordion.c-accordion-layout-b span.caret:before {
    content: '+';
    font-size: 4rem;
    font-weight: 300;
    left: 0;
    line-height: 2.3rem;
    position: absolute;
    top: -2px;
}
.c-accordion.c-accordion-layout-b:focus span.caret,
.c-accordion.c-accordion-layout-b:hover span.caret {
    transform: rotate(180);
}
.c-accordion.c-accordion-layout-b span.caret.open:before {
    content: '-';
    font-size: 7rem;
    font-weight: 100;
    left: -2px;
    top: -5px;
}

.accordion-layout-c-wrapper {
    position: relative;
}
.accordion-layout-c-wrapper:before {
    border-bottom: 30px solid transparent;
    border-right: 30px solid #264D7A;
    border-top: 30px solid transparent;
    bottom: -19px;
    content: "";
    display: block;
    height: 0;
    left: -4px;
    position: absolute;
    transform: rotate(-45deg);
    width: 0;
    z-index: 99;
}
.accordion-layout-c-wrapper:after {
    background-color: #949494;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    transition: background-color .3s, height .3s;
    width: 100%;
}
.accordion-layout-c-wrapper.open:after,
.accordion-layout-c-wrapper:hover:after {
    background-color: #264d7a;
    height: 3px;
}

.accordion-layout-c-wrapper.rosso:before {
    border-right: 30px solid #e3125d;
}
.accordion-layout-c-wrapper.rosso.open:after,
.accordion-layout-c-wrapper.rosso:hover:after {
    background-color: #e3125d;
}

.accordion-layout-c-wrapper.arancione:before {
    border-right: 30px solid #eb8a3c;
}
.accordion-layout-c-wrapper.arancione.open:after,
.accordion-layout-c-wrapper.arancione:hover:after {
    background-color: #eb8a3c;
}

.accordion-layout-c-wrapper.verde {
    background-color: #fff;
}
.accordion-layout-c-wrapper.verde:before {
    border-right: 30px solid #117e93;
}
.accordion-layout-c-wrapper.verde.open:after,
.accordion-layout-c-wrapper.verde:hover:after {
    background-color: #117e93;
}

.c-accordion.c-accordion-layout-c {
    background-color: #fff;
    font-family: Roboto Slab, serif;
    font-size: 2rem;
    font-weight: 700;
    padding: 48px 59px 21px 63px;
    position: relative;
    text-transform: uppercase;
}
.c-accordion.c-accordion-layout-c:before {
    content: none;
}

.c-accordion.c-accordion-layout-c span.caret {
    border: none;
    height: 23px;
    margin: 0;
    position: absolute;
    right: 21px;
    transform: rotate(0);
    transition: transform .3s;
    width: 23px;
}
.c-accordion.c-accordion-layout-c span.caret:before {
    content: '+';
    font-size: 4rem;
    font-weight: 300;
    left: 0;
    line-height: 2.3rem;
    position: absolute;
    top: -2px;
}
.c-accordion.c-accordion-layout-c:focus span.caret,
.c-accordion.c-accordion-layout-c:hover span.caret {
    transform: rotate(180);
}
.c-accordion.c-accordion-layout-c span.caret.open:before {
    content: '-';
    font-size: 7rem;
    font-weight: 100;
    left: -2px;
    top: -5px;
}

.accordion-layout-c-wrapper div {
    padding-left: 43px;
}


/* tab LAYOUT C da collateral */

.o-riconsegne-tab {
	float: left;
	list-style-type: none;
	margin-top: 30px;
	padding-left: 0 !important;
	width: 100%;
}

.o-riconsegne-tab li { 
    background: #F5F5F5;
    border-radius: 0 0 0 0;
    box-shadow: transparent 0 -2px 2px;
	color: #66696C;
	float: left;
    font-size: 1.4rem;
    height: 50px;
    line-height: 3.5rem;
    min-width: 50px;
    overflow: hidden;
    padding: 9px 20px 5px;
    position: relative;
    transition: border-radius .3s, box-shadow .3s, min-width .3s;
}
.o-riconsegne-tab li:nth-child(1) {
    border-radius: 10px 0 0 0;
}
.o-riconsegne-tab li:last-child {
    border-radius: 0 10px 0 0;
}

.o-riconsegne-tab li a { 
    border: none;
    border-bottom: 3px solid transparent;
	color: #222;
    display: inline-block;
    font-family: Roboto Slab, serif;
    font-weight: 400;
    padding: 0 10px 3px;
    transition: border-bottom .3s;
	white-space: nowrap; 
}

.o-riconsegne-tab li.active a { 
	font-weight: 700;
}

.o-riconsegne-tab li a:active,
.o-riconsegne-tab li a:focus {
	border: 0px;
	text-decoration: none;
}
.o-riconsegne-tab li a:focus,
.o-riconsegne-tab li a:hover { 
    border: 0px;
    border-bottom: 3px solid #264d7a;
	color: #222;
	text-decoration: none;
}
.o-riconsegne-tab li.active { 
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: #949494 0 -2px 2px;
    min-width: 250px;
    top: 0;
} 
.o-riconsegne-tab li.active a:focus,
.o-riconsegne-tab li.active a:hover {
    border-bottom: 2px solid transparent;
} 
.o-riconsegne-tab li.active:after {
	background-image: none;
}
.o-riconsegne-tab li .o-close {
    background: none;
    bottom: 0;
    border-radius: 20px;
    color: #222;
    cursor: pointer;
    display: none;
    font-size: 1.5rem;
    height: 20px;
    line-height: 20px;
    opacity: 1;
    position: absolute;
    right: -11px;
    text-align: center;
    top: 3px;
    transform: translateY(0%);
    width: 50px;
    z-index: 99;
}
.o-riconsegne-tab li.active .o-close {
    display: block;
}

.o-riconsegne-tab li:not(.active):hover {
    height: 50px;
    line-height: 3.5rem;
    top: 0;
}

#c-tab .tab-content {
    clear: both;
}
